import React from 'react';

import Element from './Element.react';
import SharedInput from './SharedInput.react';

export const DateInput = (props) => {
	return <SharedInput {...props} inputType="date" />;
};

export default Element(DateInput);
