import { useMemo } from 'react';

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Tag,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { mdiCheck, mdiHuman, mdiTarget } from '@mdi/js';
import { FieldComponentTypes } from 'shared/src/utils/shared';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import { scrollToElementById } from '@/util/helper';
import { ACCESSIBILITY_ERROR_CATEGORIES } from '@/util/resources';

import { Icon } from '@/components/gui/shared/Icon';

const ACCESSIBILITY_CONTRAST_TYPES = {
	AREA: 'AREA',
	LABEL: 'LABEL',
	BUTTON: 'BUTTON',
};

type Address = {
	rowId: string;
	slot: number;
	component: number;
};

type AccessibilityError = {
	page: number;
	type: string;
	id: string;
	errorType: string;
	address: Address;
};

const getTagProps = (type: string, count: number) => {
	if (count === 0) {
		return {
			colorScheme: 'success',
			children: <Icon path={mdiCheck} />,
			'data-testid': `accessibility-list-${type.toLowerCase().replace(' ', '-')}-tag`,
		};
	}

	return { colorScheme: 'primary', children: count, 'data-testid': `accessibility-list-${type.toLowerCase().replace(' ', '-')}-tag` };
};

const ErrorIndicatorWithAccordion = ({ errorType, errorCounts, renderAccessibilityErrors }) => {
	const hasErrors = errorCounts[errorType] > 0;

	return (
		<AccordionItem>
			{!hasErrors ? (
				<Box display="flex" p="4" pr="8">
					<Box flex="1" textAlign="left">
						{errorType}
					</Box>
					<Tag size="sm" mr="2" justifyContent="center" {...getTagProps(errorType, errorCounts[errorType])} />
				</Box>
			) : (
				<>
					<AccordionButton>
						<Box flex="1" textAlign="left">
							{errorType}
						</Box>
						<Tag size="md" mr="2" justifyContent="center" {...getTagProps(errorType, errorCounts[errorType])} />
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pb={4}>{renderAccessibilityErrors(errorType)}</AccordionPanel>
				</>
			)}
		</AccordionItem>
	);
};

const AccessibilityList = () => {
	const { accessibilityErrors } = useContentStore();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const errorCounts = useMemo(
		() => ({
			[ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT]: accessibilityErrors?.filter(
				(error: AccessibilityError) => error.errorType === ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT,
			).length,
			[ACCESSIBILITY_ERROR_CATEGORIES.LABEL]: accessibilityErrors?.filter(
				(error: AccessibilityError) => error.errorType === ACCESSIBILITY_ERROR_CATEGORIES.LABEL,
			).length,
			[ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST]: accessibilityErrors?.filter(
				(error: AccessibilityError) => error.errorType === ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST,
			).length,
		}),
		[accessibilityErrors],
	);

	const renderAccessibilityErrors = (errorType: string) => {
		if (!accessibilityErrors) return;

		const errorMessages = {
			[ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT]: 'Alt text is missing from image',
			[ACCESSIBILITY_ERROR_CATEGORIES.LABEL]: 'Label missing from field',
			[ACCESSIBILITY_CONTRAST_TYPES.AREA]: "Low contrast of field's text",
			[ACCESSIBILITY_CONTRAST_TYPES.LABEL]: "Low contrast of label's text",
			[ACCESSIBILITY_CONTRAST_TYPES.BUTTON]: "Low contrast of button's text",
		};

		const renderErrorMessage = (error) => {
			if (errorType !== ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST) return errorMessages[errorType];

			if (error.type === FieldComponentTypes.submit_button) return errorMessages[ACCESSIBILITY_CONTRAST_TYPES.BUTTON];
			// This is only for summary page, to differentiate between label and field texts
			if (error.type === 'label') return errorMessages[ACCESSIBILITY_CONTRAST_TYPES.LABEL];

			return errorMessages[ACCESSIBILITY_CONTRAST_TYPES.AREA];
		};

		return accessibilityErrors
			.filter((error: AccessibilityError) => error.errorType === errorType)
			.map((error: AccessibilityError) => {
				const scrollTo = () => {
					onClose();
					if (error.id === 'summary_page') {
						document.getElementById('summary-page').scrollIntoView({ behavior: 'smooth' });
						useLeftSidebarStore.getState().toggleSummaryPageSettings();
						useContentStore.getState().setClickedSummaryPage();
						return;
					}

					scrollToElementById(error.id);

					if (error.address) {
						useLeftSidebarStore.getState().toggleElementSettings({ address: error.address });
						useContentStore.getState().setClickedElement(error.address);
					}
				};

				return (
					<Box key={error.id} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
						<Box display="flex" alignItems="center">
							<Text fontWeight="bold" mr={2}>
								{error.id === 'summary_page' ? 'Summary page:' : `Page ${error.page + 1}:`}
							</Text>
							<Text color="primary-fg" mr={2}>
								{renderErrorMessage(error)}
							</Text>
						</Box>
						<IconButton
							variant="ghost"
							icon={<Icon path={mdiTarget} />}
							size="sm"
							aria-label={'Select element with error'}
							onClick={scrollTo}
						/>
					</Box>
				);
			});
	};

	return (
		<Box position="relative" display="flex" alignItems="center">
			<Popover isOpen={isOpen} onClose={onClose}>
				<PopoverTrigger>
					<Box>
						<Tooltip label="Accessibility">
							<IconButton
								variant="ghost"
								size="sm"
								aria-label="Accessibility"
								icon={<Icon path={mdiHuman} />}
								data-testid="accessibility-list-open-btn"
								onClick={onOpen}
							/>
						</Tooltip>
						<Box
							hidden={!accessibilityErrors || accessibilityErrors.length === 0}
							borderRadius="full"
							bgColor="purple"
							color="chakra-inverse-text"
							fontSize="xs"
							fontWeight="medium"
							position="absolute"
							h={3.5}
							w={3.5}
							top={-1}
							right={-1}
							display="flex"
							alignItems="center"
							justifyContent="center"
							lineHeight="1px"
						>
							{accessibilityErrors?.length}
						</Box>
					</Box>
				</PopoverTrigger>
				<PopoverContent p={4} boxShadow="lg" mt={2} w={450}>
					<PopoverArrow />
					<Box width={'98%'} display={'flex'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'}>
						<PopoverHeader border={0} fontSize={18} fontWeight={'bold'}>
							Accessibility
						</PopoverHeader>
						<PopoverCloseButton position={'unset'} />
					</Box>
					<PopoverBody>
						<Accordion allowToggle>
							<ErrorIndicatorWithAccordion
								errorType={ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT}
								errorCounts={errorCounts}
								renderAccessibilityErrors={renderAccessibilityErrors}
							/>

							<ErrorIndicatorWithAccordion
								errorType={ACCESSIBILITY_ERROR_CATEGORIES.LABEL}
								errorCounts={errorCounts}
								renderAccessibilityErrors={renderAccessibilityErrors}
							/>

							<ErrorIndicatorWithAccordion
								errorType={ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST}
								errorCounts={errorCounts}
								renderAccessibilityErrors={renderAccessibilityErrors}
							/>
						</Accordion>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Box>
	);
};

export default AccessibilityList;
