import { useNavigate } from 'react-router-dom';

import { updateSearchParamsIfNeeded } from '@/util/auth/tenant-parameter';
import { getAuth0ParamsFromUrl } from '@/util/auth/urlUtils';

const useNavigateWithParams = () => {
	const navigate = useNavigate();

	const navigateWithParams = (path: string) => {
		if (path?.length > 0) {
			const [basePath, pathQueryString] = path.split('?');
			const auth0Params = getAuth0ParamsFromUrl(window.location.search);
			const searchParams = new URLSearchParams(auth0Params);

			if (pathQueryString) {
				const pathParams = new URLSearchParams(pathQueryString);
				pathParams.forEach((value, key) => {
					updateSearchParamsIfNeeded(searchParams, key, value);
				});
			}

			navigate(`${basePath}?${searchParams.toString()}`);
		}
	};

	return navigateWithParams;
};

export default useNavigateWithParams;
