import React from 'react';

import { Box } from '@chakra-ui/react';
import { ChakraStylesConfig, GroupBase, OptionBase, Select } from 'chakra-react-select';

import useContentStore from '@/stores/ContentStore';

export interface OperatorProps {
	address: number[];
	value: string;
	disabled?: boolean;
}

interface OperatorOption extends OptionBase {
	label: string;
	value: string;
}

const SelectOptions: OperatorOption[] = [
	{
		label: 'and',
		value: 'and',
	},
	{
		label: 'or',
		value: 'or',
	},
];

const StylesConfig: ChakraStylesConfig = {
	control: (provided) => ({
		...provided,
		bgColor: 'transparent',
		borderRadius: 'full',
		borderColor: 'chakra-border-color',
	}),
	valueContainer: (provided) => ({
		...provided,
		pr: 0,
	}),
	singleValue: (provided) => ({
		...provided,
		minW: '4',
	}),
};

export const Operator: React.FC<OperatorProps> = ({ address, value, disabled }) => {
	const changeConditionSetting = useContentStore((state) => state.changeConditionSetting);

	const onChange = React.useCallback(
		(type, value) => {
			changeConditionSetting(address, { [type]: value });
		},
		[address],
	);

	return (
		<Box my={3} display="flex" alignItems="center">
			<Select<OperatorOption, false, GroupBase<OperatorOption>>
				variant="outline"
				size="sm"
				useBasicStyles
				selectedOptionStyle="check"
				chakraStyles={StylesConfig}
				isDisabled={disabled}
				options={SelectOptions}
				value={value ? { value, label: value } : null}
				onChange={(data) => onChange('operator', typeof data === 'object' ? data.value : data)}
			/>

			{value === 'or' && <Box bgColor="chakra-border-color" h="1px" w="100%" />}
		</Box>
	);
};
