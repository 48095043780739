import React from 'react';

import { Box } from '@chakra-ui/react';
import { DropTarget } from 'react-dnd';

import useContentStore from '@/stores/ContentStore';
import useContentViewStore from '@/stores/ContentViewStore';

import itemTypes from '@/util/itemTypes';
import { contentViewMenuItemConstants as ViewTypes } from '@/util/resources';

import { _structureItems, StructureItem } from './helper/AddStructuresBox.react';

export const PagePlaceholder = (props) => {
	const isMobileView = useContentViewStore((state) => state.currentView === ViewTypes.MOBILE_VIEW);

	if (isMobileView) {
		return null;
	}

	let structures = _structureItems.map((item, key) => {
		let lastMarginRight = key === _structureItems.length - 1 ? 0 : 15;
		return (
			<StructureItem
				{...item}
				mb={'0px'}
				lastMarginRight={lastMarginRight}
				key={key}
				onClick={() => useContentStore.getState().insertRowInPage(props.id, item.type)}
			/>
		);
	});

	return props.connectDropTarget(
		<div>
			<Box
				display="flex"
				{...(props.isOver && {
					outline: '2px solid',
					outlineColor: 'primary',
				})}
				w="fit-content"
				m="auto"
			>
				{structures}
			</Box>
		</div>,
	);
};

let containerSpec = {
	drop(props, monitor) {
		const { row, originalIndex: dragIndex, pageIndex } = monitor.getItem();
		const draggedId = row.id;
		const { id: overId, pageIndex: newPageIndex } = props;
		const { index: overIndex } = props.findRow(overId);

		const contentStore = useContentStore.getState();

		if (dragIndex === overIndex) {
			return;
		}

		if (pageIndex !== newPageIndex) {
			contentStore.moveRowToPage({ rowId: draggedId, pageIndex: newPageIndex });
			monitor.getItem().pageIndex = newPageIndex;
			contentStore.removeRow(props.id);
			return;
		}
	},
};

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		rowBeingDragged: monitor.getItem(),
	};
}

const Comp = DropTarget(itemTypes.STRUCTURE, containerSpec, collect)(PagePlaceholder);

export default Comp;
