import { useEffect } from 'react';

import { ErrorMessages, ModalMessages, SuccessMessages } from 'config/messages';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';

import itemTypes from '@/util/itemTypes';
import { Modals, NotificationTypes } from '@/util/resources';

import { DraggableBoxItem } from './DraggableBoxItem';

/* istanbul ignore next */
const draggableFunctions = {
	beginDrag(props) {
		const contentStore = useContentStore.getState();

		contentStore.toggleDragging();

		contentStore.toggleRowDragging();

		let row;
		//condition here in case it is a custom row being dragged
		if (!props.structure) {
			row = contentStore.appendRowByDrag({ rowType: props.type, id: props.rowId });
		} else {
			row = contentStore.appendCustomRow(props.structure);
		}

		//return findRow(storeState.rows, storeState.rows.length - 1).row;
		return { row, newRow: true };
	},

	endDrag(props, monitor) {
		const contentStore = useContentStore.getState();

		contentStore.toggleDragging();
		contentStore.toggleRowDragging();
		const { row } = monitor.getItem();
		const didDrop = monitor.didDrop();

		const id = row.id;

		if (!didDrop) {
			contentStore.removeRow(id);
		} else {
			contentStore.dropRow();
			if (props.rowId) {
				contentStore.saveRowSettings({ loading: true, id: props.rowId });

				const customRows = useLeftSidebarStore.getState().custom_rows;
				const row =
					customRows &&
					customRows.rows &&
					customRows.rows.find((item) => {
						return item.id === props.rowId;
					});

				const onError = () => {
					contentStore.removeRow(props.rowId);
				};

				const onSuccess = (rowData) => {
					const newRow = JSON.parse(rowData);
					contentStore.replaceRow({ rowId: props.rowId, row: newRow });
				};

				if (row) useLeftSidebarStore.getState().getCustomRowData(row, onSuccess, onError);
			}
		}
	},
};

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
		connectDragPreview: connect.dragPreview(),
	};
}

const DraggableStructure = ({
	rowId,
	connectDragPreview,
	connectDragSource,
	onDoubleClick,
	iconSrc,
	className,
	labelFirst,
	structure,
	authorisationMode,
	createdBy,
	item,
}) => {
	const removeCustomRow = () => {
		const successCb = () => {
			useLeftSidebarStore.getState().getCustomRows();
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.CUSTOM_ROW_REMOVED });
		};

		const errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.CUSTOM_ROW_NOT_REMOVED });
		};

		const onOk = () => rowId && useLeftSidebarStore.getState().removeCustomRow(rowId, successCb, errorCb);

		useModalStore.getState().showGeneralModal({
			modal: Modals.ERROR,
			title: 'Delete custom layout?',
			message: ModalMessages.REMOVE_CUSTOM_ROW,
			okLabel: 'Delete',
			onOk,
		});
	};

	const shareCustomRow = () => {
		const onError = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Could not get saved layout data' });
		};

		const onSuccess = (rowData) => {
			const row = JSON.parse(rowData);
			useLeftSidebarStore.getState().setCustomRowToSave(row);
			useModalStore.getState().showCustomRowModal({ row: item });
		};

		useLeftSidebarStore.getState().getCustomRowData(item, onSuccess, onError);
	};

	useEffect(() => {
		connectDragPreview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	return connectDragSource(
		<div onDoubleClick={onDoubleClick} data-testid="draggable-structure-wrapper" role="group">
			<DraggableBoxItem
				iconSrc={iconSrc}
				className={className}
				labels={[labelFirst]}
				onShare={(structure || (!structure && rowId)) && shareCustomRow}
				onRemove={(structure || (!structure && rowId)) && removeCustomRow}
				boxProps={{ 'data-testid': 'draggable-structure' }}
				authorisationMode={authorisationMode}
				createdBy={createdBy}
			/>
		</div>,
	);
};

export default DragSource(itemTypes.STRUCTURE, draggableFunctions, collect)(DraggableStructure);
