import { ApiAuthorisationModes } from '@/util/resources';

export const buildMetadataServicePayload = (Key, Category, Content, Attributes, AuthorisationMode = ApiAuthorisationModes.Private) => {
	let attributes = Object.keys(Attributes).map((attr) => {
		let value = typeof Attributes[attr] === 'object' ? JSON.stringify(Attributes[attr]) : Attributes[attr];

		return { Key: attr, Value: value };
	});

	let content = {
		ContentType: Content.ContentType,
		Data: typeof Content.Data === 'object' ? JSON.stringify(Content.Data) : Content.Data,
	};

	return {
		Category,
		AuthorisationMode,
		Attributes: attributes,
		Content: content,
		Key,
		Name: Key,
	};
};
