import React from 'react';

import Element from './Element.react';
import SharedInput from './SharedInput.react';

export const TextInput = (props) => {
	return <SharedInput {...props} inputType="text" />;
};

export default Element(TextInput);
