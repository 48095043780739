import React, { memo } from 'react';

import { Button } from '@chakra-ui/react';

import { Icon } from '@/components/gui/shared/Icon';

const ButtonSetting = ({ loading, disabled, className, tooltip, customStyle, icon, label, onClick, onMouseDown, testId }) => {
	const handleClick = (e) => {
		onClick(e);
	};

	const handleMouseDown = (e) => {
		if (onMouseDown) {
			onMouseDown(e);
		}
	};

	return (
		<Button
			mb={5}
			className={`button-setting ${className ?? ''} notranslate`}
			isLoading={loading}
			isDisabled={disabled}
			size={'md'}
			variant="outline"
			colorScheme="primary"
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			title={tooltip}
			data-testid={testId}
			leftIcon={<Icon path={icon} dataTestId={`${testId}-icon`} className={className} />}
			w="100%"
			{...customStyle}
		>
			{label}
		</Button>
	);
};

export default memo(ButtonSetting);
