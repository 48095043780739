import config from 'config/config';

import { generateUrlWithPathParams, getEditorType, makeApiGatewayCall } from '@/util/helper';
import { MetadataCategories } from '@/util/resources';

import { buildMetadataServicePayload } from './metadataService';

export const postCustomElement = (title, element, id, order, AuthorisationMode) => {
	const editorType = getEditorType();

	const orderAttr = order ? { order } : {};

	const elementId = id ? id : element.id;

	let data = buildMetadataServicePayload(
		elementId,
		MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_ELEMENTS,
		{
			ContentType: 'application/json',
			Data: element,
		},
		{
			id: elementId,
			title,
			type: element.type,
			// element,
			tenants: element.tenants,
			createdBy: element.createdBy,
			...orderAttr,
		},
		AuthorisationMode,
	);

	let createMetadataUrl = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	return makeApiGatewayCall(createMetadataUrl, 'post', data);
	// return new Promise((res, rej) => res('asd'));
};

export const getCustomElements = () => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_ELEMENTS,
			LatestOnly: true,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetAllMetadataForUserRequest);

	return makeApiGatewayCall(url, 'get', {}, {}, data);
};

export const getCustomElement = (elementMetadata) => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_ELEMENTS,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetLatestMetadataRequest, {
		key: elementMetadata.id,
		metadataVersionId: elementMetadata.metadataVersionId,
	});

	return makeApiGatewayCall(url, 'get', {}, {}, data);
};

export const deleteCustomElement = (key) => {
	const editorType = getEditorType();

	let data = {
		data: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_ELEMENTS,
			key,
		},
	};

	let url = config.metadata_service.url + config.metadata_service.paths.DeleteMetadataRequest;

	return makeApiGatewayCall(url, 'delete', {}, {}, data);
};

export const getCustomElementsFromRsp = (customElementsRsp) => {
	let customElements = customElementsRsp.data.Data.map((elementData) => {
		const orderAttr = elementData.Attributes.find((attr) => attr.Key === 'order');
		const order = orderAttr ? orderAttr.Value : '';

		const elementAttr = elementData.Attributes.find((attr) => attr.Key === 'element');
		const element = elementAttr ? JSON.parse(elementAttr.Value) : '';

		const typeAttr = elementData.Attributes.find((attr) => attr.Key === 'type');
		const type = typeAttr ? typeAttr.Value : '';

		const tenantsAttr = elementData.Attributes.find((attr) => attr.Key === 'tenants');
		const tenants = tenantsAttr?.Value ? JSON.parse(tenantsAttr.Value) : undefined;

		const createdByAttr = elementData.Attributes.find((attr) => attr.Key === 'createdBy');
		const createdBy = createdByAttr?.Value ? JSON.parse(createdByAttr.Value) : undefined;

		return {
			// AuthorisationMode: elementData.AuthorisationMode,
			title: elementData.Attributes.find((attr) => attr.Key === 'title').Value,
			element,
			order,
			type,
			tenants,
			createdBy,
			metadataVersionId: elementData.MetadataVersionId,
			id: elementData.Key,
			authorisationMode: elementData.AuthorisationMode,
		};
	});

	return customElements;
};
