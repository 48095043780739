import React from 'react';

import Element from './Element.react';
import SharedInput from './SharedInput.react';

export const LongTextInput = (props) => {
	return <SharedInput {...props} variant="textarea" />;
};

export default Element(LongTextInput);
