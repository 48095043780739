import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Text } from '@chakra-ui/react';
import { shallow } from 'zustand/shallow';

import useEntitiesStore from '@/stores/EntitiesStore';
import { showNotification } from '@/stores/NotificationStore';
import useXmcAppsStore from '@/stores/XmcAppsStore';

import { NotificationTypes } from '@/util/resources';

import { SitesDrawer } from '@/components/Table/containers/SitesContainer';

const SiteManagement = () => {
	const [isOpen, setIsOpen] = useState(false);

	const { noOfSites, selectedSites, getSites, resetSites } = useXmcAppsStore(
		(state) => ({
			noOfSites: state.sites.dataset.length,
			selectedSites: state.sites.dataset.filter((site) => site.isSelected),
			getSites: state.getSites,
			resetSites: state.resetSites,
		}),
		shallow,
	);
	const { activeEntity, getEntity, updateSites } = useEntitiesStore(
		(state) => ({
			activeEntity: state.activeEntity,
			getEntity: state.getEntity,
			updateSites: state.updateSites,
		}),
		shallow,
	);

	const onClose = () => {
		setIsOpen(false);
		resetSites(activeEntity.Sites);
	};

	const onSave = () => {
		setIsOpen(false);
		updateSites(
			activeEntity.Entity.Id,
			selectedSites.map((site) => ({ id: site.id, name: site.name })),
			() => {
				showNotification({ type: NotificationTypes.SUCCESS, text: 'Selected sites saved successfully' });
				getEntity(activeEntity.Entity.Id);
			},
		);
	};

	const onLoaded = useCallback(
		(entitySites, xmcSites, forceUpdate) => {
			if (entitySites.length > 0) {
				const existingSites = entitySites.filter((entitySite) => Boolean(xmcSites.find((xmcSite) => xmcSite.id === entitySite.id)));

				if (existingSites.length < entitySites.length || forceUpdate) {
					updateSites(activeEntity.Entity.Id, existingSites, () => {
						getEntity(activeEntity.Entity.Id);
					});
				}
			}
		},
		[activeEntity, updateSites, getEntity],
	);

	useEffect(() => {
		if (activeEntity) {
			getSites(activeEntity.Sites, onLoaded);
		}
	}, [getSites, activeEntity, onLoaded]);

	if (!activeEntity) {
		return null;
	}

	return (
		<>
			<Box data-testid="site-management">
				<Text mb={3} fontWeight="medium" color="neutral-fg">
					Form available on
				</Text>
				<Box display="flex" alignItems="center" gap={4}>
					<Text>
						{activeEntity.Sites.length === 0
							? 'All sites'
							: `${activeEntity.Sites.length} out of ${noOfSites} ${noOfSites > 1 ? 'sites' : 'site'}`}
					</Text>
					<Button variant="outline" colorScheme="primary" onClick={() => setIsOpen(true)} size="sm">
						Edit
					</Button>
				</Box>
			</Box>
			<SitesDrawer isOpen={isOpen} showFooter={noOfSites > 0} onClose={onClose} onSave={onSave} />
		</>
	);
};

export default SiteManagement;
