import {
	mdiArchiveArrowUpOutline,
	mdiArchiveOutline,
	mdiCloudUploadOutline,
	mdiContentCopy,
	mdiLabelOutline,
	mdiTrashCanOutline,
} from '@mdi/js';

import noForms from './images/noforms.svg';
import noPages from './images/nopages.svg';

export const EntitiesRoutes = {
	root: '/:type/:list/:page',
	formList: '/:type/list/:page',
	webhook: '/:type/webhook/:page',
	stylingList: '/:type/styling/list',
	stylingEdit: '/:type/styling/edit/:id',
};

export const getRoute = {
	root: (type = 'forms', list = 'list', pagination = 1) => `/${type}/${list}/${pagination}`,
	stylingList: (type = 'forms') => `/${type}/styling/list`,
	stylingEdit: (type = 'forms', id) => `/${type}/styling/edit/${id}`,
};

export const SmallButtons = [
	{
		action: 'More',
		icon: 'more_horiz',
		additional: [
			{ action: 'Settings', icon: 'settings' },
			{
				action: 'Activate',
				icon: 'publish',
			},
			// {
			// 	action: 'Deactivate',
			// 	icon: 'publish',
			// },
			{
				action: 'Refresh',
				icon: 'Refresh',
			},
			{
				action: 'Duplicate',
				icon: 'file_copy',
			},
			{
				action: 'Copy',
				icon: 'Copy',
				label: 'Copy to environment',
			},
			{
				action: 'Archive',
				icon: 'archive',
			},
			{
				action: 'Unarchive',
				icon: 'unarchive',
			},
			{
				action: 'Delete',
				icon: 'Delete',
			},
		],
	},
];

export const EntityStatuses = {
	draft: 0,
	active: 1,
	inactive: 2,
	archived: 3,
};

export const statusFilters = [
	{ label: 'Draft', value: '0' },
	{ label: 'Active', value: '1' },
	{ label: 'Inactive', value: '2' },
	// { label: 'Archived', value: '3' },
];

export const manyActions = [
	{ label: 'Activate', value: 'publish', icon: mdiCloudUploadOutline },
	// { label: 'Deactivate', value: 'unpublish', icon: 'cloud_off' },
	{ label: 'Archive', value: 'archive', icon: mdiArchiveOutline },
	{ label: 'Unarchive', value: 'unarchive', icon: mdiArchiveArrowUpOutline },
	{ label: 'Copy to environment', value: 'copy', icon: mdiContentCopy },
	{ label: 'Add labels', value: 'tags', icon: mdiLabelOutline },
	{ label: 'Delete', value: 'delete', icon: mdiTrashCanOutline },
];

export const FilterCategories = {
	forms: 'formFilters',
};

export const FilterTypes = {
	name: 'name',
	orderBy: 'orderBy',
	status: 'status',
	subType: 'subType',
	tags: 'tags',
};

export const EntityTypes = {
	landingPage: {
		type: 2,
		route: 'landing-pages',
		labels: {
			simple: 'Page',
			simplePlural: 'Pages',
			full: 'Landing Page',
			fullPlural: 'Landing Pages',
		},

		images: {
			empty: noPages,
		},
	},
	form: {
		type: 1,
		route: 'forms',
		labels: {
			simple: 'Form',
			simplePlural: 'Forms',
			full: 'Form',
			fullPlural: 'Forms',
		},
		images: {
			empty: noForms,
		},
	},
};

export const NotificationType = [
	{
		multi: '',
		single: '',
	},
	{
		multi: 'Forms',
		single: 'Form',
	},
	{
		multi: 'Pages',
		single: 'Page',
	},
];

export const FormTypesList = [
	{
		designerType: 'popup',
		type: 1,
	},
	{
		designerType: 'inline',
		type: 2,
	},
	{
		designerType: 'smartbar',
		type: 3,
	},
	{
		designerType: 'scrollbox',
		type: 4,
	},
	{
		designerType: 'welcomemat',
		type: 5,
	},
	{
		designerType: 'promotion',
		type: 6,
	},
];

export const FormsDefaultSorting = 'CreatedOnDescending';
export const WebhooksDefaultSorting = 'None';

export const OrderByTypes = [
	'NameAscending',
	'NameDescending',
	'CreatedOnAscending',
	'CreatedOnDescending',
	'ViewsAscending',
	'ViewsDescending',
	'StatusDraft',
	'StatusAscending',
	'StatusDescending',
	'ConversionsDescending',
	'ConversionPercentageAscending',
	'ConversionPercentageDescending',
];

export const InUseOptions = [
	{
		label: 'Yes',
		value: 'true',
	},
	{
		label: 'No',
		value: 'false',
	},
];

export const OAuthSubmitOptions = [
	{
		label: 'Form data',
		value: false,
	},
	{
		label: 'JSON',
		value: true,
	},
];

export const WebhookOrderByTypes = ['NameAscending', 'NameDescending', 'IsActiveAscending', 'IsActiveDescending', 'None'];

export const WebhookTypes = ['OAuth2', 'Basic', 'ApiKey', 'NoAuth'];
export const WebhookTypesLabels = ['OAuth 2', 'Basic', 'API key', 'No authentication'];

export const WebhookTypeProperties = {
	[WebhookTypes[0]]: ['Name', 'AuthType', 'EndPoint', 'ClientId', 'ClientSecret', 'AuthorizeEndPoint', 'UseJsonFormat'],
	[WebhookTypes[1]]: ['Name', 'AuthType', 'EndPoint', 'Username', 'Password'],
	[WebhookTypes[2]]: ['Name', 'AuthType', 'EndPoint', 'Header', 'ApiKey'],
	[WebhookTypes[3]]: ['Name', 'AuthType', 'EndPoint'],
};

export const SearchTermMinLength = 3;

export const WebhookOptions = [
	...WebhookTypes.map((_, idx) => ({
		idx,
		label: WebhookTypesLabels[idx],
		value: (idx + 1).toString(),
	})),
];
