import { findDOMNode } from 'react-dom';

/**This helper depends on react-dnd and it's monitor object provided in DropTarget or DropSource collector functions.
 * This helper depends on react-dom.
 *
 * Takes a dom element as a parameter and attaches behaviour depending on where the cursor is relative to
 * the vertical middle of the component.
 * @param component - a reference to the react component that represents the dom element you would like to calculate the vertical middle for
 * @param monitor - a reference to react-dnd's monitor (from DropTarget or DropSource)
 * @param bottomCallback - a function to be executed if the cursor is above the component's vertical middle
 * @param topCallback - a function to be executed if the cursor is below the component's vertical middle
 */
export function attachOnMiddle(component, monitor, bottomCallback, topCallback) {
	const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

	const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

	const clientOffset = monitor.getClientOffset();

	const hoverClientY = clientOffset.y - hoverBoundingRect.top;

	//move at the bottom
	if (hoverClientY > hoverMiddleY) {
		bottomCallback();
	}

	//move on top
	if (hoverClientY <= hoverMiddleY) {
		topCallback();
	}
}

//TODO: test attachOnMiddle
