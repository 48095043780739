import * as React from 'react';

import { Box } from '@chakra-ui/react';
import { shallow } from 'zustand/shallow';

import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import { DynamicSettings } from './ElementSettings.react';

class RightSidebarMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			width: 400,
			canResize: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.options.scrollTop !== this.props.options.scrollTop && this.props.options.scrollTop) {
			const container = document.getElementById('right-sidebar-menu');
			setTimeout(() => {
				container.scrollTo({ top: this.props.options.scrollTop, behavior: 'smooth' });
			}, 100);
		}
	}

	render() {
		const { isOpen } = this.props;

		return (
			<Box
				w={isOpen ? `${this.state.width}px` : 0}
				h="calc(100vh - 6.5rem)"
				borderLeft={isOpen ? '1px solid' : 0}
				borderLeftColor="chakra-border-color"
				transition={this.state.canResize ? 'unset' : 'all 0.2s ease 0s'}
				className="right-settings"
				backgroundColor="chakra-subtle-bg"
			>
				<Box id="right-sidebar-menu" h="100%" overflowY="auto">
					{isOpen && (
						<Box position="relative" p={5}>
							{this.state.canResize && (
								<Box
									position="fixed"
									top={0}
									left={0}
									right={0}
									bottom={0}
									zIndex={999}
									onMouseMove={this.onMouseMove}
									onMouseUp={this.disableResize}
									cursor="ew-resize"
									data-testid="resize-overlay"
								/>
							)}
							<Box
								style={{ userSelect: 'none' }}
								data-testid="resize-handle"
								onMouseDown={this.enableResize}
								onMouseUp={this.disableResize}
								cursor="ew-resize"
								top={0}
								bottom={0}
								left={0}
								w={2.5}
								position="absolute"
								zIndex={1}
							/>
							<DynamicSettings options={this.props.options} />
						</Box>
					)}
				</Box>
			</Box>
		);
	}

	onMouseMove = (e) => {
		const movedWidth = window.innerWidth - this.state.width - e.nativeEvent.clientX;
		const newWidth = this.state.width + movedWidth;
		if (newWidth > 750 || newWidth < 300) {
			return;
		}
		this.setState({ width: newWidth });
	};

	enableResize = () => {
		this.setState({ canResize: true });
	};

	disableResize = () => {
		this.setState({ canResize: false });
	};
}

const RightSidebarMenuWrapper = (props) => {
	const leftSidebarData = useLeftSidebarStore((state) => {
		return {
			isOpen: !!state.settings.toggledElementSettingsAddress,
			options: state.options,
		};
	}, shallow);
	return <RightSidebarMenu {...leftSidebarData} {...props} />;
};

export default RightSidebarMenuWrapper;
