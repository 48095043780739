import config from 'config/config';
import { generateGuid } from 'shared/src/utils/shared.js';

import { generateUrlWithPathParams, getEditorType, makeApiGatewayCall } from '@/util/helper';
import { MetadataCategories } from '@/util/resources';

import { buildMetadataServicePayload } from './metadataService';

export const getAllStyles = (authorisationMode = null) => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_GLOBAL_STYLES,
			authorisationMode,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetAllMetadataForUserRequest);

	return makeApiGatewayCall(url, 'get', {}, {}, data).catch((error) => {
		if (error.response && error.response.data.status === 404) {
			return false;
		} else {
			throw error;
		}
	});
};

export const createGlobalStyles = (styles, key, attributes) => {
	const editorType = getEditorType();

	let data = buildMetadataServicePayload(
		key ? key : generateGuid(),
		MetadataCategories[editorType].EDITOR_SAVED_GLOBAL_STYLES,
		{
			ContentType: 'application/json',
			Data: styles,
		},
		attributes ? attributes : {},
	);

	let createMetadataUrl = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	return makeApiGatewayCall(createMetadataUrl, 'post', data);
};

export const saveGlobalStyles = (styles, key, attributes) => {
	const editorType = getEditorType();

	let data = buildMetadataServicePayload(
		key ? key : generateGuid(),
		MetadataCategories[editorType].EDITOR_SAVED_GLOBAL_STYLES,
		{
			ContentType: 'application/json',
			Data: styles,
		},
		attributes ? attributes : {},
	);

	let createMetadataUrl =
		generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataVersionRequest, { key }) +
		'?Replace=true';

	return makeApiGatewayCall(createMetadataUrl, 'post', data);
};

export const getStyle = (key) => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_GLOBAL_STYLES,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetLatestMetadataRequest, {
		key: key,
	});

	return makeApiGatewayCall(url, 'get', {}, {}, data).catch((error) => {
		if (error.response && error.response.status === 404) {
			return false;
		} else {
			throw error;
		}
	});
};

export const deleteStyle = (key) => {
	const editorType = getEditorType();

	let data = {
		data: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_GLOBAL_STYLES,
			Key: key,
		},
	};

	let url = config.metadata_service.url + config.metadata_service.paths.DeleteMetadataRequest;

	return makeApiGatewayCall(url, 'delete', {}, {}, data);
};

export const getStylesFromRsp = (stylesRsp) => {
	let styles = stylesRsp.data.Data.map((styleData) => {
		return {
			name: styleData.Attributes.find((attr) => attr.Key === 'name').Value,
			isCss: styleData.Attributes.find((attr) => attr.Key === 'isCss').Value === 'true' ? true : false,
			id: styleData.Key,
			timestamp: parseInt(styleData.Timestamp.substr(7), 10),
		};
	}).sort((x, y) => {
		return y.timestamp - x.timestamp;
	});

	return styles;
};
