import { Box } from '@chakra-ui/react';
import AceEditor from 'react-ace';

import useStylingStore from '@/stores/StylingStore';

import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-clouds_midnight';

import { useCallback } from 'react';

export const StylingCode = () => {
	const stylesString = useStylingStore((state) => state.styles.stylesString);
	const changeStyleString = useStylingStore((state) => state.changeStyleString);

	const onChange = useCallback((value) => changeStyleString(value), []);

	return (
		<Box flex={2} boxShadow="base" h="95%">
			<AceEditor
				mode="css"
				theme="clouds_midnight"
				name="STYLE_STRING"
				showPrintMargin={false}
				editorProps={{ $blockScrolling: true }}
				enableBasicAutocompletion={true}
				enableLiveAutocompletion={true}
				value={stylesString}
				width="100%"
				height="100%"
				onChange={onChange}
			/>
		</Box>
	);
};

export default StylingCode;
