import React from 'react';

import Element from './Element.react';
import SharedInput from './SharedInput.react';

export const PhoneInput = (props) => {
	return <SharedInput {...props} placeholder={false} inputType="tel" />;
};

export default Element(PhoneInput);
