import { useEffect, useMemo, useState } from 'react';

import {
	Box,
	Button,
	ButtonGroup,
	Collapse,
	Flex,
	FormControl,
	FormLabel,
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { MultiValue, Select } from 'chakra-react-select';

import useUserStore from '@/stores/UserStore';

import { Icon } from '@/components/gui/shared/Icon';

export interface TableCopyModalProps {
	isOpen: boolean;
	onClose: () => void;
	entityIds: string[];
	entityName?: string;
	onCopy: (entityIds: string[], tenantIds: string[]) => Promise<void>;
}

interface TenantOption {
	label: string;
	value: string;
}

const entityLiterals = {
	form: { singular: 'form', plural: 'forms' },
	style: { singular: 'style', plural: 'styles' },
};

const TableCopyModal: React.FC<TableCopyModalProps> = ({ isOpen, onClose, entityIds, entityName = 'form', onCopy }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTenants, setSelectedTenants] = useState<MultiValue<TenantOption>>([]);
	const { currentTenant, tenants } = useUserStore((state) => ({
		currentTenant: state.currentTenant,
		tenants: state.tenants,
	}));

	const entity = entityLiterals[entityName];

	const tenantOptions: TenantOption[] = useMemo(
		() =>
			tenants
				.filter((tenant) => tenant.id !== currentTenant.id)
				.map((tenant) => ({
					label: tenant.displayName,
					value: tenant.id,
				})),
		[currentTenant, tenants],
	);

	const onCopyClick = () => {
		setIsLoading(true);

		onCopy(
			entityIds,
			selectedTenants.map(({ value }) => value),
		).then(() => setIsLoading(false));
	};

	useEffect(() => {
		setSelectedTenants([]);
	}, [isOpen]);

	return (
		<Modal id="copy-entity" isOpen={isOpen} onClose={onClose} size="2xl">
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					{entityIds.length === 1
						? `Select which environment to copy this ${entity.singular} to`
						: `Select which environment to copy (${entityIds.length}) ${entity.plural} to`}
				</ModalHeader>

				<ModalCloseButton />

				<ModalBody>
					<Text mb={3} color="chakra-subtle-text">
						Current environment: {currentTenant.displayName}
					</Text>

					<FormControl id="tenants-dropdown">
						<FormLabel>Available environments</FormLabel>
						<Select
							useBasicStyles
							isRequired
							isMulti
							isSearchable
							isDisabled={isLoading}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							selectedOptionStyle="check"
							options={tenantOptions}
							value={selectedTenants}
							onChange={(selectedOptions) => {
								setSelectedTenants(selectedOptions);
							}}
						/>
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup>
						<Button variant="ghost" colorScheme="blackAlpha" onClick={onClose}>
							Cancel
						</Button>

						<Button isDisabled={!selectedTenants.length} isLoading={isLoading} onClick={onCopyClick}>
							Copy
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export const CopyErrors = ({ errors }) => {
	const { isOpen, onToggle } = useDisclosure();
	const tenants = useUserStore((state) => state.tenants);
	const noOfLines = errors.map((error) => error.Forms.length + 1).reduce((sum, val) => sum + val, 0);

	return (
		<Box mt={3}>
			<Collapse in={isOpen} startingHeight={errors[0].Forms.length === 1 ? '48px' : '72px'}>
				<Flex direction="column" gap={3} maxHeight="400px" overflowY={isOpen ? 'auto' : 'visible'}>
					{errors.map((error) => (
						<List>
							<ListItem noOfLines={1} fontWeight={600}>
								{tenants.find((tenant) => tenant.id === error.TenantId).displayName}
							</ListItem>
							{error.Forms.map((form) => (
								<ListItem noOfLines={1}>{form.FormName}</ListItem>
							))}
						</List>
					))}
				</Flex>
			</Collapse>

			{noOfLines > 3 && (
				<Button variant="link" onClick={onToggle}>
					{isOpen ? 'Show less' : 'Show more'}
					<Icon path={isOpen ? mdiChevronUp : mdiChevronDown} />
				</Button>
			)}
		</Box>
	);
};

export default TableCopyModal;
