import React from 'react';

import { useShallow } from 'zustand/react/shallow';

import useContentStore from '@/stores/ContentStore';
import useTextEditorStore from '@/stores/TextEditorStore';

import { compareFontLabels } from '@/util/helper';
import { FontFamilyOptions, FormFieldTypes, FormLabelPositions } from '@/util/resources';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import ColorPickerSetting from '@/components/gui/shared/settings/ColorPickerSetting.react';
import DropDownSetting from '@/components/gui/shared/settings/DropDownSetting.react';
import MultiSetting from '@/components/gui/shared/settings/MultiSetting.react';
import NumberSetting from '@/components/gui/shared/settings/NumberSetting.react';
import PaddingSetting from '@/components/gui/shared/settings/PaddingSetting.react';
import ShadowsSettings from '@/components/gui/shared/settings/ShadowsSettings.react';
import TextInputSetting from '@/components/gui/shared/settings/TextInputSetting.react';
import ToggleBlock from '@/components/gui/shared/settings/ToggleBlock.react';

import { ButtonSettingComponents } from './Button-settings.react';
import { RowBackgroundImageSettings } from './Row-settings.react';
import { DecorationSettings, defaultFormInputSettings as settings, SharedInputSettingsFields } from './SharedInput-settings.react';

const SummaryPageSettings = ({ leftSidebarOptions }) => {
	const { summaryPage, structureWidth, saveSummaryPageSettings } = useContentStore(
		useShallow((state) => ({
			summaryPage: state.content.summaryPage,
			structureWidth: state.content.structureWidth,
			saveSummaryPageSettings: state.saveSummaryPageSettings,
		})),
	);
	const { custom_fonts } = useTextEditorStore.getState(useShallow((state) => ({ custom: state.custom_fonts })));
	const { title, layoutStyles, buttonStyles, fieldStyles } = summaryPage;
	const {
		multiPadding,
		padding,
		width,
		borderColor,
		borderWidth,
		borderRadius,
		bg_color,
		background_image,
		background_position,
		background_repeat,
	} = layoutStyles;

	const {
		labelFont,
		labelFontColour,
		labelFontSize,
		labelFontWeight,
		labelFontStyle,
		labelTextDecoration,
		labelPosition,
		labelWidth,
		asteriskColor,

		fieldFontColour,
		fieldFontSize,
		fieldFontWeight,
		fieldFontStyle,
		fieldTextDecoration,
		fieldFont,
	} = fieldStyles;

	const allFontOptions = FontFamilyOptions.concat(custom_fonts).sort(compareFontLabels);

	const onChange = (data, group) => {
		const settings = Array.isArray(data)
			? data.reduce((acc, { type, value }) => ({ ...acc, [type]: value }), {})
			: { [data.type]: data.value };

		saveSummaryPageSettings(settings, group);
	};

	const onDecorationsChange = ({ type: dataType, fontType, value: dataValue }, group) => {
		let type, value;
		switch (dataType) {
			case 'italics':
				type = fontType ? `${fontType}FontStyle` : 'fontStyle';
				value = dataValue ? 'italic' : 'normal';
				break;
			case 'bold':
				type = fontType ? `${fontType}FontWeight` : 'fontWeight';
				value = dataValue ? 'bold' : 'normal';
				break;
			case 'underline':
				type = fontType ? `${fontType}TextDecoration` : 'textDecoration';
				value = dataValue ? 'underline' : 'none';
				break;
		}

		return onChange({ type, value }, group);
	};

	const onLabelPositionChange = (data) => {
		let settings = {};
		if (data.value === 'column' && labelWidth > 40) {
			settings = {
				...summaryPage.fieldStyles,
				[data.type]: data.value,
				[SharedInputSettingsFields.labelWidth]: 25,
			};
		} else if (data.value === 'row' && labelWidth < 120) {
			settings = {
				...summaryPage.fieldStyles,
				[data.type]: data.value,
				[SharedInputSettingsFields.labelWidth]: 120,
			};
		}
		saveSummaryPageSettings(settings, 'fieldStyles');
	};

	return (
		<SettingsBox>
			<TextInputSetting type="title" onChange={onChange} text={title} label="Page title" ariaLabel="Enter summary page title" />

			<ToggleBlock
				label="Layout"
				open={leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.field : undefined}
				filterImmunity
			>
				<PaddingSetting
					multiPadding={multiPadding}
					padding={padding}
					type="multiPadding"
					secondaryType={'padding'}
					onChange={(data) => onChange(data, 'layoutStyles')}
					label="Padding"
				/>
				<ColorPickerSetting
					type="bg_color"
					onChange={(data) => onChange(data, 'layoutStyles')}
					selectedColor={bg_color}
					label="Layout color"
				/>
				<NumberSetting
					type="width"
					onChange={(data) => onChange(data, 'layoutStyles')}
					text={width || structureWidth}
					label="Layout width"
					ariaLabel="Number of pixels"
				/>
				<MultiSetting label="Border width & border radius">
					<NumberSetting
						type={'borderWidth'}
						onChange={(data) => onChange(data, 'layoutStyles')}
						text={borderWidth}
						label="Border width"
						ariaLabel="Number of pixels"
					/>
					<NumberSetting
						type="borderRadius"
						onChange={(data) => onChange(data, 'layoutStyles')}
						text={borderRadius}
						label="Border radius"
						ariaLabel="Number of pixels"
					/>
				</MultiSetting>
				<ColorPickerSetting
					type="borderColor"
					onChange={(data) => onChange(data, 'layoutStyles')}
					selectedColor={borderColor}
					label="Layout border color"
				/>
				<RowBackgroundImageSettings
					label={background_image ? 'Change content background image' : 'Add content background image'}
					removeLabel="Remove content background image"
					id="summary_page"
					settingType="summary_page"
					onChange={(data) => onChange(data, 'layoutStyles')}
					background_image={background_image}
					background_repeat={background_repeat}
					background_position={background_position}
					type={{ repeat: 'background_repeat', position: 'background_position' }}
				/>
			</ToggleBlock>

			<ToggleBlock
				label="Button"
				open={leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.field : undefined}
				filterImmunity
			>
				<ColorPickerSetting
					type="background_color"
					onChange={(data) => onChange(data, 'buttonStyles')}
					selectedColor={buttonStyles.background_color || 'transparent'}
					label="Background color"
				/>
				<ShadowsSettings
					selectedValue={
						buttonStyles.box_shadow || {
							offsetx: 1,
							offsety: 1,
							blur: 10,
							spread: 1,
							color: '#000',
						}
					}
					onChange={(data) => onChange(data, 'buttonStyles')}
					label="Shadow settings"
				/>
				<ButtonSettingComponents
					{...buttonStyles}
					pageIndex={0}
					lastPage={0}
					onChange={(data) => onChange(data, 'buttonStyles')}
					onDecorationsChange={(data) => onDecorationsChange(data, 'buttonStyles')}
					decorations={true}
					noLink
				/>
			</ToggleBlock>

			{settings.fieldGroup.active && (
				<ToggleBlock
					label={settings.fieldGroup.label}
					open={leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.field : undefined}
					filterImmunity
				>
					{settings.fieldGroup.settings.fieldFont.active && (
						<DropDownSetting
							type={SharedInputSettingsFields.fieldFont}
							onChange={(data) => onChange(data, 'fieldStyles')}
							selected={fieldFont}
							label={settings.fieldGroup.settings.fieldFont.label}
							options={allFontOptions}
						/>
					)}
					<MultiSetting label="Field font size & inner padding">
						{settings.fieldGroup.settings.fieldFontSize.active && (
							<NumberSetting
								type={SharedInputSettingsFields.fieldFontSize}
								onChange={(data) => onChange(data, 'fieldStyles')}
								text={fieldFontSize}
								label={settings.fieldGroup.settings.fieldFontSize.label}
								ariaLabel="Font size in pixels"
							/>
						)}
					</MultiSetting>
					{settings.fieldGroup.settings.fieldFontColour.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.fieldFontColour}
							onChange={(data) => onChange(data, 'fieldStyles')}
							selectedColor={fieldFontColour}
							label={settings.fieldGroup.settings.fieldFontColour.label}
						/>
					)}
					{settings.fieldGroup.settings.fieldTextDecoration.active && (
						<DecorationSettings
							type="field"
							label={settings.fieldGroup.settings.fieldTextDecoration.label}
							onDecorationsChange={(data) => onDecorationsChange(data, 'fieldStyles')}
							textDecoration={fieldTextDecoration}
							fontStyle={fieldFontStyle}
							fontWeight={fieldFontWeight}
						/>
					)}
				</ToggleBlock>
			)}

			{settings.labelGroup.active && (
				<ToggleBlock
					label={settings.labelGroup.label}
					open={leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.label : undefined}
					filterImmunity
				>
					<MultiSetting label="Label font & select label position">
						{settings.labelGroup.settings.labelFont.active && (
							<DropDownSetting
								type={SharedInputSettingsFields.labelFont}
								onChange={(data) => onChange(data, 'fieldStyles')}
								selected={labelFont}
								label={settings.labelGroup.settings.labelFont.label}
								options={allFontOptions}
							/>
						)}
						{settings.labelGroup.settings.labelPosition.active && (
							<DropDownSetting
								type={SharedInputSettingsFields.labelPosition}
								onChange={onLabelPositionChange}
								selected={labelPosition}
								label={settings.labelGroup.settings.labelPosition.label}
								options={FormLabelPositions}
							/>
						)}
					</MultiSetting>
					<MultiSetting label="Label width & font size">
						{settings.labelGroup.settings.labelFontSize.active && (
							<NumberSetting
								type={SharedInputSettingsFields.labelFontSize}
								onChange={(data) => onChange(data, 'fieldStyles')}
								text={labelFontSize}
								label={settings.labelGroup.settings.labelFontSize.label}
								ariaLabel="Font size in pixels"
							/>
						)}
						{settings.labelGroup.settings.labelWidth.active && (
							<NumberSetting
								type={SharedInputSettingsFields.labelWidth}
								onChange={(data) => onChange(data, 'fieldStyles')}
								text={labelWidth}
								label={settings.labelGroup.settings.labelWidth.label}
								ariaLabel="Label width in pixels"
							/>
						)}
					</MultiSetting>
					{settings.labelGroup.settings.labelFontColour.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.labelFontColour}
							onChange={(data) => onChange(data, 'fieldStyles')}
							selectedColor={labelFontColour}
							label={settings.labelGroup.settings.labelFontColour.label}
						/>
					)}
					{settings.labelGroup.settings.asteriskColor.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.asteriskColor}
							onChange={(data) => onChange(data, 'fieldStyles')}
							selectedColor={asteriskColor}
							label={settings.labelGroup.settings.asteriskColor.label}
						/>
					)}
					{settings.labelGroup.settings.labelTextDecoration.active && (
						<DecorationSettings
							type="label"
							label={settings.labelGroup.settings.labelTextDecoration.label}
							onDecorationsChange={(data) => onDecorationsChange(data, 'fieldStyles')}
							textDecoration={labelTextDecoration}
							fontStyle={labelFontStyle}
							fontWeight={labelFontWeight}
						/>
					)}
				</ToggleBlock>
			)}
		</SettingsBox>
	);
};

export default SummaryPageSettings;
