import cloneDeep from 'lodash/cloneDeep';
import hasProperty from 'lodash/has';

import { ComparerTypesExpectedValue, ComparerTypesExpectedValueByFieldType, ConditionalLogicTypes, LogicErrors } from '@/util/resources';

import { LogicAction } from '@/components/Conditions/models/model';

export function getInvalidLogics(logics, allFields) {
	if (!logics?.length) return [];

	return logics.map((logic) => getInvalidLogic(logic, allFields));
}

export function getInvalidLogic(logic, allFields = []) {
	const errors: string[] = [];
	const result: { errors?: string[]; settings?: unknown[]; actions?: unknown[] } = {};

	if (!logic.type) errors.push(LogicErrors.missingType);
	if (!logic.name) errors.push(LogicErrors.missingName);
	if (!logic.id) errors.push(LogicErrors.missingId);

	const settingsResult = getInvalidConditions(logic.settings, allFields);
	const actionsResult = getInvalidActions(logic.actions, allFields);

	if (!settingsResult.conditions?.length) errors.push(LogicErrors.missingSettings);
	if (!actionsResult.actions?.length) errors.push(LogicErrors.missingActions);

	if (errors.length) result.errors = errors;
	if (settingsResult.hasError) result.settings = settingsResult.conditions;
	if (actionsResult.hasError) result.actions = actionsResult.actions;

	if (Object.keys(result).length) return result;

	return undefined;
}

export function getInvalidConditions(conditions, allFields) {
	let hasError = false;

	const conditionsWithErrors = conditions.map((condition) => {
		const errors: string[] = [];
		let settings = [];

		if (!condition.operator) errors.push(LogicErrors.missingOperator);
		if (!condition.type) errors.push(LogicErrors.missingType);

		if (condition.type === ConditionalLogicTypes.condition) {
			if (allFields?.length && condition.field) {
				const field = allFields.find((field) => field.id === condition.field.id);
				// Mutates the field to update it
				condition.field = cloneDeep(field);
			}

			const isFieldValid = isConditionsFieldValid(condition.field);

			if (!isFieldValid.valid) errors.push(...isFieldValid.errors);
			else {
				const criteriaResult = getInvalidCriteria(condition.settings, condition?.field);
				hasError = hasError || criteriaResult.hasError;
				settings = criteriaResult.criteria;
			}
		}

		if (condition.type === ConditionalLogicTypes.group) {
			const conditionsResult = getInvalidConditions(condition.settings, allFields);
			hasError = hasError || conditionsResult.hasError;
			settings = conditionsResult.conditions;
		}

		if (!settings.length) errors.push(LogicErrors.missingSettings);

		if (errors.length || hasError) {
			hasError = true;
			return { settings, errors };
		}

		return undefined;
	});

	return { hasError, conditions: conditionsWithErrors };
}

export function getInvalidCriteria(criteria, field) {
	const fieldHasOptions = Boolean(field?.options?.length);
	let hasError = false;

	const criteriaWithErrors = criteria.map((criterion) => {
		const errors: string[] = [];

		if (!criterion.type) errors.push(LogicErrors.missingType);
		if (!criterion.id) errors.push(LogicErrors.missingId);

		if (!criterion.comparer) errors.push(LogicErrors.missingComparer);
		else {
			const expectedValueType =
				ComparerTypesExpectedValueByFieldType[field.type]?.[criterion.comparer] || ComparerTypesExpectedValue[criterion.comparer];

			switch (expectedValueType) {
				case 'empty':
					if (criterion.value) errors.push(LogicErrors.invalidValue);
					break;

				case 'array':
					if (!Array.isArray(criterion.value)) errors.push(LogicErrors.invalidValue);
					else {
						if (fieldHasOptions)
							criterion.value = criterion.value.filter((value) => field.options.find((option) => option.value === value));

						if (!criterion.value.length) errors.push(LogicErrors.missingValue);
					}
					break;

				case 'object':
					if (typeof criterion.value !== 'object') errors.push(LogicErrors.invalidValue);
					else if (Object.keys(criterion.value).length !== Object.values(criterion.value).filter(Boolean).length)
						errors.push(LogicErrors.missingValue);
					break;

				default:
					if (!criterion.value) errors.push(LogicErrors.missingValue);
					else if (
						(fieldHasOptions && !field.options.find((option) => criterion.value === option.value)) ||
						expectedValueType !== typeof criterion.value
					) {
						errors.push(LogicErrors.invalidValue);
					}
			}
		}

		if (errors.length) {
			hasError = true;
			return { errors };
		}

		return undefined;
	});

	return { hasError, criteria: criteriaWithErrors };
}

export function getInvalidActions(actions: LogicAction[], allFields) {
	let hasError = false;

	const actionsWithErrors = (actions || []).map((action) => {
		const errors: string[] = [];

		if (!action.action) {
			errors.push(LogicErrors.missingAction);
		} else {
			if (allFields?.length && action.field) {
				const field = allFields.find((field) => field.id === action.field.id);
				// Mutates the field to update it
				action.field = cloneDeep(field);
			}

			const isFieldValid = isConditionsFieldValid(action.field);

			if (!isFieldValid.valid) {
				errors.push(...isFieldValid.errors);
			}
		}

		if (errors.length) {
			hasError = true;
			return { errors };
		}

		return undefined;
	});

	return { hasError, actions: actionsWithErrors };
}

export function isConditionsFieldValid(field) {
	if (!field) return { valid: false, errors: [LogicErrors.invalidField] };

	const errors: string[] = [];

	if (!hasProperty(field, 'type') || !field.type) errors.push(LogicErrors.invalidField);
	if (!hasProperty(field, 'id') || !field.id) errors.push(LogicErrors.invalidField);
	if (!hasProperty(field, 'name') || !field.name) errors.push(LogicErrors.invalidField);

	if (errors.length) return { valid: false, errors };

	return { valid: true, errors: [] };
}

export function isLogicsValid(invalidLogics) {
	if (!invalidLogics?.length) return true;

	return !invalidLogics.filter(Boolean).length;
}
