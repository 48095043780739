import { useEffect } from 'react';

import { useToast } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import useNotificationStore from '@/stores/NotificationStore';

const NotificationPopup = () => {
	const toast = useToast();
	const { notifications, hideNotification } = useNotificationStore(
		useShallow((state) => ({
			notifications: state.notifications,
			hideNotification: state.hideNotification,
		})),
	);

	useEffect(() => {
		(notifications || [])
			.filter((notification) => !toast.isActive(notification.id))
			.forEach((notification) => {
				const position = notification.position || 'bottom-left';
				toast({
					id: notification.id,
					status: notification.type.toLowerCase(),
					duration: notification.duration,
					position,
					onCloseComplete: () => hideNotification(notification.id),
					isClosable: notification.isClosable,
					title: notification.title,
					description: notification.text,
				});
			});
	}, [notifications]);

	return null;
};

export default NotificationPopup;
