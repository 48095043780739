import config from 'config/config';

import { claimsKeys, getUser } from '@/util/auth/auth';
import { makeApiGatewayCall } from '@/util/helper';

let env = 'staging';

if (import.meta.env.MODE === 'preproduction') {
	env = 'pre-production';
} else if (import.meta.env.MODE === 'production') {
	env = 'production';
}

export const getTenants = () => {
	const user = getUser();

	let data = {
		labels: {
			ProductCode: {
				operator: 'Include',
				values: ['XMCloud'],
			},
			Environment: { operator: 'Include', values: [env] },
		},
		state: 'Active',
		organizationId: user[claimsKeys.ORG_ID],
	};

	let url = config.inventory.base + '/api/inventory/v1/tenants/list?pagenumber=1&pagesize=100';

	return makeApiGatewayCall(url, 'post', data).catch((error) => {
		if (error.response && error.response.data.status === 404) {
			return false;
		} else {
			throw error;
		}
	});
};
