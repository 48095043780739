import React from 'react';

import { RowDefaultProperties, slotSubstractor } from 'shared/src/utils/shared.js';

import LandingSlot from './Slot.react';

class LandingRow extends React.PureComponent {
	static defaultProps = {
		repeat_count: RowDefaultProperties.repeat_count,
		sort_products: RowDefaultProperties.sort_products,
	};

	render() {
		const { padding, multiPadding, general_styles, successAction } = this.props;
		const deductPadding = multiPadding && multiPadding.allow ? multiPadding.paddingRight + multiPadding.paddingLeft : padding * 2;

		let slots = [];
		if (this.props.slots) {
			// let centerSpacing = this.props.slot_spacing_center, sideSpacing = this.props.slot_spacing_side;

			slots = this.props.slots.map((item, i) => {
				const slotWidth =
					(this.props.rowWidth ? this.props.rowWidth : general_styles.structureWidth) / slotSubstractor[item.type] -
					deductPadding / slotSubstractor[item.type] -
					(this.props.borderWidth * 2) / slotSubstractor[item.type];

				return (
					<LandingSlot
						{...item}
						entityId={this.props.entityId}
						lastPage={this.props.lastPage}
						pageIndex={this.props.pageIndex}
						type={item.type}
						key={i + 1}
						slotWidth={slotWidth}
						responsive={this.props.mobileProps && this.props.mobileProps.responsive}
						address={{ rowId: this.props.id, slot: i }}
						general_styles={this.props.general_styles}
						successAction={successAction}
					/>
				);
			});
		}

		const id = this.props.id ? this.props.id.toString().replace(/-/g, '') : '';

		return (
			<div
				data-structure-type="row"
				id={id}
				className={`landing-page-row row${id} ${
					(this.props.mobileProps && this.props.mobileProps.responsive && 'responsive-row') || ''
				} lp-ie-flex-shrink`}
			>
				<div className="inner-row-div">
					<div className="border-wrapper">
						<div className="row-slot-wrapper lp-flex-container">{slots}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingRow;
