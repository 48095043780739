import { importedConfig } from 'config/config';

import * as Application from './application';
import { loadInitialData, loadInitialMoosendData } from './util/apiUtils/initialData';
import DomHelper from './util/domHelper';

export const runEditor = async () => {
	let allDraftsMetadata;

	try {
		let overlay = document.querySelector('#initial-overlay');
		const messageDiv = overlay && overlay.querySelector('#initial-error');
		if (messageDiv) messageDiv.innerHTML = '';

		if (overlay) overlay.style.display = 'flex';

		importedConfig.query = Application.fetchQueryStringConfig(); //add query string data to the imported config

		if (import.meta.env.MODE !== 'test') {
			allDraftsMetadata = await loadInitialData();

			await loadInitialMoosendData(allDraftsMetadata); //no await here, because this does not have to block
		}

		Application.afterInitialDataLoad();
	} catch (error) {
		console.log(error);
		DomHelper.handleInitialErrors(error);
	}
};
