import React, { useEffect, useState } from 'react';

import ImageSetting from '@/components/gui/shared/settings/ImageSetting.react';

const ImagePickerModal = ({ show, ...props }) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (loading) {
			const loader = document.createElement('i');
			loader.className = 'fa fa-circle-o-notch fa-spin fa-4x';
			loader.id = 'image-picker-loader';
			loader.style.position = 'fixed';
			loader.style.top = '50%';
			loader.style.left = '58%';
			loader.style.zIndex = 9999999999;
			loader.style.margin = '-45px';
			document.body.append(loader);
		} else {
			const loader = document.getElementById('image-picker-loader');
			if (loader) loader.parentNode.removeChild(loader);
		}

		return () => {
			const loader = document.getElementById('image-picker-loader');
			if (loader) loader.parentNode.removeChild(loader);
		};
	}, [loading]);

	return (
		<div className="image-picker-modal" style={{ display: show ? 'block' : 'none' }}>
			<ImageSetting {...props} setLoader={setLoading} />
		</div>
	);
};

export default ImagePickerModal;
