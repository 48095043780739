import React from 'react';

import { Checkbox } from '@chakra-ui/react';
import { useDeepCompareMemo } from 'use-deep-compare';

import { FormsDefaultSorting, OrderByTypes } from '@/components/Table/strings';

import BaseTable from './BaseTable';
import { FormsTableLoader } from './skeletons/FormsTableLoader';
import {
	EntitiesTableItemActions,
	EntitiesTableItemDetails,
	EntitiesTableItemLabels,
	EntitiesTableItemSelect,
	EntitiesTableItemStatus,
} from './TableItems';

const orderCategories = {
	Checkbox: { filter: '', label: '' },
	Name: { filter: 'Name', label: 'Name' },
	Views: { filter: 'Views', label: 'Views' },
	Interactions: { filter: 'Interactions', label: 'Interactions' },
	Submissions: { filter: 'Submissions', label: 'Submissions' },
	Status: { filter: 'Status', label: 'Status' },
	Labels: { filter: 'Labels', label: 'Labels' },
	Action: { filter: '', label: '' },
};

export const FormsTable = ({
	activeTags,
	allSelected,
	allTags,
	analytics: analyticsProp,
	changeOrder,
	entities,
	loaders,
	onTagClick: onTagClickProp,
	onTagsRemove: onTagsRemoveProp,
	onTagsSave: onTagsSaveProp,
	onToggleAll: onToggleAllProp,
	order,
	selectEntity: selectEntityProp,
	selectedEntitiesIds,
	takeEntityAction: takeEntityActionProp,
}) => {
	const orderDirection = () => {
		if (order.includes(orderCategories.Status.filter)) {
			if (order.includes('Ascending')) {
				return true;
			}
			return false;
		}
		return undefined;
	};

	const formattedColumns = useDeepCompareMemo(() => {
		return entities.map((item) => {
			const { Entity } = item;
			const analytics = analyticsProp && analyticsProp.length && analyticsProp.find((item) => item.EntityId === Entity.Id);

			const selectEntity = (data) => {
				if (selectEntity) selectEntityProp(data);
			};

			const takeEntityAction = (action, entityId) => {
				if (takeEntityAction) takeEntityActionProp(action, entityId);
			};

			const onTagsSave = (tags, entityId) => {
				if (onTagsSave) onTagsSaveProp(tags, entityId);
			};

			const onTagsRemove = (data) => {
				if (onTagsRemove) onTagsRemoveProp(data);
			};

			const onTagClick = (tag) => {
				if (onTagClick) onTagClickProp(tag);
			};

			return {
				Checkbox: {
					item: <EntitiesTableItemSelect entity={item} checked={selectedEntitiesIds.includes(Entity.Id)} selectEntity={selectEntity} />,
					restProps: { className: 'notranslate' },
				},
				Name: {
					item: <EntitiesTableItemDetails entity={item} takeEntityAction={takeEntityAction} />,
				},
				Views: {
					item: <>{analytics ? analytics.Statistics.FormAnalyticsViewedCount : 0}</>,
					restProps: { 'data-testid': `entity-${item.Entity.Id}-views` },
				},
				Interactions: {
					item: <>{analytics ? analytics.Statistics.FormAnalyticsInteractedCount : 0}</>,
					restProps: { 'data-testid': `entity-${item.Entity.Id}-interactions` },
				},
				Submissions: {
					item: <>{analytics ? analytics.Statistics.FormAnalyticsSubmittedCount : 0}</>,
					restProps: { 'data-testid': `entity-${item.Entity.Id}-submissions` },
				},
				Status: {
					item: <EntitiesTableItemStatus entity={item} takeEntityAction={takeEntityAction} />,
				},
				Labels: {
					item: (
						<EntitiesTableItemLabels
							entity={item}
							activeTags={activeTags}
							allTags={allTags}
							onTagsSave={onTagsSave}
							onTagsRemove={onTagsRemove}
							onTagClick={onTagClick}
						/>
					),
				},
				Action: {
					item: <EntitiesTableItemActions entity={item} takeEntityAction={takeEntityAction} />,
				},
			};
		});
	}, [
		activeTags,
		analyticsProp,
		allTags,
		entities,
		selectedEntitiesIds,
		onTagClickProp,
		onTagsRemoveProp,
		onTagsSaveProp,
		selectEntityProp,
		takeEntityActionProp,
	]);

	const formattedHeaders = useDeepCompareMemo(() => {
		const onToggleAll = (e) => {
			if (onToggleAll) onToggleAllProp(e.target.checked);
		};

		return {
			Checkbox: {
				label: '',
				width: '4%',
				restHeaderProps: { onChange: onToggleAll },
				item: <Checkbox isChecked={allSelected} data-testid="table-head-select-checkbox" />,
			},
			Name: {
				label: 'Name',
				isSortable: true,
				width: ['25%', '25%', '25%', '25%', '25%', '35%'],
				restHeaderProps: { cursor: 'pointer' },
			},
			Views: {
				label: 'Views',
				width: '7%',
			},
			Interactions: {
				label: 'Interactions',
				width: '7%',
			},
			Submissions: {
				label: 'Submissions',
				width: '7%',
			},
			Status: {
				label: 'Status',
				isSortable: true,
				width: '9%',
				restHeaderProps: { cursor: 'pointer' },
			},
			Labels: {
				label: 'Labels',
				width: ['38%', '38%', '38%', '38%', '38%', '31%'],
			},
			Action: {
				label: '',
				width: '4%',
			},
		};
	}, [allSelected, onToggleAllProp]);

	const renderDesktop = () => {
		return (
			<BaseTable
				headers={formattedHeaders}
				data={formattedColumns}
				order={order}
				orderByTypes={OrderByTypes}
				orderCategories={orderCategories}
				orderDirection={orderDirection}
				changeOrder={changeOrder}
				loaders={loaders}
				skeletonComponent={<FormsTableLoader />}
				defaultOrderBy={FormsDefaultSorting}
			/>
		);
	};

	const renderTable = () => {
		return <>{renderDesktop()}</>;
	};

	return renderTable();
};

FormsTable.defaultProps = {
	loaders: {
		table: false,
		tableDataUpdate: false,
	},
};
