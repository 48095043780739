import { useEffect } from 'react';

import { Box, CircularProgress } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import useStylingStore from '@/stores/StylingStore';

import StylingHeader from '@/components/Table/components/StylingHeader';
import StylingLeftSidebar from '@/components/Table/components/StylingLeftSidebar';
import StylingPreview from '@/components/Table/components/StylingPreview';

export const StylingEdit = () => {
	const params = useParams();
	const isLoading = useStylingStore((state) => state.isLoading);

	useEffect(() => {
		useStylingStore.getState().getStyle(params.id);

		setTimeout(() => {
			useStylingStore.getState().generatePreview(params.id);
		}, 1);
	}, []);

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" h="100%">
				<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" />
			</Box>
		);
	}

	return (
		<Box h="100%">
			<StylingHeader id={params.id} />
			<Box display="flex" h="calc(100% - 4.5rem)" px={7} pb={7}>
				<Box display="flex" flex={1} borderRadius="lg" border="1px solid" borderColor="chakra-border-color">
					<StylingLeftSidebar />
					<StylingPreview />
				</Box>
			</Box>
		</Box>
	);
};

export default StylingEdit;
