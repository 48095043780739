import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';

import { Icon } from '@/components/gui/shared/Icon';

export const TableHeader = (props) => {
	const renderCreateButtons = () => {
		const { initiateCreateNew } = props;
		return (
			<Button ml="auto" leftIcon={<Icon path={mdiPlus} />} onClick={initiateCreateNew} data-testid="button-create-new">
				Create form
			</Button>
		);
	};

	return (
		<Box hideBelow="md">
			<Box display="flex" flexWrap="wrap" alignItems="center" pt={[2.5, 2.5, 6]} px={[2.5, 2.5, 8]} m="0 auto" maxW={2000}>
				<Heading as="h1">Forms</Heading>

				{renderCreateButtons()}
			</Box>

			<Text variant="subtle" px={[2.5, 2.5, 8]} py={2.5} m="0 auto" maxW={2000}>
				Create and manage your forms
			</Text>
		</Box>
	);
};
