import { useCallback } from 'react';

import WebStorage from '@/util/webStorage';

export const updateSearchParamsIfNeeded = (searchParams: URLSearchParams, name: string, value: string | undefined) => {
	if (!value && searchParams.has(name)) {
		searchParams.delete(name);

		return true;
	}

	if (value && searchParams.get(name) != value) {
		searchParams.set(name, value);

		return true;
	}

	return false;
};

const removeAuth0QueryParamsFromUrl = (searchParams: URLSearchParams) => {
	if (searchParams.has('code') || searchParams.has('state')) {
		searchParams.delete('code');
		searchParams.delete('state');

		return true;
	}

	return false;
};

export const useRememberTenantAndOrg = () => {
	return useCallback((organizationId: string | undefined, tenantName: string | undefined) => {
		const { location } = window;
		const searchParams = new URLSearchParams(location.search);
		const organizationIdUpdated = updateSearchParamsIfNeeded(searchParams, 'organization', organizationId);

		const tenantNameUpdated = updateSearchParamsIfNeeded(searchParams, 'tenantName', tenantName);

		const isAuth0ParamsRemoved = removeAuth0QueryParamsFromUrl(searchParams);

		if (organizationIdUpdated || tenantNameUpdated || isAuth0ParamsRemoved) {
			window.history.replaceState({}, '', `${location.pathname}?${searchParams.toString()}`);
		}

		const tenantInfo = { organization: organizationId, tenantName: tenantName };

		WebStorage.set('tenant_info', tenantInfo);
	}, []);
};
