import React from 'react';

import { Box } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const EmailInputSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

const EmailInputSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		const settings = {
			[data.type]: data.value,
		};

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<Box className="email-input-settings">
			<EmailInputSettingComponents {...props} onChange={onChange} settings={{ pattern: { active: true } }} />
		</Box>
	);
};

export default EmailInputSettings;
