import React from 'react';

import { Box, IconButton, Spinner, Tooltip } from '@chakra-ui/react';
import { mdiCog, mdiContentCopy, mdiContentSave, mdiCursorMove, mdiTrashCan } from '@mdi/js';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Flipped, spring } from 'react-flip-toolkit';
import { AllComponentTypes, SpecialComponentTypes } from 'shared/src/utils/shared.js';

import useCommentStore from '@/stores/CommentStore';
import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';

import { attachOnMiddle } from '@/util/dndHelper';
import { pathPrefix, stringifyAddress } from '@/util/helper';
import itemTypes from '@/util/itemTypes';
import { LimitedComponentTypes, SlotsNotAllowedForElementDesigner } from '@/util/resources';

import { HoverExtended } from '@/components/gui/content/Row.react';
import { Icon } from '@/components/gui/shared/Icon';

export const elementSourceSpec = {
	beginDrag(props) {
		useContentStore.getState().toggleDragging(props.id);
		return {
			id: props.id,
			address: props.address,
			type: props.type,
			pageIndex: props.pageIndex,
		};
	},

	endDrag() {
		const contentStore = useContentStore.getState();
		contentStore.toggleDragging();

		// const didDrop = monitor.didDrop();

		// if (!didDrop) {
		// 	const callRemoveActions = () => {
		// 		contentStore.unsetClickedElement();
		// 		contentStore.unsetClickedRow();
		// 		contentStore.deactivateAddStructureBoxHelper();
		// 		contentStore.deactivateAddElementBoxHelper();
		// 		contentStore.removeComponent(props.address);
		// 	};

		// 	if (useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.element) {
		// 		if (props.address) {
		// 			callRemoveActions();
		// 		}
		// 	} else {
		// 		let onOk = () => {
		// 			if (props.address) {
		// 				callRemoveActions();
		// 			}
		// 		};

		// 		let message = ModalMessages.REMOVE_ELEMENT;

		// 		useModalStore.getState().showDontAskModal({ type: 'element', message, onOk });
		// 	}
		// }
	},

	canDrag(props) {
		if (
			props.isMobileView ||
			props.noDrag ||
			location.pathname === pathPrefix() + '/comment-mode' ||
			props.type === SpecialComponentTypes.fieldPlaceholder
		) {
			return false;
		}
		return true;
	},
};

export const elementTargetSpec = {
	hover(props, monitor, component) {
		const { address, type, pageIndex } = monitor.getItem();
		const { address: overAddress } = props;
		const contentStore = useContentStore.getState();

		if (!overAddress) {
			throw new Error('component address error');
		}

		if (
			LimitedComponentTypes.includes(type) &&
			useContentStore.getState().validity.limitedComponents[props.pageIndex].find((item) => item.type === type) &&
			pageIndex !== props.pageIndex
		) {
			return;
		}

		if (type === AllComponentTypes.recaptcha && props.pageIndex < props.lastPage) {
			return;
		}

		const slotsNotAllowed = SlotsNotAllowedForElementDesigner;

		if (slotsNotAllowed[type].includes(props.slot_type)) {
			return;
		}

		attachOnMiddle(
			component,
			monitor,
			() => {
				if (!address) {
					contentStore.insertComponent({
						insertAddress: overAddress,
						componentData: {
							type,
							loading: monitor.getItem().isCustomElement ? true : false,
							id: monitor.getItem().id,
						},
						additionalData: { componentType: type, pageIndex: props.pageIndex },
					});
					monitor.getItem().address = overAddress;
				} else if (address && JSON.stringify(address) !== JSON.stringify(overAddress)) {
					if (address.component - 1 !== overAddress.component) {
						contentStore.moveComponent({ fromAddress: address, toAddress: overAddress });
						monitor.getItem().address = overAddress;
					} else if (address.slot !== overAddress.slot && address.component - 1 === overAddress.component) {
						const newAddress = { ...overAddress, component: overAddress.component + 1 };
						contentStore.moveComponent({ fromAddress: address, toAddress: newAddress });
						monitor.getItem().address = newAddress;
					}
				}
			},
			() => {
				if (!address) {
					contentStore.insertComponent({
						insertAddress: overAddress,
						componentData: {
							type,
							loading: monitor.getItem().isCustomElement ? true : false,
							id: monitor.getItem().id,
						},
						additionalData: { componentType: type, pageIndex: props.pageIndex },
					});
					monitor.getItem().address = overAddress;
				} else if (address && JSON.stringify(address) !== JSON.stringify(overAddress)) {
					if (overAddress.component - 1 !== address.component) {
						contentStore.moveComponent({ fromAddress: address, toAddress: overAddress });
						monitor.getItem().address = overAddress;
					}
				}
			},
		);
	},
};

function sourceCollect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
		connectDragPreview: connect.dragPreview(),
	};
}

function targetCollect(connect) {
	return {
		connectDropTarget: connect.dropTarget(),
	};
}

const HoverMenu = (props) => {
	const { type } = props;
	return (
		<Box
			display="flex"
			position="absolute"
			left={0}
			borderRadius="base"
			top="-21px"
			zIndex={10}
			bgColor="purple.400"
			data-testid="hovermenu-container"
		>
			<HoverExtended {...props} type="element">
				{type !== SpecialComponentTypes.fieldPlaceholder &&
					props.connectDragSource(
						<div>
							<Tooltip placement="top" label="Drag to move">
								<IconButton
									aria-label={'Drag to move'}
									variant="unstyled"
									icon={<Icon path={mdiCursorMove} color="chakra-inverse-text" />}
									size="xs"
									display="flex"
									alignItems="center"
									cursor="move"
									className="element-dnd-item"
								/>
							</Tooltip>
						</div>,
					)}
				<Tooltip placement="top" label="Settings">
					<IconButton
						aria-label={'Settings'}
						variant="unstyled"
						icon={<Icon path={mdiCog} color="chakra-inverse-text" />}
						size="xs"
						display="flex"
						alignItems="center"
						data-testid="toggle-settings-component"
						onClick={props.toggleSettings}
					/>
				</Tooltip>
				<Tooltip placement="top" label="Duplicate">
					<IconButton
						aria-label={'Duplicate'}
						variant="unstyled"
						icon={<Icon path={mdiContentCopy} color="chakra-inverse-text" />}
						size="xs"
						display="flex"
						alignItems="center"
						data-testid="duplicate-component"
						onClick={props.duplicateElement}
					/>
				</Tooltip>
				<Tooltip placement="top" label="Remove">
					<IconButton
						aria-label={'Remove'}
						variant="unstyled"
						icon={<Icon path={mdiTrashCan} color="chakra-inverse-text" />}
						size="xs"
						display="flex"
						alignItems="center"
						data-testid="remove-component"
						onClick={props.removeComponent}
					/>
				</Tooltip>
				{type !== SpecialComponentTypes.fieldPlaceholder && (
					<Tooltip placement="top" label="Save item">
						<IconButton
							aria-label={'Save item'}
							variant="unstyled"
							icon={<Icon path={mdiContentSave} color="chakra-inverse-text" />}
							size="xs"
							display="flex"
							alignItems="center"
							onClick={props.saveCustomElement}
						/>
					</Tooltip>
				)}
			</HoverExtended>
		</Box>
	);
};

let Element = (ComposedComponent) => {
	class HigherOrderClass extends React.PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				menu: false,
				transformedHtml: '',
				canDrag: true,
			};
		}

		changeCanDrag(value) {
			this.setState({ canDrag: value });
		}

		render() {
			const { connectDragSource, connectDragPreview, connectDropTarget, address, isScriptMode, isDragging } = this.props;

			// const row = isFormDesigner() && address && findRow(useContentStore.getState().content.rows, address.rowId).row;
			// if (row && row.slots[address.slot].components[address.component].hidden) {
			// 	return null;
			// }

			if (!connectDragSource || !connectDragPreview || !connectDropTarget) throw new Error('dnd functions not present');

			let displayElement = this.state.displayCode ? 'none' : 'block';

			let display = 'block';

			let adr = stringifyAddress(address);

			connectDragPreview(getEmptyImage(), { captureDraggingState: false });

			const scriptStyles = isScriptMode ? { cursor: 'pointer' } : {};

			const comments = this.props.comments
				? this.props.comments.filter((item) => {
						return item.type === 'element' && item.targetId === this.props.uniqueId;
					})
				: [];

			if (this.props.loading) {
				return (
					<Box display="flex" h="100%" minH="24" w="100%" margin="0 auto" alignItems="center" justifyContent="center">
						<Spinner thickness="4px" emptyColor="neutral.200" color="primary" size="xl" />
					</Box>
				);
			}

			let elem = connectDropTarget(
				<div
					onMouseEnter={() => !isDragging && this.setState({ menu: true })}
					onMouseLeave={() => this.setState({ menu: false })}
					style={{ display, position: 'relative', width: '100%', ...scriptStyles }}
					onClick={this.toggleSettings}
					data-testid="editor-element"
				>
					<Box className={`component-element element${adr}`} display={displayElement} position="relative">
						{(this.state.menu || this.props.activeElement) && !isDragging && (
							<React.Fragment>
								<Box position="absolute" borderTop="3px solid" borderTopColor="purple.400" zIndex={1} top={0} left={0} right={0} />
								<Box position="absolute" borderBottom="3px solid" borderBottomColor="purple.400" zIndex={1} bottom={0} left={0} right={0} />
								<Box position="absolute" borderRight="3px solid" borderRightColor="purple.400" zIndex={1} top={0} right={0} bottom={0} />
								<Box position="absolute" borderLeft="3px solid" borderLeftColor="purple.400" zIndex={1} top={0} left={0} bottom={0} />
							</React.Fragment>
						)}
						{!this.props.isMobileView && !isScriptMode && (this.state.menu || this.props.activeElement) && !isDragging && (
							<HoverMenu
								type={this.props.type}
								teamMembers={this.props.teamMembers}
								showResolvedComments={this.props.showResolvedComments}
								toggleHtmlView={this.toggleHtmlView}
								duplicateElement={this.duplicateElement}
								toggleSettings={this.toggleSettings}
								removeComponent={this.removeComponent}
								connectDragSource={connectDragSource}
								onSubmitComment={this.onSubmitComment}
								onEditComment={this.onEditComment}
								onDeleteComment={this.onDeleteComment}
								saveCustomElement={this.saveCustomElement}
								showComments={this.props.activeElement}
								comments={comments}
								targetId={this.props.uniqueId}
							/>
						)}

						<Box>
							{location.pathname === pathPrefix() + '/comment-mode' && (
								<Box zIndex={1} position="absolute" left={0} right={0} bottom={0} top={0} />
							)}
							<ComposedComponent
								{...this.props}
								isDragging={isDragging}
								changeCanDrag={this.changeCanDrag.bind(this)}
								ref="composedComponent"
								displayCode={this.state.displayCode}
								toggleHtmlView={this.toggleHtmlView}
							/>
						</Box>
					</Box>
				</div>,
			);

			//check canDrag before adding connectDragSource because it was causing a bug in text components when trying to select text with mouse cursor (Firefox, Edge)
			return this.state.canDrag ? connectDragSource(elem) : elem;
		}

		removeComponent = (e) => {
			if (e) e.stopPropagation();
			const contentStore = useContentStore.getState();

			contentStore.unsetClickedElement();
			contentStore.unsetClickedRow();
			contentStore.deactivateAddStructureBoxHelper();
			contentStore.deactivateAddElementBoxHelper();
			contentStore.userRemoveComponent({
				address: this.props.address,
				type: this.props.type,
				pageIndex: this.props.pageIndex,
			});
		};

		hideComponent = (e) => {
			if (e) e.stopPropagation();

			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings: {
					hidden: !this.props.hidden,
				},
			});
		};

		onSubmitComment = (text, parentId) => {
			const details = {
				status: 'unresolved',
				content: text,
				type: 'element',
				parentId,
				targetId: this.props.uniqueId,
			};
			useCommentStore.getState().saveComment(details);
		};

		onEditComment = (details) => {
			useCommentStore.getState().editComment(details);
		};

		onDeleteComment = (id) => {
			useCommentStore.getState().deleteComment(id);
		};

		toggleSettings = () => {
			if (this.props.address) useLeftSidebarStore.getState().toggleElementSettings({ address: this.props.address });
			if (this.props.address) useContentStore.getState().setClickedElement(this.props.address);
		};

		saveCustomElement = (e) => {
			e.stopPropagation();
			useLeftSidebarStore.getState().setCustomElementToSave(this.props);
			useModalStore.getState().showCustomElementModal();
		};

		duplicateElement = (e) => {
			if (e) e.stopPropagation();

			if (this.props.address) useContentStore.getState().duplicateElement({ address: this.props.address });
		};
	}

	const HigherOrderClassComp = DragSource(
		itemTypes.ELEMENT,
		elementSourceSpec,
		sourceCollect,
	)(DropTarget(itemTypes.ELEMENT, elementTargetSpec, targetCollect)(HigherOrderClass));

	const HigherOrderClassWrapper = React.memo((props) => {
		const address = React.useMemo(() => {
			return { rowId: props.rowId, slot: props.slotIndex, component: props.index };
		}, [props.index, props.rowId, props.slotIndex]);

		return (
			<Flipped
				flipId={props.id}
				onAppear={React.useCallback(
					(el) =>
						spring({
							onUpdate: (val) => {
								el.style.opacity = val;
							},
						}),
					[],
				)}
				onExit={React.useCallback((el, index, removeElement) => {
					spring({
						onUpdate: (val) => {
							el.style.opacity = 1 - val;
						},
						onComplete: removeElement,
					});

					return () => {
						// el.style.opacity = '';
						removeElement();
					};
				}, [])}
			>
				<div>
					<HigherOrderClassComp {...props} address={address} />
				</div>
			</Flipped>
		);
	});

	return HigherOrderClassWrapper;
};

export default Element;
