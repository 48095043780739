import React from 'react';

import { Box, Button, Flex, HStack, IconButton, StackDivider, Tooltip } from '@chakra-ui/react';
import { mdiCellphone, mdiMonitor, mdiRedo, mdiUndo } from '@mdi/js';
import { ModalMessages } from 'config/messages';
import { shallow } from 'zustand/shallow';

import useCommentStore from '@/stores/CommentStore';
import useContentStore from '@/stores/ContentStore';
import useContentViewStore, { changeView } from '@/stores/ContentViewStore';
import useEntitiesStore from '@/stores/EntitiesStore';
import useModalStore from '@/stores/ModalStore';
import useUndoStore from '@/stores/UndoStore';

import strings from '@/resources/strings/en-us';
import { GridOptions, contentViewMenuItemConstants as itemConstants, Modals } from '@/util/resources';

import AccessibilityList from '@/components/gui/layout/AccessibilityList';
import { Icon } from '@/components/gui/shared/Icon';
import { EntityStatuses } from '@/components/Table/strings';

import ContentViewMenuItem from './ContentViewMenuItem.react';
import ErrorsList from './ErrorsList.react';
import SimpleTopMenuItem from './SimpleTopMenuItem.react';

export const _designerItemTypes = [
	{
		type: itemConstants.DESIGN_VIEW,
		label: strings.desktop_view('view'),
		icon: mdiMonitor,
		testId: 'desktop-view',
	},
	{
		type: itemConstants.MOBILE_VIEW,
		label: strings.mobile_view('view'),
		icon: mdiCellphone,
		testId: 'mobile-view',
	},
];

class ContentViewMenu extends React.PureComponent {
	render() {
		const itemArray = _designerItemTypes;

		let menuItems = itemArray.map((item, i) => {
			let toggled = this.props.currentView === item.type;

			return <ContentViewMenuItem toggled={toggled} itemData={item} icon={item.icon} key={i} testId={item.testId} />;
		});
		const { hasErrors } = useContentStore.getState().validity;

		return (
			<Box
				bgColor="chakra-subtle-bg"
				borderBottom="1px solid"
				borderColor="chakra-border-color"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				h={'12'}
				px={5}
				position="relative"
			>
				<Box display="flex">
					{!this.props.isPublishedEntity && (
						<SimpleTopMenuItem
							className="template-library-item"
							href="/template-library"
							label={strings.template_market}
							type="three"
							size="sm"
						/>
					)}

					<Button size="sm" variant="ghost" onClick={this.onClickClear} data-testid="clear-design-button">
						Clear design
					</Button>
				</Box>
				<HStack divider={<StackDivider height={8} alignSelf="center" />}>
					{this.props.rows ? (
						<Box display="flex" alignItems="center" mx="auto">
							{menuItems}
						</Box>
					) : null}
					<Flex gap={2}>
						<Tooltip label="Undo">
							<IconButton
								size="sm"
								aria-label="Undo"
								variant="ghost"
								isDisabled={this.props.index === 0}
								onClick={this.onClickUndo}
								icon={<Icon path={mdiUndo} />}
								data-testid="content-view-menu-undo"
							/>
						</Tooltip>

						<Tooltip label="Redo">
							<IconButton
								size="sm"
								aria-label="Redo"
								variant="ghost"
								isDisabled={!this.props.list.length || this.props.index === this.props.list.length - 1}
								onClick={this.onClickRedo}
								children="redo"
								icon={<Icon path={mdiRedo} />}
								data-testid="content-view-menu-redo"
							/>
						</Tooltip>
					</Flex>
					<AccessibilityList />
					{hasErrors && <ErrorsList />}
				</HStack>
			</Box>
		);
	}

	toggleGrid = (data) => {
		let grid = data.value ? GridOptions.ENABLED : GridOptions.DISABLED;
		useContentStore.getState().saveContentHelper({ grid });
	};

	onClickClear = (e) => {
		e.preventDefault();

		useModalStore.getState().showGeneralModal({
			modal: Modals.WARNING,
			type: 'checkPreview',
			message: useContentStore.getState().content.logics.length > 0 ? ModalMessages.CLEAR_DESIGN_WITH_LOGIC : ModalMessages.CLEAR_DESIGN,
			onOk: () => {
				useContentStore.getState().clearContent();
				useCommentStore.getState().deleteAllComments();
				changeView(itemConstants.DESIGN_VIEW);
			},
			okLabel: 'Clear',
			cancelLabel: 'Cancel',
		});
	};

	onClickUndo = (e) => {
		e.preventDefault();
		useUndoStore.getState().undoContentStoreState();
	};

	onClickRedo = (e) => {
		e.preventDefault();
		useUndoStore.getState().redoContentStoreState();
	};
}

const ContentViewMenuWrapper = (props) => {
	const currentView = useContentViewStore((state) => state.currentView);
	const { grid, rows } = useContentStore((state) => {
		return {
			grid: state.helper.grid,
			rows: state.content.rows.length,
		};
	}, shallow);
	const { contentStateList, index } = useUndoStore();
	const isPublishedEntity = useEntitiesStore((state) => state.activeEntity?.Entity.Status === EntityStatuses.active);

	return (
		<ContentViewMenu
			currentView={currentView}
			grid={grid}
			rows={rows}
			list={contentStateList}
			index={index}
			isPublishedEntity={isPublishedEntity}
			{...props}
		/>
	);
};

export default ContentViewMenuWrapper;
