import React from 'react';

import Button from './Button.react';

const SummaryPage = ({ content }) => {
	if (!content.summaryPage?.active) return null;
	const {
		summaryPage: { title, buttonStyles },
		lastPage,
	} = content;

	const groupedFields = Array.from({ length: content.rows.length }, () => []);
	content.rows.map((row) => {
		row.slots.map((slot) => {
			slot.components.map((component) => {
				if (['submit_button', 'recaptcha', 'gdpr', 'fieldPlaceholder'].includes(component.type)) return;
				groupedFields[row.pageIndex].push(component);
			});
		});
	});

	return (
		<div
			className="form-page summary-page"
			style={{
				display: 'none',
				opacity: 0,
				flexDirection: 'column',
			}}
			id="summary-page"
			data-page-index={lastPage + 1}
		>
			<div className="inner-row-div">
				<div className="title">{title}</div>

				{groupedFields.map((group, index) => {
					const sectionTitleId = `page-section-title-${index}`;
					const sectionId = `page-${index}`;
					return (
						<div className="page-wrapper" key={index}>
							<div className="page-marker summary-page-collapse-button">
								<button type="button" aria-expanded={true} aria-controls={sectionId} id={sectionTitleId}>
									Page {index + 1}
									<i className="collapse-icon mdi mdi-chevron-up" aria-hidden={true} />
								</button>
								<button
									type="button"
									className="summary-page-edit-page"
									data-target-page={index}
									aria-label={`Go back to Page ${index + 1}`}
								>
									<i className="mdi mdi-pencil-outline" />
								</button>
							</div>
							<div className="collapse" key={index} id={sectionId} aria-labelledby={sectionTitleId} role="region" tabIndex={0}>
								<div className="fields-wrapper">
									{group.map(({ id, label, type, required }) => (
										<SummaryField key={id} id={id} label={label} type={type} required={required} />
									))}
								</div>
							</div>
						</div>
					);
				})}

				<Button id="summary-page-submit" successAction={content.successAction} {...buttonStyles} />
			</div>
		</div>
	);
};

const SummaryField = ({ id, label, type, required }) => {
	const formattedId = `${id.toString().replace(/-/g, '')}`;
	return (
		<div className="field-wrapper">
			<div className="field-label">
				{label}
				{required && <span className="asterisk"> *</span>}
			</div>
			<div className="field-text summary-page-field-value" data-id={formattedId} data-type={type}>
				-
			</div>
		</div>
	);
};

export const generateSummaryPageClass = (summaryPage) => {
	const {
		fieldStyles: {
			labelFont,
			labelFontColour,
			labelFontSize,
			labelFontWeight,
			labelFontStyle,
			labelTextDecoration,
			labelPosition,
			labelWidth,
			asteriskColor,

			fieldFontColour,
			fieldFontSize,
			fieldFontWeight,
			fieldFontStyle,
			fieldTextDecoration,
			fieldFont,
		},
	} = summaryPage;

	const labelDimension = labelPosition === 'row' ? `width: ${labelWidth}px;` : ``;

	const labelStyles = `
    color: ${labelFontColour};
		font-size: ${labelFontSize}px;
		font-weight: ${labelFontWeight};
		font-style: ${labelFontStyle};
		text-decoration: ${labelTextDecoration};
    font-family: ${labelFont};
		${labelDimension};
  `;

	const fieldStyles = `
		color: ${fieldFontColour};
		font-size: ${fieldFontSize}px;
		font-weight: ${fieldFontWeight};
		font-style: ${fieldFontStyle};
		text-decoration: ${fieldTextDecoration};
		font-family: ${fieldFont};
  `;

	return `
    .summary-page .fields-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

		.summary-page .page-wrapper .fields-wrapper {
			padding: 12px 0 20px;
		}

		.summary-page .collapse {
			transition-duration: 200ms;
			transition-property: height, opacity;
		}

		.summary-page .collapsed .collapse {
			height: 0 !important;
			opacity: 0;
			overflow: hidden;
		}

		.summary-page .collapsed .collapse-icon {
			transform: rotate(180deg);
		}

    .summary-page .field-wrapper {
      display: flex;
      flex-direction: ${labelPosition};
      gap: 8px;
    }

    .summary-page .field-label {
      ${labelStyles}
    }

    .summary-page .field-label .asterisk {
      color: ${asteriskColor};
    }

    .summary-page .field-text {
      ${fieldStyles}
    }
		
		.summary-page .file_upload-file-wrapper {
			display: none;
		}

		.summary-page .file_upload-file-wrapper + .file_upload-file-wrapper {
			margin-top: 8px;
		}

		.summary-page .file_upload-file {
			display: flex;
			align-items: center;
			padding: 16px;
			background-color: rgba(0, 0, 0, 0.03);
			border-radius: 4px;
		}
		
		.summary-page .file_upload-file > img {
			max-width: 18px;
			margin-right: 12px;
		}

		.summary-page .file_upload-file-name {
			margin-right: 8px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.summary-page .file_upload-file-actions {
			display: none;
		}

		.summary-page .file_upload-file-size {
			margin-right: 8px;
			color: rgba(0, 0, 0, 0.55);
			font-size: .875rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.summary-page .file_upload-file-size:before {
			content: '(';
		}

		.summary-page .file_upload-file-size:after {
			content: ')';
		}
  `;
};

export default SummaryPage;
