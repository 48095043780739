import configDev from './config.development';
import configPreProd from './config.preproduction';
import configProd from './config.production';

let config = configDev;

if (import.meta.env.MODE === 'preproduction') {
	config = configPreProd;
} else if (import.meta.env.MODE === 'production') {
	config = configProd;
}

export default config;

export const importedConfig = {
	query: {
		redirectUrl: null,
		secondaryRedirectUrl: null,
		fullMetadataKey: null,
		showTemplate: null,
		editorType: null,
		entityId: null,
		hasBlueprints: null,
		// isActive: null,
	},
};

export const setupRegionBasedConfig = ({ regionCode }) => {
	let recurse = (data) => {
		Object.keys(data).forEach((key) => {
			if (typeof data[key] === 'string') {
				const replaceKey = 'regionCode';

				data[key] = data[key].replace(`{${replaceKey}}`, regionCode);
			} else if (data[key] && typeof data[key] === 'object') {
				recurse(data[key]);
			}
		});
	};

	recurse(config);
};
