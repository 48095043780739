import React from 'react';

import { Box, Button, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { mdiAlertCircle, mdiArrowDown, mdiArrowUp, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import { Flipper } from 'react-flip-toolkit';
import { useLocation } from 'react-router-dom';

import useContentStore from '@/stores/ContentStore';

import { pathPrefix } from '@/util/helper';
import { PagePlaceholderProperties, contentViewMenuItemConstants as ViewTypes } from '@/util/resources';

import SummaryPage from '@/components/gui/content/elements/SummaryPage';
import AddStructuresBox from '@/components/gui/content/helper/AddStructuresBox.react';
import { Icon } from '@/components/gui/shared/Icon';

const EmptyContent = React.memo((props) => {
	if (!props.editingState.rows || props.editingState.rows.length === 0 || (props.editingState.rows.length === 1 && props.isDraggingRow)) {
		return (
			<Box className="empty-content-screen" h="calc(100vh - 172px)" w="100%" display="flex" alignItems="center" justifyContent="center">
				<AddStructuresBox
					addStructure={props.addStructure}
					editingState={props.editingState}
					helper={props.helper}
					isDragging={props.isDragging}
					isDraggingRow={props.isDraggingRow}
					isMobileView={props.isMobileView}
					isOver={props.isOver}
					currentView={props.currentView}
					first={true}
					customRows={props.customRows}
				/>
			</Box>
		);
	} else {
		return null;
	}
});

export const DesignerEditContainer = React.memo((props) => {
	const { connectDropTarget } = props;

	const borderRadius = props.editingState.structureBorderRadius;

	const mobileStyles = props.isMobileView
		? {
				p: 0,
				w: '450px',
				mt: 10,
				boxShadow: 'xl',
				bgColor: props.editingState.bg_color,
				h: 'fit-content',
				flex: 'unset',
			}
		: {};

	// const marginTop = (1 - props.scale) * 10 * 50;

	let pages = [];

	for (const key in props.rows) {
		const page = props.rows[key].map((item) => item.component);
		pages = [
			...pages,
			<Page
				key={`page-${key}`}
				pageIndex={parseInt(key, 10)}
				rows={props.rows}
				isMobileView={props.isMobileView}
				lastPage={props.lastPage}
				lastPageAll={props.lastPageAll}
				errors={props.errors[parseInt(key, 10)]}
				borderColor={props.editingState.borderColor}
				borderWidth={props.editingState.borderWidth}
				structureWidth={props.editingState.structureWidth}
				backgroundImage={props.backgroundImage}
				backgroundRepeat={props.backgroundRepeat}
				backgroundSize={props.backgroundSize}
				bg_color={props.bg_color}
			>
				{page}
			</Page>,
		];
	}

	let backgroundImage =
		(props.isMobileView &&
			props.editingState.background_image && {
				bgRepeat: props.editingState.background_repeat === 'full' ? 'no-repeat' : props.editingState.background_repeat,
				bgImage: `url('${props.editingState.background_image}')`,
				bgSize: props.editingState.background_repeat === 'full' ? '100%' : '',
			}) ||
		{};

	return (
		<Box
			position="relative"
			py={12}
			px={0}
			zIndex={1}
			display="flex"
			flex={1}
			alignSelf="baseline"
			mx="auto"
			transform={props.hasRows ? `scale(${props.scale})` : ''}
			{...mobileStyles}
			style={{
				...props.style,
			}}
			className="content-container"
			{...backgroundImage}
		>
			{connectDropTarget(
				<div
					style={{
						width: '100%',
					}}
					className="edit-container"
				>
					<Box display="flex" flexDirection="column" flex={1} zIndex={1} borderRadius={borderRadius}>
						<Flipper spring="noWobble" flipKey={Math.random()}>
							{pages}
						</Flipper>
						{props.currentView !== ViewTypes.MOBILE_VIEW && <EmptyContent {...props} />}
						<SummaryPage {...props} />
						<AddSummaryPage {...props} />
					</Box>
				</div>,
			)}
		</Box>
	);
});

const AddSummaryPage = (props) => {
	if (
		props.summaryPage.active ||
		!props.editingState.rows ||
		props.editingState.rows.length === 0 ||
		(props.editingState.rows.length === 1 && props.isDraggingRow)
	)
		return null;

	return (
		<Box w="full" pb={10} px={8}>
			<Box display="flex" justifyContent="center" p={3} border="2px dashed" borderColor="chakra-border-color">
				<Button
					size="sm"
					variant="ghost"
					colorScheme="primary"
					onClick={() => useContentStore.getState().toggleSummaryPage()}
					leftIcon={<Icon path={mdiPlus} />}
				>
					Add summary page
				</Button>
			</Box>
		</Box>
	);
};

const Page = React.memo((props) => {
	const location = useLocation();
	const { pageIndex, rows, isMobileView, errors, lastPageAll } = props;
	const hasMaxPages = lastPageAll + 1 === 10;
	const showBorder = PagePlaceholderProperties.type !== rows[pageIndex][0].row.type;

	const getBackgroundImage = () => {
		if (props.isMobileView) {
			return {};
		}

		return (
			(props.backgroundImage && {
				bgRepeat: props.backgroundRepeat === 'full' ? 'no-repeat' : props.backgroundRepeat,
				bgImage: `url('${props.backgroundImage}')`,
				bgSize: props.backgroundSize,
			}) ||
			{}
		);
	};

	const getBackgroundColor = () => {
		if (props.isMobileView) {
			return '';
		}
		return props.bg_color;
	};

	return (
		<Box id={`content-page-${pageIndex}`} position="relative" className="form-page">
			<Box data-testid="content-page-wrapper" bgColor={getBackgroundColor()} {...getBackgroundImage()}>
				{!isMobileView && location.pathname !== pathPrefix() + '/comment-mode' && Object.keys(rows).length !== 1 ? (
					<Box position="absolute" display="flex" left={5} top={-10} alignItems="center" gap="2">
						{pageIndex !== 0 && (
							<Tooltip label="Move up">
								<IconButton
									variant="ghost"
									size="sm"
									aria-label="Move up"
									onClick={() => useContentStore.getState().movePage(pageIndex, pageIndex - 1)}
									data-testid="move-page-up"
									icon={<Icon path={mdiArrowUp} />}
								/>
							</Tooltip>
						)}

						{pageIndex !== Object.keys(rows).length - 1 && (
							<Tooltip label="Move down">
								<IconButton
									variant="ghost"
									size="sm"
									aria-label="Move down"
									onClick={() => useContentStore.getState().movePage(pageIndex, pageIndex + 1)}
									data-testid="move-page-down"
									icon={<Icon path={mdiArrowDown} />}
								/>
							</Tooltip>
						)}

						<Tooltip label="Delete page">
							<IconButton
								variant="ghostColorOnHover"
								size="sm"
								aria-label="Delete page"
								colorScheme="danger"
								onClick={() => useContentStore.getState().removePage(pageIndex)}
								data-testid="delete-page"
								icon={<Icon path={mdiTrashCanOutline} />}
							/>
						</Tooltip>

						<Text variant="subtle" ml={2.5}>
							Page {pageIndex + 1}/{lastPageAll + 1}
						</Text>
					</Box>
				) : null}

				{errors && errors.length ? (
					<Box position="absolute" display="flex" right={5} top={-10} alignItems="center">
						<Tooltip placement="left" label={errors.map((item, i) => (i > errors.length ? item.title : `\n${item.title}`)).join('')}>
							<IconButton size="sm" variant="ghost" colorScheme="danger" aria-label="Errors" icon={<Icon path={mdiAlertCircle} />} />
						</Tooltip>
					</Box>
				) : null}
				<Box
					m={'0 auto'}
					maxW={props.structureWidth}
					w="100%"
					borderStyle="solid"
					borderColor={props.borderColor}
					borderWidth={showBorder ? props.borderWidth : 0}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					data-testid={'editor-content-wrapper'}
				>
					{props.children}
				</Box>
			</Box>
			{!isMobileView && location.pathname !== pathPrefix() + '/comment-mode' ? (
				<Box py={10} w="100%" display="flex" alignItems="center">
					<Box flex={1} h="1px" bgColor="chakra-border-color" />

					<Button
						size="sm"
						variant="ghost"
						colorScheme="primary"
						title={hasMaxPages ? 'You have reached page limit (Max 10).' : 'Add page'}
						isDisabled={hasMaxPages}
						data-testid="clone-page"
						onClick={() => !hasMaxPages && useContentStore.getState().duplicatePage(pageIndex)}
						leftIcon={<Icon path={mdiPlus} />}
					>
						Add page
					</Button>

					<Box flex={1} h="1px" bgColor="chakra-border-color" />
				</Box>
			) : (
				<Box w="100%" h="12" />
			)}
		</Box>
	);
});
