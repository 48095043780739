import config from 'config/config';
import { uniq } from 'lodash';
import { getEntityId } from 'shared/src/utils/shared.js';

import { claimsKeys, getRegionCode, getUser } from '@/util/auth/auth';
import { checkBoolean, generateUrlWithPathParams, getEditorType, getMetadataKey, makeApiGatewayCall } from '@/util/helper';
import { ApiAuthorisationModes, emptyDefaultJson, MetadataCategories } from '@/util/resources';

import { buildMetadataServicePayload } from './metadataService';

export const getUserDetails = () => {
	const userData = getUser();
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_USER_DETAILS,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetLatestMetadataRequest, {
		key: `tenant_${userData[claimsKeys.TENANT_ID]}`,
	});

	return makeApiGatewayCall(url, 'get', {}, {}, data).catch((error) => {
		if (error.response && error.response.status === 404) {
			//indicating there is no such key in metadata service
			//$FlowIgnore here becuse of stupid boolean return here
			return false;
		} else {
			throw error;
		}
	});
};

export const deleteUserDetails = () => {
	const userData = getUser();

	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_USER_DETAILS,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.DeleteMetadataRequest, {
		key: `tenant_${userData[claimsKeys.TENANT_ID]}`,
	});

	return makeApiGatewayCall(url, 'delete', {}, {}, data);
};

export const deleteFavouriteTemplate = (templateID) => {
	const userData = getUser();

	const editorType = getEditorType();

	let data = buildMetadataServicePayload(
		`tenant_${userData[claimsKeys.TENANT_ID]}`,
		MetadataCategories[editorType].EDITOR_USER_DETAILS,
		{
			ContentType: 'application/json',
			Data: '{}',
		},
		{
			favouriteTemplates: [templateID],
		},
	);

	let createMetadataUrl = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	return getUserDetails().then((response) => {
		if (response === false) {
			throw new Error('metadata does not exist');
		} else {
			let favouriteTemplatesObj = response.data.Attributes.find((attr) => attr.Key === 'favouriteTemplates');

			let favouriteTemplates = favouriteTemplatesObj && JSON.parse(favouriteTemplatesObj.Value.toString());

			let templateIndex = (favouriteTemplates && favouriteTemplates.indexOf(templateID)) || -1;

			if (templateIndex > -1 && favouriteTemplates) {
				favouriteTemplates.splice(templateIndex, 1);
			}

			let favouriteTemplateAttr = data.Attributes.find((attr) => attr.Key === 'favouriteTemplates');
			if (favouriteTemplateAttr) {
				favouriteTemplateAttr.Value = JSON.stringify(uniq(favouriteTemplates));
			}

			let savedRecepientsObj = response.data.Attributes.find((attr) => attr.Key === 'savedRecipients');

			const newData = data;
			newData.Attributes = [favouriteTemplateAttr];

			if (savedRecepientsObj) {
				newData.Attributes = [favouriteTemplateAttr, savedRecepientsObj];
			}

			return makeApiGatewayCall(createMetadataUrl, 'post', data);
		}
	});
};

export const postFavouriteTemplate = (templateID) => {
	const editorType = getEditorType();
	const userData = getUser();

	let data = buildMetadataServicePayload(
		`tenant_${userData[claimsKeys.TENANT_ID]}`,
		MetadataCategories[editorType].EDITOR_USER_DETAILS,
		{
			ContentType: 'application/json',
			Data: '{}',
		},
		{
			favouriteTemplates: [templateID],
		},
	);

	let createMetadataUrl = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	return getUserDetails().then((response) => {
		if (response === false) {
			return makeApiGatewayCall(createMetadataUrl, 'post', data);
		}

		const newData = data;

		// if(response === false){ //if not false then manipulate content
		//     return axios.post(createMetadataUrl, data, conf);
		// }

		let favouriteTemplatesObj = response.data.Attributes.find((attr) => attr.Key === 'favouriteTemplates');

		let savedRecepientsObj = response.data.Attributes.find((attr) => attr.Key === 'savedRecipients');

		let favouriteTemplates = favouriteTemplatesObj && JSON.parse(favouriteTemplatesObj.Value.toString());

		let favouriteTemplateAttr = data.Attributes.find((attr) => attr.Key === 'favouriteTemplates');

		if (favouriteTemplateAttr && favouriteTemplates) {
			favouriteTemplateAttr.Value = JSON.stringify(uniq([...favouriteTemplates, templateID]));
		}

		newData.Attributes = [favouriteTemplateAttr];

		if (savedRecepientsObj) {
			newData.Attributes = [favouriteTemplateAttr, savedRecepientsObj];
		}

		return makeApiGatewayCall(createMetadataUrl, 'post', newData);

		// if (savedRecepientsObj){
		//     const newData = data;
		//     newData.Attributes = [favouriteTemplateAttr, savedRecepientsObj];
		//     return axios.post(createMetadataUrl, newData, conf);
		// }
	});
};

//urls are temporary for dev environment
export const getTemplates = (authorisationMode = null) => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_TEMPLATES,
			LatestOnly: true,
			authorisationMode,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetAllMetadataForUserRequest);

	return makeApiGatewayCall(url, 'get', {}, {}, data).catch((error) => {
		if (error.response && error.response.data.status === 404) {
			//indicating there is no such key in metadata service
			//$FlowIgnore here becuse of stupid boolean return here
			return false;
		} else {
			throw error;
		}
	});
};

export const getTemplate = (templateMetadata) => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_TEMPLATES,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetLatestMetadataRequest, {
		key: templateMetadata.id,
		metadataVersionId: templateMetadata.MetadataVersionId,
	});

	return makeApiGatewayCall(url, 'get', {}, {}, data);
};

export const getPreviewHtml = (html) => {
	const url = config.publisher.baseUrl(getRegionCode()) + config.publisher.preview(getEntityId(config));

	const data = {
		JsonContent: html,
	};

	return makeApiGatewayCall(url, 'post', data);
};

export const deleteTemplate = (key) => {
	const editorType = getEditorType();

	let data = {
		data: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_TEMPLATES,
			Key: key,
		},
	};

	let url = config.metadata_service.url + config.metadata_service.paths.DeleteMetadataRequest;

	return makeApiGatewayCall(url, 'delete', {}, {}, data);
};

export const postTemplate = (template, AuthorisationMode = ApiAuthorisationModes.Private) => {
	const editorType = getEditorType();

	let data = buildMetadataServicePayload(
		template.id,
		MetadataCategories[editorType].EDITOR_SAVED_TEMPLATES,
		{
			ContentType: 'application/json',
			Data: template,
		},
		{
			createdBy: template.createdBy,
			tenants: template.tenants,
			id: template.id,
			dateSaved: template.dateSaved,
			title: template.title,
			imageSrc: template.imageSrc || '',
			imageMobileSrc: template.imageMobileSrc || '',
			generatedImage: template.generatedImage || '',
			categories: template.categories,
		},
		AuthorisationMode,
	);

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	return makeApiGatewayCall(url, 'post', data);
};

export const transferTemplate = (template) => {
	const editorType = getEditorType();

	let data = buildMetadataServicePayload(
		template.id,
		MetadataCategories[editorType].EDITOR_SAVED_TEMPLATES,
		{
			ContentType: 'application/json',
			Data: template,
		},
		{
			id: template.id,
			dateSaved: template.dateSaved,
			title: template.title,
			imageSrc: template.imageSrc || '',
			imageMobileSrc: template.imageMobileSrc || '',
			generatedImage: template.generatedImage || '',
			categories: template.categories,
		},
		ApiAuthorisationModes.Organization,
	);

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	return makeApiGatewayCall(url, 'post', data, { 'X-Extra-Auth': config.publicMetadataKey });
};

export const transferTemplateViaEmail = (data) => {
	console.log(data);
	return true;
};

export const editTemplate = (templateMetadata, changedAttributes = [], newJson = null) => {
	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	const mergeAttributes = (oldAttrs, newAttrs) => {
		let newKeys = newAttrs.map((newattr) => {
			return newattr.Key;
		});

		return oldAttrs.map((attr) => {
			let tempAttr = { ...attr };
			if (newKeys.includes(attr.Key)) {
				let findAttr = newAttrs.find((newAttr) => newAttr.Key === attr.Key);
				tempAttr.Value = (findAttr && findAttr.Value) || '';
			}

			return tempAttr;
		});
	};

	return getTemplate(templateMetadata).then((response) => {
		let newData = response.data;
		newData.Attributes = mergeAttributes(newData.Attributes, changedAttributes);

		if (newJson) {
			newData.Content.Data = JSON.stringify(newJson);
			newData.Content.ContentType = 'application/json';
		}

		return makeApiGatewayCall(url, 'post', newData);
	});
};

export const postDraft = (draft) => {
	return postDraftCall(draft, false);
};

export const postAutosaveDraft = (draft) => {
	return postDraftCall(draft, true);
};

export const getDrafts = (page = 1) => {
	return getDraftsCall(page, false);
};

export const getAutosaveDrafts = (page = 1) => {
	return getDraftsCall(page, true);
};

export const getDraft = (draftMetadata) => {
	return getDraftCall(draftMetadata, false);
};

export const getAutosaveDraft = (draftMetadata) => {
	return getDraftCall(draftMetadata, true);
};

export const resetDrafts = (key) => {
	const metadataCategory = getDraftCategory();

	let data = {
		params: {
			Category: metadataCategory.EDITOR_SAVED_DRAFTS,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.DeleteMetadataRequest, {
		key: key,
	});

	return makeApiGatewayCall(url, 'delete', {}, {}, data).catch((error) => {
		if (
			error.response &&
			error.response.status === 404 &&
			error.response.data &&
			error.response.data.Service &&
			error.response.data.Code === -4041
		) {
			//indicating there is no such key in metadata service
			return false;
		} else {
			throw error;
		}
	});
};

export const getTemplatesFromRsp = (templateRsp) => {
	//transform attributes from template response into a proper key-value object
	let templates = templateRsp.data.Data.map((data) => {
		try {
			return data.Attributes.concat([
				{ Key: 'MetadataVersionId', Value: data.MetadataVersionId },
				{ Key: 'AuthorisationMode', Value: data.AuthorisationMode },
			]);
		} catch (e) {
			console.error(e);
			return [];
		}
	})
		.filter((attrsArray) => {
			return attrsArray.length > 0;
		})
		.map((attrsArray) => {
			return attrsArray
				.map((attr) => {
					let value;

					//parse categories from json string
					if (attr.Key === 'categories' || attr.Key === 'tenants' || attr.Key === 'createdBy') {
						try {
							value = JSON.parse(attr.Value);
						} catch {
							value = [];
						}
					} else {
						value = attr.Value;
					}

					return { [attr.Key]: value };
				})
				.reduce((acc, array) => ({ ...acc, ...array }));
		});

	//sort by date
	return sortTemplates(templates);
};

export const getTemplateFromRsp = (templateRsp) => {
	try {
		return JSON.parse(templateRsp.data.Content.Data);
	} catch (e) {
		console.error(e);
		throw new Error('Invalid JSON');
		//clearDrafts(); //clear drafts if json is corrupted
	}
};

export const getFavouriteTemplatesFromRsp = (userDetailsRsp) => {
	try {
		const favoriteAttr = userDetailsRsp.data.Attributes.find((attr) => attr.Key === 'favouriteTemplates');
		const favorites = favoriteAttr ? favoriteAttr.Value : '[]';
		return JSON.parse(favorites);
	} catch (e) {
		console.error(e);
		throw new Error('Invalid JSON');
		//clearDrafts(); //clear drafts if json is corrupted
	}
};

export const getTemplateCategoriesFromTemplatesMetadata = (templatesMetadata) => {
	let categories = templatesMetadata
		.map((metadata) => {
			return metadata.categories;
		})
		.reduce((acc, item) => {
			return acc.concat(item);
		}, []);

	return uniq(categories);
};

export const getDraftsFromRsp = (draftsRsp) => {
	if (!draftsRsp || !draftsRsp.data) {
		return [];
	}
	try {
		return draftsRsp.data.Data.map((data) => {
			if (!Array.isArray(data.Attributes)) {
				throw new Error('Attributes should be an array of objects');
			}

			return data.Attributes.concat([{ Key: 'MetadataVersionId', Value: data.MetadataVersionId }]);
		}).map((attrsArray) => {
			return attrsArray
				.map((attr) => {
					if (attr.Key !== undefined && attr.Value !== undefined) {
						return { [attr.Key]: attr.Value };
					}
				})
				.reduce((acc, array) => ({ ...acc, ...array }));
		});
	} catch (e) {
		console.error(e);
		return [];
	}
};

export const getDraftFromRsp = (DraftRsp) => {
	try {
		return JSON.parse(DraftRsp.data.Content.Data.replace(/href=\\" https?:\/\//g, 'href=\\"'));
	} catch (e) {
		console.error(e);
		return { json: emptyDefaultJson[getEditorType()] };
	}
};

//extract merge drafts and autosaved drafts
export const extractAndMergeAllDraftsFromResponses = (draftResponses) => {
	let drafts = draftResponses
		.map((response) => {
			let drafts = [];

			if (response) {
				drafts = getDraftsFromRsp(response);
				return drafts;
			} else {
				return [];
			}
		})
		.reduce((acc, current) => {
			return acc.concat(current);
		});

	return sortDrafts(drafts);
};

export const sortDrafts = (drafts) => {
	return (
		drafts &&
		drafts.sort((draftA, draftB) => {
			return parseInt(draftB.dateSaved) - parseInt(draftA.dateSaved);
		})
	);
};

export const sortTemplates = (templates) => {
	return (
		templates &&
		templates.sort((templateA, templateB) => {
			return parseInt(templateB.dateSaved) - parseInt(templateA.dateSaved);
		})
	);
};

//api function calls

const getDraftCall = (draftMetadata, isAutosave) => {
	const metadataCategory = getDraftCategory();
	const category = checkBoolean(isAutosave) ? metadataCategory.EDITOR_SAVED_DRAFTS_AUTOSAVE : metadataCategory.EDITOR_SAVED_DRAFTS;

	let data = {
		params: {
			Category: category,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetMetadataRequest, {
		key: getMetadataKey() || getEntityId(config) || 'template-edit-only',
		metadataVersionId: draftMetadata.MetadataVersionId || 'latest',
	});

	return makeApiGatewayCall(url, 'get', {}, {}, data);
};

const postDraftCall = (template, isAutosave) => {
	const metadataCategory = getDraftCategory();
	const category = checkBoolean(isAutosave) ? metadataCategory.EDITOR_SAVED_DRAFTS_AUTOSAVE : metadataCategory.EDITOR_SAVED_DRAFTS;

	const fromTemplateId = template.fromTemplateId
		? {
				fromTemplateId: template.fromTemplateId,
			}
		: {};

	let attributes = {
		id: template.id,
		dateSaved: template.dateSaved,
		title: template.title,
		fromUser: template.fromUser,
		autosave: checkBoolean(isAutosave).toString(),
		...fromTemplateId,
	};

	if (template.generatedImage) {
		attributes = {
			...attributes,
			generatedImage: template.generatedImage,
		};
	}

	const data = buildMetadataServicePayload(
		getMetadataKey() || getEntityId(config) || 'template-edit-only',
		category,
		{
			ContentType: 'application/json',
			Data: template,
		},
		attributes,
		ApiAuthorisationModes.Private,
	);

	let createMetadataUrl = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	let createMetadataVersionUrl = generateUrlWithPathParams(
		config.metadata_service.url,
		config.metadata_service.paths.CreateMetadataVersionRequest,
		{
			key: data.Key,
		},
	);

	let getDraftsFunc = checkBoolean(isAutosave) ? getAutosaveDrafts : getDrafts;

	return getDraftsFunc().then((response) => {
		if (response !== false) {
			//if not false then manipulate content
			return makeApiGatewayCall(createMetadataVersionUrl, 'post', data);
		}
		return makeApiGatewayCall(createMetadataUrl, 'post', data);
	});
};

const getDraftsCall = (page, isAutosave) => {
	const metadataCategory = getDraftCategory();
	const category = checkBoolean(isAutosave) ? metadataCategory.EDITOR_SAVED_DRAFTS_AUTOSAVE : metadataCategory.EDITOR_SAVED_DRAFTS;

	let data = {
		params: {
			Category: category,
		},
	};

	let url = generateUrlWithPathParams(
		config.metadata_service.url,
		isAutosave ? config.metadata_service.paths.GetLatestMetadataRequest : config.metadata_service.paths.GetAllMetadataVersionsRequest,
		{
			key: getMetadataKey() || getEntityId(config) || 'template-edit-only',
			pagesize: checkBoolean(isAutosave) ? config.autosave_count : config.drafts.drafts_pagesize,
			page,
		},
	);

	return makeApiGatewayCall(url, 'get', {}, {}, data).catch((error) => {
		if (error.response && error.response.data.status === 404) {
			//indicating there is no such key in metadata service
			//$FlowIgnore here becuse of stupid boolean return here
			return false;
		} else {
			throw error;
		}
	});
};

// export const getInts = () => {

//     let data = {
//         headers: getHeaders(),
//         params: {
//             Category: 'integrations'
//         }
//     };

//     return axios.get('https://gateway.services.moostaging.com/metadata', data)
//         .catch((error) => {
//             if(error.response && error.response.status === 404 && error.response.data && error.response.data.Service && error.response.data.Code === -4041){

//indicating there is no such key in metadata service
//$FlowIgnore here becuse of stupid boolean return here
//                 return false;
//             }else{
//                 throw error;
//             }
//         });
// }
// export const postInt = () => {

//     let data = buildMetadataServicePayload(
//         'quickemailverification',
//         'integrations',
//         {
//             ContentType: 'String',
//             Data: ''
//         },
//         {
//             RelativeRedirect: 'http://docs.quickemailverification.com/integrations/integrating-with-moosend',
//             Image: 'https://cdn.designer-images.net/20181212-2018-1212-2018-121220181212/7f8436c970b44a4594eb05767ae77914QuickEmailVerification.png',
//             Description: 'Created by Quick Email Verification, allows users to clean their mailing lists and thus send emails to real prospects and reduce bounces.',
//             Name: 'Quick Email Verification',
//             NeedsMailinglist: 'false',
//             Active: 'true',
//             Order: 11
//         },
//         ApiAuthorisationModes.Organization
//     );

//     let conf = {
//         headers: getHeaders()
//     };

//     let createMetadataUrl = generateUrlWithPathParams(
//         config.metadata_service.url,
//         config.metadata_service.paths.CreateMetadataRequest
//     );

//     return axios.post(createMetadataUrl, data, conf);

// };

export const getDraftCategory = () => {
	return MetadataCategories[getEditorType()];
};

export const createHtmlImage = (content, width) => {
	let data = {
		ContentWidth: width,
		JsonContent: JSON.stringify(content),
		// ExistingHtml: THE OLD HTML,
		BlueprintType: 'html',
		EntityId: getEntityId(config),
	};

	let url = config.entities_service.base + config.entities_service.jsonToImage;

	return makeApiGatewayCall(url, 'post', data);
};

export const saveComment = (key, comments, AuthorisationMode = ApiAuthorisationModes.Private) => {
	let data = buildMetadataServicePayload(
		key,
		'EDITOR_COMMENTS',
		{
			ContentType: 'application/json',
			Data: { comments },
		},
		[],
		AuthorisationMode,
	);

	let conf = {
		params: {
			Category: 'EDITOR_COMMENTS',
		},
	};

	const url = config.metadata_service.url + config.metadata_service.paths.CreateMetadataRequest;

	return makeApiGatewayCall(url, 'post', data, undefined, conf);
};

export const updateComment = (key, comments, AuthorisationMode = ApiAuthorisationModes.Private) => {
	let data = buildMetadataServicePayload(
		key,
		'EDITOR_COMMENTS',
		{
			ContentType: 'application/json',
			Data: { comments },
		},
		[],
		AuthorisationMode,
	);

	let conf = {
		params: {
			Category: 'EDITOR_COMMENTS',
		},
	};

	let url =
		generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataVersionRequest, {
			key: key,
		}) + '?Replace=true';

	return makeApiGatewayCall(url, 'post', data, undefined, conf);
};

export const getComments = (key) => {
	let conf = {
		params: {
			Category: 'EDITOR_COMMENTS',
			AuthorisationMode: ApiAuthorisationModes.Private,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetLatestMetadataRequest, {
		key: key,
	});

	// const url = config.metadata_service.url + config.metadata_service.paths.GetAllMetadataForUserRequest;

	return makeApiGatewayCall(url, 'get', {}, {}, conf);
};

export const deleteComment = (key) => {
	let conf = {
		params: {
			Category: 'EDITOR_COMMENTS',
		},
	};

	const url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.DeleteMetadataRequest, {
		key,
	});

	return makeApiGatewayCall(url, 'delete', {}, {}, conf);
};

export const notifyMembers = () => {
	// return makeApiGatewayCall(config.moosend.gatewayNew + config.accounts.notifyMembers, 'post', data);
};
