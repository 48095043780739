/*
 * KEEP THIS FILE ISOMORPHIC
 * DO NOT IMPORT ANYTHING THAT IS NOT ISOMORPHIC AND WONT WORK ON THE NODE APP
 */

import { AllComponentTypes, googleFontEmbed } from 'shared/src/utils/shared.js';

export function generateGoogleFontsLink(rows, customFonts, generalFont, summaryPage) {
	const generalGoogleFont = findGoogleFont(generalFont, customFonts);
	const availableGoogleFonts = generalFont && generalGoogleFont ? [generalGoogleFont] : [];

	rows.forEach((row) => {
		if (row.slots && row.slots.length) {
			row.slots.forEach((slot) => {
				if (slot.components && slot.components.length) {
					slot.components.forEach((component) => {
						let font;
						if (
							component.type === AllComponentTypes.checkbox ||
							component.type === AllComponentTypes.checkbox_group ||
							component.type === AllComponentTypes.radio ||
							component.type === AllComponentTypes.email_input ||
							component.type === AllComponentTypes.date_input ||
							component.type === AllComponentTypes.longtext_input ||
							component.type === AllComponentTypes.text_input ||
							component.type === AllComponentTypes.number_input ||
							component.type === AllComponentTypes.phone_input ||
							component.type === AllComponentTypes.multiselect_input ||
							component.type === AllComponentTypes.file_upload ||
							component.type === AllComponentTypes.singleselect_input
						) {
							if (component.fieldFont) {
								if (font !== component.fieldFont) {
									font = component.fieldFont;
									const isGoogleFont = findGoogleFont(font, customFonts);
									if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
								}
							}
							if (component.mobileProps?.fieldFont) {
								if (font !== component.mobileProps.fieldFont) {
									font = component.mobileProps.fieldFont;
									const isGoogleFont = findGoogleFont(font, customFonts);
									if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
								}
							}
							if (component.labelFont) {
								if (font !== component.labelFont) {
									font = component.labelFont;
									const isGoogleFont = findGoogleFont(font, customFonts);
									if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
								}
							}
							if (component.mobileProps?.labelFont) {
								if (font !== component.mobileProps.labelFont) {
									font = component.mobileProps.labelFont;
									const isGoogleFont = findGoogleFont(font, customFonts);
									if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
								}
							}
							if (component.placeholderFont) {
								if (font !== component.placeholderFont) {
									font = component.placeholderFont;
									const isGoogleFont = findGoogleFont(font, customFonts);
									if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
								}
							}
							if (component.mobileProps?.placeholderFont) {
								if (font !== component.mobileProps.placeholderFont) {
									font = component.mobileProps.placeholderFont;
									const isGoogleFont = findGoogleFont(font, customFonts);
									if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
								}
							}
						} else if (component.type === AllComponentTypes.text) {
							if (component.text) {
								const div = window.document.createElement('div');
								div.innerHTML = component.text;
								const children = [...div.getElementsByTagName('*')];
								if (children && children.length) {
									children.forEach((element) => {
										font = element.style.fontFamily;
										const isGoogleFont = findGoogleFont(font, customFonts);
										if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
									});
								}
							}
							if (component.mobileProps?.text) {
								const div = window.document.createElement('div');
								div.innerHTML = component.mobileProps.text;
								const children = [...div.getElementsByTagName('*')];
								if (children && children.length) {
									children.forEach((element) => {
										font = element.style.fontFamily;
										const isGoogleFont = findGoogleFont(font, customFonts);
										if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
									});
								}
							}
						} else if (component.fontFamily) {
							font = component.fontFamily;
							const isGoogleFont = findGoogleFont(font, customFonts);
							if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
						}
					});
				}
			});
		}
	});

	if (summaryPage?.active) {
		[summaryPage.fieldStyles.labelFont, summaryPage.fieldStyles.fieldFont, summaryPage.buttonStyles.fontFamily].map((font) => {
			const isGoogleFont = findGoogleFont(font, customFonts);
			if (isGoogleFont && !availableGoogleFonts.includes(isGoogleFont)) availableGoogleFonts.push(isGoogleFont);
		});
	}

	if (availableGoogleFonts && availableGoogleFonts.length) {
		return googleFontEmbed(availableGoogleFonts.join('|'));
	}
}

function findGoogleFont(font, customFonts) {
	const found =
		customFonts &&
		customFonts.find((item) => {
			if (font) {
				return item.value === font.replace(/"/g, '');
			}
		});
	if (found) {
		return found.embed;
	}
}

export const addGoogleFontsLink = (dom, target, model) => {
	const rows = model && model.rows ? model.rows : [];

	const googleFonts = generateGoogleFontsLink(rows, model.customFonts, model.font_family, model.summaryPage);

	if (googleFonts) {
		const div = window.document.createElement('div');
		div.innerHTML = googleFonts.trim();
		target.append(div.firstChild);
	}

	return dom;
};
