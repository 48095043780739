// import useContentStore, { actionsForUndoRedo } from '@/stores/ContentStore';
import config from 'config/config';
import { create } from 'zustand';

import useContentStore from './ContentStore';
import useLeftSidebarStore from './LeftSidebarStore';

const useUndoStore = create((set, get) => ({
	contentStateList: [],
	index: 0,
	setInitialState: () => {
		const list = [{ content: useContentStore.getState().content }];
		set(() => ({ contentStateList: list, index: list.length - 1 }));
	},
	saveContentStoreState: () => {
		const list = [...get().contentStateList];
		if (get().contentStateList.length < config.undo_steps) {
			list.splice(get().index + 1, list.length - (get().index + 1));
			list.push({ content: useContentStore.getState().content });
		} else {
			list.splice(0, 1);
			list.push({ content: useContentStore.getState().content });
		}
		set(() => ({ contentStateList: list, index: list.length - 1 }));
	},
	undoContentStoreState: () => {
		const index = get().index - 1;
		if (get().index > 0) {
			set(() => ({ index }));
		}
		useLeftSidebarStore.getState().undoContentStoreState();
		useContentStore.getState().undoContentStoreState(get().contentStateList[index]);
		useContentStore.getState().runPostActions();
	},
	redoContentStoreState: () => {
		const index = get().index + 1;
		if (get().index < get().contentStateList.length - 1) {
			set(() => ({ index }));
		}
		useLeftSidebarStore.getState().undoContentStoreState();
		useContentStore.getState().undoContentStoreState(get().contentStateList[index]);
		useContentStore.getState().runPostActions();
	},
}));

export default useUndoStore;

// this.delayedInterval = createDelayedInterval();

// this.on('beforeEach', ({ payload, state }) => {
// 	if (payload && payload.details) {
// 		let { name, namespace } = payload.details;
// 		if (actionsForUndoRedo.includes(name)) {
// 			this.delayedInterval(() => {
// 				this.onSaveContentStoreState(useContentStore.getState());
// 			}, 100);
// 		}
// 	}
// });
