import React from 'react';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { formatTestId } from '@/util/helper';

import { settingsLabelStyle } from './Settings.styles';

const NumberSetting = ({
	allowNegativeValues = false,
	disabled = false,
	min = 0,
	max = 2000,
	label = '',
	customStyle = { customRoot: null, customLabel: null, customInput: null },
	text,
	type,
	ariaLabel,
	onChange,
	onBlur,
	validateOnBlur,
}) => {
	const { customRoot, customLabel, customInput } = customStyle;
	const inputValue = typeof text !== 'undefined' ? parseInt(text, 10) : undefined;

	const handleChange = (event) => {
		if (onChange) {
			let value = parseInt(event.target.value, 10);

			if (isNaN(value)) {
				value = 0;
			} else if (!validateOnBlur) {
				if (!allowNegativeValues && value < min) {
					value = min;
				} else if (max && value > max) {
					value = max;
				}
			}

			onChange({ event, type, value });
		}
	};

	const handleBlur = (event) => {
		if (validateOnBlur) {
			let value = text;

			if (isNaN(value)) {
				value = 0;
			} else if (!allowNegativeValues && value < min) {
				value = min;
			} else if (max && value > max) {
				value = max;
			}

			if (onChange) onChange({ event, type, value });
		}

		if (onBlur) onBlur({ event, type, value: text });
	};

	return (
		<FormControl
			id={`${type}-number-setting`}
			data-type={type}
			className="number-input-setting-container"
			isDisabled={disabled}
			mb={5}
			flex={1}
			{...customRoot}
		>
			<FormLabel {...settingsLabelStyle} {...customLabel}>
				{label}
			</FormLabel>

			<Input
				type="number"
				value={inputValue}
				aria-label={ariaLabel || label}
				data-testid={`input-${formatTestId(label)}`}
				w="100%"
				{...customInput}
				onBlur={handleBlur}
				onChange={handleChange}
			/>
		</FormControl>
	);
};

export default NumberSetting;
