const leadGenMainArticles = [
	{
		label: 'How Can I Create a Landing Page?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403146809618-Create-a-Landing-Page',
	},
	{
		label: 'Can I track conversions in my landing page?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403349114898-Track-conversions-in-your-landing-page',
	},
	{
		label: 'How can I create a subscription form?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403356535954-Create-a-subscription-form',
	},
	{
		label: 'Can I display my Modal Pop-up form when a user exits a page?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403352587282-Display-your-Modal-Pop-up-form-when-a-user-leaves-a-page-',
	},
	{
		label: 'How can I hide the form from visitors who have submitted it already?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403399969042-Hide-the-form-from-visitors-who-have-submitted-it-already',
	},
	{
		label: 'Learn more about growth tools',
		url: 'https://help.moosend.com/hc/en-us/sections/4407514421778-Dashboard',
		withArrow: true,
	},
];

const campaignsMainArticles = [
	{
		label: 'What factors can I test when I perform an A/B split test on my campaign?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403083250578-The-factors-you-can-test-when-you-perform-an-A-B-split-test-on-your-campaign',
	},
	{
		label: 'How do I create a new Email Marketing Campaign?',
		url: 'https://help.moosend.com/hc/en-us/articles/4402716613266-Create-a-new-Email-Marketing-Campaign',
	},
	{
		label: 'Can I schedule a campaign and send it whenever I want?',
		url: 'https://help.moosend.com/hc/en-us/articles/4402813532562-Schedule-a-campaign-and-send-it-whenever-you-want',
	},
	{
		label: 'How can I create a Repeatable HTML Campaign?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403081916178-Create-a-Repeatable-HTML-Campaign-',
	},
	{
		label: 'How can I create an RSS campaign?',
		url: 'https://help.moosend.com/hc/en-us/articles/4402982031506-Create-an-RSS-campaign-',
	},
	{
		label: 'Learn more about campaigns',
		url: 'https://help.moosend.com/hc/en-us/sections/4407514438162-Campaigns',
		withArrow: true,
	},
];

const reportsMainArticles = [
	{
		label: 'What reports does Moosend provide?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403501515666-What-reports-does-our-platform-provide',
	},
	{
		label: 'How can I see all activities of a recipient?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403500346002-How-can-I-see-all-activities-of-a-recipient',
	},
	{
		label: 'Can I share my report with other people?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403451204370-Share-your-report-with-other-people',
	},
	{
		label: 'How can I create a Custom Report?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403617443346-Create-a-Custom-Report',
	},
	{
		label: 'How can I export my data from a Custom Report?',
		url: 'https://help.moosend.com/hc/en-us/articles/4403618759954-Export-your-data-from-a-Custom-Report-',
	},
	{
		label: 'Learn more about reporting',
		url: 'https://help.moosend.com/hc/en-us/sections/4407514468114-Reports',
		withArrow: true,
	},
];

export const helpCenter = {
	landingPages: {
		drawerArticles: [
			{
				label: 'What is a Landing Page?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405379708178-What-is-a-Landing-Page',
			},
			{
				label: 'How Can I Create a Landing Page στα ελληνικα?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403146809618-Create-a-Landing-Page',
			},
			{
				label: 'Can I track conversions in my landing page?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403349114898-Track-conversions-in-your-landing-page',
			},
			{
				label: 'What is the Moosend Landing Page Designer? ',
				url: 'https://help.moosend.com/hc/en-us/articles/4403147463698-What-is-the-Landing-Page-Designer-',
			},
			{
				label: 'How can I configure my Landing Page Domain?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403783120402-Configure-your-Landing-Page-Domain',
			},
			{
				label: 'Learn more about landing pages',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514450194-Growth-Tools',
				withArrow: true,
			},
		],
		articles: leadGenMainArticles,
		routes: ['/lead-generation/landing-pages'],
		title: 'Need help with your landing page?',
		description: 'Here are some how-to articles to get you started.',
	},
	integrations: {
		drawerArticles: [
			{
				label: 'How do I connect Moosend to Zapier?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404735437714-Connect-our-platform-to-Zapier',
			},
			{
				label: 'What is the Mailchimp Synchronizer and how can I use it?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404920087826-The-Mailchimp-Synchronizer-and-how-you-can-use-it',
			},
			{
				label: 'How can I use the Moosend Landing Pages wordpress plugin to import my landing pages on my wordpress website?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407400459410-Use-the-Moosend-Landing-Pages-wordpress-plugin-to-import-your-landing-pages-on-your-wordpress-website',
			},
			{
				label: 'What is the Google Contacts Plugin and how can I use it?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407352214290-The-Google-Contacts-Plugin-and-how-you-can-use-it',
			},
			{
				label: 'What information does the Mailchimp Synchronizer transfer?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407702899858-What-information-does-the-Mailchimp-Synchronizer-transfer',
			},
			{
				label: 'Learn more about integrations',
				url: 'https://help.moosend.com/hc/en-us/sections/4407521933842-Integrations',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/integrations'],
		title: 'Need help managing your favorite apps?',
		description: 'Here are some how-to articles to get you started.',
	},
	automations: {
		drawerArticles: [
			{
				label: 'What is a Moosend Automation?',
				url: 'https://help.moosend.com/hc/en-us/articles/4406847174546-What-is-an-Automation-Workflow',
			},
			{
				label: 'How can I create a Welcome Email automation?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407394642962-Create-a-Welcome-Email-automation',
			},
			{
				label: 'How can I create an Abandoned Cart automation?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407354002322-Create-an-Abandoned-Cart-automation',
			},
			{
				label: 'What reporting can I get from my automations?',
				url: 'https://help.moosend.com/hc/en-us/articles/4406847557266-The-reporting-you-can-get-from-your-automation',
			},
			{
				label: 'Do I need to have my campaign ready before I design an automation?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407117851794-Do-you-need-to-have-your-campaign-ready-before-you-design-an-automation',
			},
			{
				label: 'Learn more about automations',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514454546-Automation',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/automations'],
		title: 'Need help running an automated marketing campaign?',
		description: 'Here are some how-to articles to get you started.',
	},
	reporting: {
		drawerArticles: [
			{
				label: 'What is an Email Marketing Report?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403493921938-What-is-an-Email-Marketing-Report-',
			},
			{
				label: 'What reports does Moosend provide?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403501515666-What-reports-does-our-platform-provide',
			},
			{
				label: 'How can I see all activities of a recipient?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403500346002-How-can-I-see-all-activities-of-a-recipient',
			},
			{
				label: 'How can I track the most popular links being clicked on my content?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403491637010-Track-the-most-popular-links-being-clicked-on-your-content',
			},
			{
				label: 'Can I share my report with other people?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403451204370-Share-your-report-with-other-people',
			},
			// { label: 'Learn more about reporting', url: 'https://help.moosend.com/hc/en-us/sections/201776779-Reports', withArrow: true }
		],
		articles: reportsMainArticles,
		routes: ['/reports', '/dashboards/campaign'],
		title: 'Need help with reporting?',
		description: 'Here are some how-to articles to get you started.',
	},
	regularCampaigns: {
		drawerArticles: [
			{
				label: 'How can I import my campaigns?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402987994642-Import-your-campaigns',
			},
			{
				label: 'How do I create a new Email Marketing Campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402716613266-Create-a-new-Email-Marketing-Campaign',
			},
			{
				label: 'Can I schedule a campaign and send it whenever I want?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402813532562-Schedule-a-campaign-and-send-it-whenever-you-want',
			},
			{
				label: 'Can I automatically resend my campaign to subscribers who did not open it?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402814829330-Automatically-resend-your-campaign-to-subscribers-who-did-not-open-it',
			},
			{
				label: 'Create or edit your template inside the Campaign Template Editor',
				url: 'https://help.moosend.com/hc/en-us/articles/4405011431698-Create-or-edit-your-template-inside-the-Campaign-Template-Editor',
			},
			{
				label: 'Learn more about campaigns',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514438162-Campaigns',
				withArrow: true,
			},
		],
		articles: campaignsMainArticles,
		routes: ['/campaigns/Regular/Regular', '/campaigns/list/Regular'],
		title: 'Need help with your next campaign?',
		description: 'Here are some how-to articles to get you started.',
	},
	digestCampaigns: {
		drawerArticles: [
			{
				label: 'What is an RSS Campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402989967506-What-is-an-RSS-Campaign-',
			},
			{
				label: 'What is a Repeatable HTML Campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402982480530-What-is-a-Repeatable-HTML-Campaign-',
			},
			{
				label: 'How can I create a Repeatable HTML Campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403081916178-Create-a-Repeatable-HTML-Campaign-',
			},
			{
				label: 'How can I create an RSS campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402982031506-Create-an-RSS-campaign-',
			},
			{
				label: 'How can I use an RSS Campaign block?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403391894418-Use-an-RSS-Campaign-block',
			},
			{
				label: 'Learn more about RSS/Repeatable campaigns?',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514438162-Campaigns',
				withArrow: true,
			},
		],
		articles: campaignsMainArticles,
		routes: ['/campaigns/Digest', '/campaigns/list/Digest'],
		title: 'Need help with your next RSS/Repeatable campaign?',
		description: 'Here are some how-to articles to get you started.',
	},
	abCampaigns: {
		drawerArticles: [
			{
				label: 'What is an A/B Split Campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403082054802-What-is-an-A-B-Split-Campaign',
			},
			{
				label: 'What factors can I test when I perform an A/B split test on my campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403083250578-The-factors-you-can-test-when-you-perform-an-A-B-split-test-on-your-campaign',
			},
			{
				label: 'Can I schedule a campaign and send it whenever I want?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402813532562-Schedule-a-campaign-and-send-it-whenever-you-want',
			},
			{
				label: 'Can I automatically resend my campaign to subscribers who did not open it?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402814829330-Automatically-resend-your-campaign-to-subscribers-who-did-not-open-it',
			},
			{
				label: 'How can I organize my campaigns?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402763532434-Organize-your-campaigns',
			},
			{
				label: 'Learn more about A/B campaigns?',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514438162-Campaigns',
				withArrow: true,
			},
		],
		articles: campaignsMainArticles,
		routes: ['/campaigns/Regular/Ab'],
		title: 'Need help with your next A/B campaign?',
		description: 'Description',
	},
	billing: {
		drawerArticles: [
			{
				label: 'Can I have multiple payment methods on my account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407343664274-Can-you-have-multiple-payment-methods-on-your-account',
			},
			{
				label: 'How are credits charged in Pay As You Go plans?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403728724370-How-are-credits-charged-in-Pay-As-You-Go-plans',
			},
			{
				label: 'How can I view my past transactions?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405046228242-View-your-past-transactions-',
			},
			{
				label: 'How can I upgrade my Monthly Plan?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407344105874-Upgrade-your-Monthly-Plan',
			},
			{
				label: 'Will I be charged for sending out emails that bounce back?',
				url: 'https://help.moosend.com/hc/en-us/articles/4407344232210-Will-you-be-charged-for-sending-out-emails-that-bounce-back-',
			},
			{
				label: 'Learn more about billing?',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514504978-Billing',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/billing'],
		title: 'Having billing questions?',
		description: 'Here are some how-to articles to help you out.',
	},
	emailLists: {
		drawerArticles: [
			{
				label: 'How can I import my contacts to Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402771953170-Import-your-contacts-to-our-platform',
			},
			{
				label: 'How to make my lists GDPR compliant?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403040704530-Make-your-lists-GDPR-compliant',
			},
			{
				label: 'How can I use List Segmentation to create targeted campaigns?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403097466770-Use-List-Segmentation-to-create-targeted-campaigns',
			},
			{
				label: 'How can I set up double opt-in for my mailing lists?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403032004370-Set-up-double-opt-in-for-my-email-lists',
			},
			{
				label: 'How do I export my subscribers as a CSV file?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403031374354-Export-your-subscribers-as-a-CSV-file-',
			},
			{
				label: 'Learn more about email lists',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514461970-Audience',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/mailing-lists-new'],
		title: 'Need help with your email lists?',
		description: 'Here are some how-to articles to get you started.',
	},
	websites: {
		drawerArticles: [
			{
				label: 'How can I connect my website to Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4406842422546-Connect-your-website-to-our-platform',
			},
			{
				label: 'Can I get website tracking on my site?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405109917202-Get-website-tracking-on-your-site',
			},
			{
				label: 'How can I verify my website?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405102475666-Verify-your-website-',
			},
			{
				label: 'Why is my website not verified?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405110945042-Why-is-your-website-not-verified-',
			},
			{
				label: 'Identifying my website users',
				url: 'https://help.moosend.com/hc/en-us/articles/4404925642386-Identifying-your-website-users',
			},
			{
				label: 'Learn more about websites',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514513042-Websites',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/websites'],
		title: 'Need help connecting your website?',
		description: 'Here are some how-to articles to get you started.',
	},
	subscriptionForms: {
		drawerArticles: [
			{
				label: 'How can I create a subscription form?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403356535954-Create-a-subscription-form',
			},
			{
				label: 'What is the Moosend Form Designer?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403351706898-What-is-the-Form-Designer',
			},
			{
				label: 'Can I display my Modal Pop-up form when a user exits a page?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403352587282-Display-your-Modal-Pop-up-form-when-a-user-leaves-a-page-',
			},
			{
				label: 'How can I publish my subscription form?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403449116690-Publish-your-subscription-form',
			},
			{
				label: 'How can I hide the form from visitors who have submitted it already?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403399969042-Hide-the-form-from-visitors-who-have-submitted-it-already',
			},
			{
				label: 'Learn more about subscription forms',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514450194-Growth-Tools',
				withArrow: true,
			},
		],
		articles: leadGenMainArticles,
		routes: ['/lead-generation/subscription-forms'],
		title: 'Need help with your subscription form?',
		description: 'Here are some how-to articles to get you started.',
	},
	dashboards: {
		drawerArticles: [
			{
				label: 'How can I create a Custom Report?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403617443346-Create-a-Custom-Report',
			},
			{
				label: 'What are the different widget categories?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403033401874-What-are-the-different-widget-categories-',
			},
			{
				label: 'How can I export my data from a Custom Report?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403618759954-Export-your-data-from-a-Custom-Report-',
			},
			{
				label: 'Are Custom Reports shareable?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403627496722-Are-Custom-Reports-shareable',
			},
			{
				label: 'How can I add a widget in a Custom Report?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403627904914-Add-a-widget-in-a-Custom-Report',
			},
			{
				label: 'Learn more about custom reporting',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514468114-Reports',
				withArrow: true,
			},
		],
		articles: reportsMainArticles,
		routes: ['/dashboards'],
		title: 'Need help with your custom reports?',
		description: 'Here are some how-to articles to get you started.',
	},
	account: {
		drawerArticles: [
			{
				label: 'Do I need a website to send campaigns with Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403727446290-Do-you-need-a-website-to-send-campaigns-with-our-platform',
			},
			{
				label: 'How do I create a sub-account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403728231058-Create-a-sub-account-',
			},
			{
				label: 'How can I manage team members on my Moosend account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404744432658-Manage-team-members-on-your-account-',
			},
			{
				label: 'How are credits charged in Pay As You Go plans?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403728724370-How-are-credits-charged-in-Pay-As-You-Go-plans',
			},
			{
				label: 'How can I configure my Landing Page Domain?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403783120402-Configure-your-Landing-Page-Domain',
			},
			{
				label: 'Learn more about account settings',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514476690-Account',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/account-settings/datetime'],
		title: 'Need help with your account?',
		description: 'Here are some how-to articles to get you started.',
	},
	teamMembers: {
		drawerArticles: [
			{
				label: 'How can I manage team members on my Moosend account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404744432658-Manage-team-members-on-your-account-',
			},
			{
				label: 'What are team member roles and what are their permissions?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404792631698-The-team-member-roles-and-their-permissions-',
			},
			{
				label: 'How do I create a sub-account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403728231058-Create-a-sub-account-',
			},
			{
				label: 'How do I connect to the Moosend Web API?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403735862674-Connect-to-the-Web-API-',
			},
			{
				label: 'Do I need a website to send campaigns with Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403727446290-Do-you-need-a-website-to-send-campaigns-with-our-platform',
			},
			{
				label: 'Learn more about team members',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514476690-Account',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/account-settings/team-members'],
		title: 'Need help with team members?',
		description: 'Here are some how-to articles to get you started.',
	},
	apiKey: {
		drawerArticles: [
			{
				label: 'How do I connect to the Moosend Web API?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403735862674-Connect-to-the-Web-API-',
			},
			{
				label: 'How can I set my own URL instead of moosend.com?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404167493266-Set-your-own-URL-instead-of-our-platform-s',
			},
			{
				label: 'How can I configure my Landing Page Domain?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403783120402-Configure-your-Landing-Page-Domain',
			},
			{
				label: 'How can I configure my Platform Domain?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404171473554-Configure-your-Platform-Domain-',
			},
			{
				label: 'How can I verify my Custom Domain?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404791763602-Verify-your-Custom-Domain-',
			},
			{
				label: 'Learn more about your api key',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514476690-Account',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/account-settings/apikey'],
		title: 'Having API questions?',
		description: 'Here are some how-to articles to help you out.',
	},
	senders: {
		drawerArticles: [
			{
				label: `Why isn't the Verified field on my Senders page checked?`,
				url: 'https://help.moosend.com/hc/en-us/articles/4403449685266-Why-isnt-the-Verified-field-on-your-Senders-page-checked',
			},
			{
				label: 'How do I set up a new sender?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402717408146-Set-up-a-new-sender',
			},
			{
				label: `Why hasn't my sender been approved?`,
				url: 'https://help.moosend.com/hc/en-us/articles/4403618430866-Why-hasnt-your-sender-been-approved',
			},
			{
				label: 'Can I use a different sender for each campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403449978642-Can-you-use-a-different-sender-for-each-campaign',
			},
			{
				label: 'What is a Verified Sender?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403625914514-What-is-a-Verified-Sender-',
			},
			{
				label: 'Learn more about senders',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514476690-Account',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/account-settings/senders'],
		title: 'Need help with your "from" names?',
		description: 'Here are some how-to articles to get you started.',
	},
	customDomains: {
		drawerArticles: [
			{
				label: 'How can I verify my Custom Domain?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404791763602-Verify-your-Custom-Domain-',
			},
			{
				label: 'How can I add a new Custom Domain for my Landing Pages?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404968753170-Add-a-new-Custom-Domain-for-your-Landing-Pages-',
			},
			{
				label: 'How can I add a new Custom Domain for the Platform?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404962814610-Add-a-new-Custom-Domain-for-our-Platform',
			},
			{
				label: 'How can I delete a Custom Domain?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404792973714-Delete-a-Custom-Domain-',
			},
			{
				label: 'How can I set my own URL instead of moosend.com?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404167493266-Set-your-own-URL-instead-of-our-platform-s',
			},
			{
				label: 'Learn more about branding',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514476690-Account',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/custom-domains'],
		title: 'Need help with branding?',
		description: 'Here are some how-to articles to get you started.',
	},
	clients: {
		drawerArticles: [
			{
				label: 'How do I create a sub-account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403728231058-Create-a-sub-account-',
			},
			{
				label: 'How can my client log in their sub-account?',
				url: 'https://help.moosend.com/hc/en-us/articles/207388649-How-can-your-client-log-in-their-subaccount',
			},
			{
				label: 'The team member roles and their permissions',
				url: 'https://help.moosend.com/hc/en-us/articles/4404792631698-The-team-member-roles-and-their-permissions-',
			},
			{
				label: 'Connect to the Web API',
				url: 'https://help.moosend.com/hc/en-us/articles/4403735862674-Connect-to-the-Web-API-',
			},
			{
				label: 'How can I manage team members on my Moosend account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4404744432658-Manage-team-members-on-your-account-',
			},
			{
				label: 'Learn more about clients',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514476690-Account',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/account-settings/clients'],
		title: 'Need help managing different clients?',
		description: 'Here are some how-to articles to get you started.',
	},
	directSmtp: {
		drawerArticles: [
			{
				label: 'What is SMTP and how can I use it?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405943965970-What-is-SMTP-and-how-can-you-use-it',
			},
			{
				label: 'Why is the SMTP creating a new mailing list each time I send?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405976636178-Why-is-the-SMTP-creating-a-new-email-list-each-time-you-send-',
			},
			{
				label: 'Can I send transactional emails?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405976903826-Send-transactional-emails',
			},
			{
				label: 'How can I import my contacts to Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402771953170-Import-your-contacts-to-our-platform',
			},
			{
				label: 'How to make my lists GDPR compliant?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403040704530-Make-your-lists-GDPR-compliant',
			},
			{
				label: 'Learn more about SMTP',
				url: 'https://help.moosend.com/hc/en-us/sections/4407514476690-Account',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/settings/direct-smtp'],
		title: 'Need help with your SMTP?',
		description: 'Here are some how-to articles to get you started.',
	},
	suppressionList: {
		drawerArticles: [
			{
				label: 'What is a Suppression list?',
				url: 'https://help.moosend.com/hc/en-us/articles/360021115419-What-is-a-Suppression-list',
			},
			{
				label: 'How can I add members to my Suppression List?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405047866386-Add-members-to-your-Suppression-List-',
			},
			{
				label: 'Can I permanently delete the members that exist in my Suppression List?',
				url: 'https://help.moosend.com/hc/en-us/articles/4405060262418-Permanently-delete-the-members-that-exist-in-your-Suppression-List',
			},
			{
				label: 'How can I import my contacts to Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402771953170-Import-your-contacts-to-our-platform',
			},
			{
				label: 'How to make my lists GDPR compliant?',
				url: 'https://help.moosend.com/hc/en-us/articles/4403040704530-Make-your-lists-GDPR-compliant',
			},
			{
				label: 'Learn more about suppression list',
				url: 'https://help.moosend.com/hc/en-us/sections/4407521982226-Suppression-List',
				withArrow: true,
			},
		],
		articles: [],
		routes: ['/all/1'],
		title: 'Need help with your suppression list?',
		description: 'Here are some how-to articles to get you started.',
	},
	main: {
		drawerArticles: [
			{
				label: 'How do I get started with my Moosend account?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402864722834-Get-started-with-your-account',
			},
			{
				label: 'How do I create a new Email Marketing Campaign?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402716613266-Create-a-new-Email-Marketing-Campaign',
			},
			{
				label: 'How do I set up a new sender?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402717408146-Set-up-a-new-sender',
			},
			{
				label: 'How can I connect my website to Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4406842422546-Connect-your-website-to-our-platform',
			},
			{
				label: 'How can I import my contacts to Moosend?',
				url: 'https://help.moosend.com/hc/en-us/articles/4402771953170-Import-your-contacts-to-our-platform',
			},
			{ label: 'Learn more', url: 'https://moosend.com/help/', withArrow: true },
		],
		articles: [],
		routes: [],
		title: 'Need help?',
		description: 'Our reading material might be just what you need!',
	},
};
