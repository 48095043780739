import { extendTheme } from '@chakra-ui/react';
import sitecoreTheme from '@sitecore/blok-theme';

export const theme = extendTheme(sitecoreTheme, {
	styles: {
		global: {
			'.react-content': {
				display: 'flex',
				flexDirection: 'column',
				overflowX: 'hidden',
			},
		},
	},
});
