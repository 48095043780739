import React from 'react';

import { convertSize, getId } from 'shared/src/utils/shared.js';

import { imageUrl } from '../utils.js';

export const DefaultAcceptedFileTypes = ['.jpg, .jpeg', '.pdf', '.png', '.gif'];

const FileUpload = ({ type, label, description, name, required, maxFiles, fileSizeLimit, fileSizeLimitUnit, includeMetadata, ...rest }) => {
	const id = getId(rest.id);
	const labelId = `${id}-label`;
	const descriptionId = `${id}-description`;
	const acceptedFileTypes = rest.acceptedFileTypes?.length ? rest.acceptedFileTypes : DefaultAcceptedFileTypes;

	const getDescription = () => {
		return description
			.replaceAll('[count]', maxFiles)
			.replaceAll('[size]', convertSize(fileSizeLimit, 'Bytes', fileSizeLimitUnit) + fileSizeLimitUnit);
	};

	return (
		<div
			id={id}
			data-use-file-metadata={includeMetadata ? true : undefined}
			className={`component shared-input-field file-upload-field ${
				required ? 'required-component' : ''
			} ${type}-component ${type}-wrapper ${type}${id}`}
		>
			<div className={`global-input-wrapper ${type}-dropzone`}>
				<input
					type="file"
					data-type={type}
					className={`form-input-element ${type}-input`}
					accept={acceptedFileTypes.join(',').replace(/,\s/g, ',')}
					multiple={maxFiles > 1}
					data-max-files={maxFiles}
					data-file-size-limit={fileSizeLimit}
					{...(label && { ['aria-labelledby']: labelId })}
					{...(description && { ['aria-describedby']: descriptionId })}
				/>
				<input
					type="hidden"
					data-type={type}
					data-id={id}
					required={required}
					data-required={required}
					data-label={label}
					className={`form-input-element ${type}-input-value`}
					name={name}
					aria-hidden="true"
				/>

				<div className={`${type}-box`}>
					<img src={imageUrl('/images/file_upload/plus_outlined.png')} alt="" />
					{label && (
						<span id={labelId} className="global-input-label form-field-label ">
							{!rest.labelHide && label}
							<span className="a11y-visible form-field-required">required field</span>
							<span className="global-field-required form-field-required " aria-hidden="true">
								{' '}
								*
							</span>
						</span>
					)}
					{description && (
						<div id={descriptionId} className="global-input-description form-input-description  ">
							{getDescription()}
						</div>
					)}
				</div>
			</div>

			<div className={`${type}-error-container component-error-container`} />

			<div className="a11y-announce a11y-visible" tabIndex="-1" role="status" aria-live="assertive" />

			<div className={`${type}-files`}>
				{Array.from(Array(maxFiles).keys()).map((idx) => (
					<div key={idx} className={`${type}-file-wrapper`}>
						<div className={`${type}-file`}>
							<img src={imageUrl('/images/file_upload/file.png')} alt="" />
							<span className={`${type}-file-name`} />
							<span className={`${type}-file-size`} />

							<div className={`${type}-file-actions`}>
								<button type="button" className={`${type}-remove`} />
								<div className={`${type}-loader`} />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FileUpload;
