import React from 'react';

import { Box, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { SketchPicker } from 'react-color';

import useContentStore from '@/stores/ContentStore';

import TextInputSetting from './TextInputSetting.react';

const ColorPickerSetting = ({
	label = 'Color Picker',
	disabled = false,
	selectedColor,
	inputCustomCss,
	placeholder,
	onChange,
	onOverlayClick,
	type,
	hex,
}) => {
	const renderColor = () => {
		const colorStyles =
			selectedColor && selectedColor !== 'transparent'
				? { bgColor: selectedColor }
				: {
						bg: 'unset',
						bgColor: 'white',
						bgSize: '7px 7px',
						css: {
							backgroundImage: `linear-gradient(45deg, #000 25%, transparent 25%, transparent 50%, #000 50%, #000 75%, transparent 75%, #fff)`,
						},
					};

		return (
			<PopoverTrigger>
				<Box
					className="color-container"
					display="flex"
					alignItems="center"
					justifyContent="center"
					boxSize="5"
					cursor="pointer"
					boxShadow="rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px"
					data-testid="color-picker-indicator"
					{...colorStyles}
				/>
			</PopoverTrigger>
		);
	};

	const handleClose = () => {
		if (onOverlayClick) onOverlayClick();
		useContentStore.getState().addUsedColor(selectedColor);
	};

	const handleChange = (color) => {
		const opacity = selectedColor === 'transparent' ? 1 : color.rgb.a;
		const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${opacity})`;
		onChange({ type, value: value.toLowerCase() });
	};

	const handleInputChange = ({ value }) => {
		let returnValue;

		if (hex && value.toLowerCase().includes('rgb')) {
			returnValue = rgbHex(value);
		} else {
			returnValue = value;
		}

		onChange({ type, value: returnValue.toLowerCase() });
	};

	const rgbHex = (color) => {
		const colorMatch = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		return colorMatch && colorMatch.length === 4
			? '#' +
					('0' + parseInt(colorMatch[1], 10).toString(16)).slice(-2) +
					('0' + parseInt(colorMatch[2], 10).toString(16)).slice(-2) +
					('0' + parseInt(colorMatch[3], 10).toString(16)).slice(-2)
			: '';
	};

	return (
		<Popover placement="bottom-end" onClose={handleClose}>
			<PopoverContent w="initial">
				<SketchPicker
					onChangeComplete={handleChange}
					color={selectedColor}
					presetColors={useContentStore.getState().content.usedColors || []}
				/>
			</PopoverContent>
			<TextInputSetting
				disabled={disabled}
				customStyle={inputCustomCss}
				text={selectedColor}
				label={label}
				placeholder={placeholder || label}
				onChange={handleInputChange}
				renderColor={renderColor}
				type={type}
			/>
		</Popover>
	);
};

export default ColorPickerSetting;
