import React from 'react';

import { getPadding, getShadowValFromProps, getTransformerFormFieldFontStyles } from 'shared/src/utils/shared.js';

export const basicLabelStyles = {
	userSelect: 'none',
	whiteSpace: 'pre-wrap',
	wordBreak: 'break-word',
};

export const inputGroupStyles = {
	padding: 0,
	display: 'flex',
	flexDirection: 'column',
};

export const MultiSelect = (props) => {
	const { formId, options } = props;

	const directionClass =
		props.labelPosition === 'row' ? 'lp-flex-direction-horizontal lp-flex-align-center' : 'lp-flex-direction-vertical element-vertical-ie';

	const id = props.id ? props.id.toString().replace(/-/g, '') : '';
	const labelId = `${id}-label`;
	const optionsListId = `${id}-options-list`;

	return (
		<div
			id={id}
			className={`component multi-select-field ${props.required ? 'required-component' : ''} ${props.type}-component ${
				props.type
			}${props.id.replace(/-/g, '')} form-input-wrapper`}
		>
			<label
				className={`form-input-wrapper-element form-input-select-element form-input-multiselect-element lp-flex-container form-input-${formId} ${directionClass}`}
			>
				<span id={labelId} className="global-input-label form-field-label ">
					{!props.labelHide && props.label}
					{!props.descriptionHide && props.description && <span className="a11y-visible">, {props.description},</span>}
					<span className="global-field-required form-field-required " aria-hidden="true">
						{' '}
						*
					</span>
				</span>
				<div className="lp-flex-1">
					<input
						data-id={id}
						className={`lp-flex-1 global-input-wrapper global-input-element form-input-element multiselect-input params-${props.type}`}
						placeholder={props.placeholder}
						value={props.value}
						readOnly
						data-label={props.label}
						data-type={props.type}
						data-required={props.required}
						required={props.required}
						// data-validate={props.validate || false}
						disabled={options.length <= 0}
						name={props.name}
						type={props.hidden ? 'hidden' : 'text'}
						data-readOnly={props.readOnly}
						data-values="[]"
						data-labels="[]"
						style={{ display: 'block' }} // This exists to help VoiceOver with Safari
						role="combobox"
						aria-haspopup="listbox"
						aria-controls={optionsListId}
						aria-expanded="false"
						aria-labelledby={labelId}
					/>
				</div>

				{options.length > 0 && (
					<div
						id={optionsListId}
						className="form-select-option-container "
						style={{ display: 'none' }}
						data-options-limit={props.optionsLimit}
						role="listbox"
						aria-labelledby={labelId}
					>
						{options.map((option, i) => {
							return (
								<div
									id={`${id}-option-${i}`}
									key={i}
									data-value={option.value}
									className="form-select-option"
									role="option"
									aria-selected="false"
								>
									<span className="selected-indicator mdi" aria-hidden="true"></span>
									<span className="text-indicator">{option.label}</span>
								</div>
							);
						})}
					</div>
				)}

				<i className="mdi mdi-menu-down  " aria-hidden="true"></i>
			</label>

			{!props.descriptionHide && props.description && (
				<div className="global-input-description form-input-description  ">{props.description}</div>
			)}
		</div>
	);
};

export const generateMultiSelectClass = (component) => {
	const dynamicContainerStyles =
		component.labelPosition === 'row'
			? `
					align-items: 'center';
					-webkit-box-align: 'center';
					-ms-flex-align: 'center';
			  `
			: ``;

	const labelDimension =
		component.labelPosition === 'row'
			? `
					width: ${component.labelWidth}px;
			  `
			: `
					min-height: ${component.labelWidth}px;
			  `;

	const fieldStyle = `
		background: ${component.fieldBgColor};
		padding: ${component.fieldPadding}px;
		padding-right: ${component.fieldPadding + 30}px;
		color: ${component.fieldFontColour};
		font-size: ${component.fieldFontSize / 16}rem;
		border-width: ${component.fieldBorderWidth}px;
		border-color: ${component.fieldBorderColour};
		border-style: solid;
		border-radius: ${component.fieldBorderRadius}px;
		font-weight: ${component.fieldFontWeight};
		font-style: ${component.fieldFontStyle};
		text-decoration: ${component.fieldTextDecoration};
		box-shadow: ${getShadowValFromProps(component.box_shadow)};
		${getTransformerFormFieldFontStyles(component.fieldFont)}
	`;
	return `
        .${component.type}${component.id.replace(/-/g, '')} {
            width: 100%; 
            position: relative;
            background-color: ${component.background_color};
            display: ${component.hidden ? 'none' : 'block'};
			${getPadding(component)}
        }

        .${component.type}${component.id.replace(/-/g, '')} label {
            flex-direction: ${component.labelPosition};
            position: relative;
            ${dynamicContainerStyles}
        }

        .${component.type}${component.id.replace(/-/g, '')} label .form-field-label {
            color: ${component.labelFontColour};
            font-size: ${component.labelFontSize / 16}rem;
            font-weight: ${component.labelFontWeight};
            font-style: ${component.labelFontStyle};
            text-decoration: ${component.labelTextDecoration};
            display: ${component.labelHide ? 'none' : 'block'};
            ${labelDimension}
            ${getTransformerFormFieldFontStyles(component.labelFont)}
        }

        .${component.type}${component.id.replace(/-/g, '')} label .form-field-label .form-field-required {
            color: ${component.asteriskColor};
        }

        .${component.type}${component.id.replace(/-/g, '')} .form-input-wrapper-div {
            display: block;
            flex: 1 1 auto; 
        }

        .${component.type}${component.id.replace(/-/g, '')} .form-input-element {
            width: 100%; 
            min-width: 0; 
            outline: none; 
            flex: 1;
            caret-color: transparent;
            cursor: pointer;
            ${fieldStyle}
        }

				.${component.type}${component.id.replace(/-/g, '')} .form-input-element:focus {
						outline: 2px solid #007fff;
				}

        .${component.type}${component.id.replace(/-/g, '')} .form-select-option-container {
            ${fieldStyle}
            max-height: 215px;
            overflow-y: auto;
            padding: 0;
            border: 1px solid #007fff;
            border-radius: 0;
            font-size: ${component.fieldFontSize / 16}rem;
            position: absolute;
            left: ${component.labelHide ? 0 : component.labelPosition === 'row' ? component.labelWidth : 0}px;
            z-index: 6;
            right: 0;
            top: calc(${(component.fieldFontSize || 16) / 16}rem +
							${
								(component.fieldPadding * 2 || 14) +
								(component.borderWidth * 2 || 4) +
								(component.labelPosition !== 'row' && component.labelHide !== true ? component.labelWidth : 0)
							}px +
							0.1875rem);
        }

        .${component.type}${component.id.replace(/-/g, '')} label i {
            position: absolute;
            bottom: ${component.fieldPadding - 4}px;
            right: ${component.fieldPadding + 3}px;
            color: ${component.fieldFontColour};
            font-size: ${(component.fieldFontSize + 10) / 16}rem;
            cursor: pointer;
			line-height: 1;
        }

        .${component.type}${component.id.replace(/-/g, '')} .form-input-element::placeholder {
            color: ${component.placeholderFontColour};
            font-size: ${component.fieldFontSize / 16}rem;
            font-weight: ${component.placeholderFontStyle};
            font-style: ${component.placeholderFontWeight};
            text-decoration: ${component.placeholderTextDecoration};
            font-family: ${component.placeholderFont};
        }

        .${component.type}${component.id.replace(/-/g, '')} .form-input-description, .${component.type}${component.id.replace(
					/-/g,
					'',
				)} .form-input-error-message {
            font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
            color: ${component.descriptionFontColour};
            font-family: ${component.descriptionFontFamily};
            font-size: ${component.descriptionFontSize / 16}rem;
            margin-top: ${component.descriptionSpacing}px;
            display: ${component.descriptionHide ? 'none' : 'block'};
			padding-left: ${component.labelPosition === 'row' ? component.labelWidth + 'px' : 'initial'};
        }
    `;
};

export default MultiSelect;
