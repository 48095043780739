/*
 * KEEP THIS FILE ISOMORPHIC
 * DO NOT IMPORT ANYTHING THAT IS NOT ISOMORPHIC AND WONT WORK ON THE NODE APP
 */

import React from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

import { FormFailActions, FormSuccessActions, validationMessages } from 'shared/src/utils/shared.js';

import { createFormClasses } from './CssGenerators/CssClassGenerators';
import { designerScript } from './DesignerScript';
import domHelper from './domHelper';
import FormWrapper from './FormWrapper.html?raw';
import { addGoogleFontsLink } from './GoogleFontsGenerator';
import TransformerComponent from './Transformer.react';

export default function transformer(contentModel, entityId, isPreview = false, logicsErrors = [], window) {
	let htmlContainer = window.document.createElement('div');
	let contentHtml;

	const root = createRoot(htmlContainer);

	flushSync(() => {
		root.render(<TransformerComponent content={contentModel} entityId={entityId} />);
	});
	//clone node so that the actual dom node is not mutated
	let node = htmlContainer.firstElementChild && htmlContainer.firstElementChild.cloneNode(true);

	//traverse dom node and clean up the attributes and other operations
	domHelper.traverseDOM(node, function (element) {
		if (element && element.style && element.style.fontFamily === 'inherit') {
			element.style.removeProperty('font-family');
		}

		if (element && element.style && element.style.fontFamily) {
			element.style.fontFamily = `${element.style.fontFamily}, ${contentModel.font_family}`;
		}

		//makes all content links open a new tab
		if (element && element.tagName === 'A' && element.href) {
			if (!element.target) {
				element.setAttribute('target', '_self');
			}

			element.style = `${element.style.cssText} text-decoration:none !important; text-decoration:none;`;

			// removes the space from hrefs before transforming content, space because of ckeditor gets broken if href starts with #
			const href = element.getAttribute('href');
			if (href.charAt(0) === ' ') {
				element.setAttribute('href', href.substr(1));
			}
		}

		//remove link if there is no href
		if (
			element &&
			element.tagName === 'A' &&
			element.parentNode.getAttribute('class') &&
			element.parentNode.getAttribute('class').includes('image') &&
			!element.getAttribute('href')
		) {
			element.parentNode.innerHTML = element.innerHTML;
		}

		if (element && element.tagName === 'A' && element.id) {
			element.name = element.id;
		}

		if (element && element.tagName === 'P') {
			//remove margins from <p> tags because most clients don't read <head> styles
			if (!element.getAttribute('style') || !element.getAttribute('style').includes('margin')) {
				element.style.margin = 0;
			}
			// element.style.padding = 0;
		}

		if (element && element.tagName === 'SMALL') {
			element.style.fontWeight = 100;
		}

		if (element && element.tagName === 'BIG') {
			element.style.fontWeight = 800;
		}
	});

	let dom = parseFormDom(entityId);

	let contentEl = dom.querySelector('.content');

	let signatureInput = window.document.createComment('###__FORM_SIGNATURE__###');

	// if (checkHasRecaptcha(contentModel.rows)){
	// 	contentEl.setAttribute()
	// }

	contentEl.setAttribute('data-formid', entityId);

	if (contentEl && node) {
		contentEl.innerHTML = minifyHtml(node.innerHTML);
	}

	contentEl.appendChild(signatureInput);
	contentEl.prepend(createErrorsLiveRegion());

	let generalSettings = {
		color: contentModel.color,
		text_line_height: contentModel.text_line_height,
		backgroundColor: contentModel.bg_color,
		fontSize: contentModel.font_size + 'px',
		fontFamily: contentModel.font_family,
		backgroundImage: contentModel.background_image,
		backgroundRepeat: contentModel.background_repeat === 'full' ? 'no-repeat' : contentModel.background_repeat,
		backgroundSize: contentModel.background_size,
		bg_color: contentModel.bg_color,
		title: contentModel.title,
		borderColor: contentModel.borderColor,
		borderWidth: contentModel.borderWidth,
		retina_images: contentModel.retina_images,
	};

	const successAction = contentModel.successAction;
	const failAction = contentModel.failAction;

	if (failAction?.type === FormFailActions.message) {
		contentEl.setAttribute('data-fail-action', failAction.type);
		const messageEl = dom.querySelector('.fail-popup');
		if (messageEl) {
			messageEl.innerHTML = messageEl.innerHTML + failAction.message;
		}
	}

	if (successAction?.type === FormSuccessActions.message) {
		contentEl.setAttribute('data-success-action', successAction.type);
		const messageEl = dom.querySelector('.success-popup');
		if (messageEl) {
			messageEl.innerHTML = messageEl.innerHTML + successAction.message;
		}
	} else if (successAction?.type === FormSuccessActions.redirectUrl) {
		contentEl.setAttribute('data-success-action', successAction.type);
		contentEl.setAttribute('data-success-redirect-url', successAction.redirectUrl);
	}

	dom = addGoogleFontsLink(dom, dom, contentModel);
	contentHtml = getFormHtml(dom, contentModel, generalSettings, isPreview, logicsErrors, entityId);

	// contentHtml = contentHtml.replace(/<!--[\s\S]*?-->/g, ''); //strip away HTML comments
	// console.log(contentHtml);
	return contentHtml;
}

function createScript(script) {
	const scriptTag = window.document.createElement('script');
	scriptTag.innerHTML = script;
	return scriptTag;
}

const createErrorsLiveRegion = () => {
	const liveRegion = window.document.createElement('div');

	liveRegion.setAttribute('id', 'errors-live-region');
	liveRegion.className = 'a11y-visible';
	liveRegion.setAttribute('role', 'status');
	liveRegion.setAttribute('aria-live', 'assertive');

	return liveRegion;
};

const parseFormDom = (entityId) => {
	const dom = new window.DOMParser().parseFromString(FormWrapper, 'text/html');
	const el = dom.querySelector('.main-form-wrapper');
	el.className = el.className + ` wrapper-${entityId}`;
	return el;
};

const getFormHtml = (dom, contentModel, generalSettings, isPreview, logicsErrors, entityId) => {
	const formClasses = createFormClasses(contentModel, generalSettings, entityId);
	const styleCollection = dom.getElementsByTagName('style');
	const styleTag = styleCollection[0];
	const customCss = createCustomCssString(contentModel.customCss, entityId);
	styleTag.innerHTML = minifyCss(styleTag.innerHTML + formClasses + customCss);
	// styleTag.innerHTML = minifyCss(styleTag.innerHTML + formClasses + globalStyles);

	const isLogicsValid = !logicsErrors.length;
	const logics = [...(isLogicsValid && contentModel.logics ? contentModel.logics : [])].reverse();

	const additionalData = {
		logics: transformLogics(logics),
		fieldLogics: getFieldLogics(logics),
		validation_messages: Object.fromEntries(
			Object.keys(validationMessages).map((key) => [key, contentModel.validation_messages?.[key] || validationMessages[key]]),
		),
	};
	const afterScript = createScript(designerScript(entityId, isPreview, additionalData));

	dom.appendChild(afterScript);

	return dom.outerHTML;
};

export const minifyCss = (css) => css.replace(/\n/g, '').replace(/\s\s+/g, ' ');

const minifyHtml = (html) => html.replace(/^\s+|\r\n|\n|\r|(>)\s+(<)|\s+$/gm, '$1$2');

const createCustomCssString = (css, entityId) => {
	return !css ? '' : `.wrapper-${entityId}{${css}}`;
};

const transformCondition = (condition) => {
	if (condition.type === 'condition') {
		return {
			field: {
				name: condition.field.name,
				type: condition.field.type,
			},
			criteria: condition.settings.map((criterion) => ({
				comparer: criterion.comparer,
				value: Array.isArray(criterion.value) ? [...criterion.value].sort() : criterion.value,
			})),
		};
	}

	if (condition.type === 'group') {
		return {
			type: 'expression',
			expression: transformConditions(condition.settings),
		};
	}
};

const transformConditions = (conditions) => {
	if (conditions.length === 1) {
		return transformCondition(conditions[0]);
	}

	let andExpression = null;
	let orExpression = null;

	for (let i = 0; i < conditions.length; i++) {
		const condition = conditions[i];
		const isLastCondition = i + 1 === conditions.length;

		if (condition.operator === 'or' && !isLastCondition) {
			if (!orExpression) {
				orExpression = { type: 'or', operands: [] };
			}

			if (!andExpression) {
				orExpression.operands.push(transformCondition(condition));
			} else {
				andExpression.operands.push(transformCondition(condition));
				orExpression.operands.push(andExpression);
				andExpression = null;
			}
		} else if (condition.operator === 'and' && !isLastCondition) {
			if (!andExpression) {
				andExpression = { type: 'and', operands: [] };
			}

			andExpression.operands.push(transformCondition(condition));
		} else {
			if (andExpression) {
				andExpression.operands.push(transformCondition(condition));
			}

			if (orExpression) {
				if (andExpression) {
					orExpression.operands.push(andExpression);
				} else {
					orExpression.operands.push(transformCondition(condition));
				}
			}
		}
	}

	return orExpression || andExpression;
};

const transformLogics = (logics) =>
	logics.map((logic) => ({
		expression: transformConditions(logic.settings),
		actions: logic.actions.map((action) => ({
			type: action.action,
			field: {
				name: action.field.name,
				type: action.field.type,
			},
		})),
	}));

const getFieldLogics = (logics) => {
	const fieldLogics = {};

	for (let i = 0; i < logics.length; i++) {
		getFieldLogicsForConditions(logics[i].settings, fieldLogics, i);
	}

	return fieldLogics;
};

const getFieldLogicsForConditions = (conditions, fieldLogics, logicIdx) => {
	for (let i = 0; i < conditions.length; i++) {
		const condition = conditions[i];

		if (condition.type === 'condition') {
			if (!fieldLogics[condition.field.name]) {
				fieldLogics[condition.field.name] = [logicIdx];
			} else if (!fieldLogics[condition.field.name].includes(logicIdx)) {
				fieldLogics[condition.field.name].push(logicIdx);
			}
		} else if (condition.type === 'group') {
			getFieldLogicsForConditions(condition.settings, fieldLogics, logicIdx);
		}
	}
};
