import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { generateGuid } from 'shared/src/utils/shared.js';

import { SettingsBox, withSettingsContext } from '@/components/gui/settingsContext/SettingsContext';

const AccordionStyle = {
	'&:not(.hidden) + .toggle-block:not(.hidden)': {
		mt: -5,
		borderTop: 'none',
	},
	'&:not(.hidden) + .toggle-block:not(.hidden) .toggle-block-item': {
		borderTop: 'none',
	},
};

export const ToggleBlock = withSettingsContext(({ open, onToggle, filterValue, label, children, ...rest }) => {
	const [isOpen, setIsOpen] = useState(open || false);
	const [blockId] = useState(rest.blockId || generateGuid());

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	const onToggleFieldset = useCallback(() => {
		setIsOpen(!isOpen);
		if (onToggle) onToggle(!isOpen);
	}, [isOpen]);

	const shouldOpen = useMemo(() => (filterValue ? true : isOpen), [filterValue, isOpen]);

	return (
		<Accordion
			className="toggle-block"
			sx={AccordionStyle}
			mb={5}
			mx={-5}
			id={blockId}
			index={shouldOpen ? 0 : 1}
			allowToggle
			onChange={onToggleFieldset}
		>
			<AccordionItem className="toggle-block-item">
				<AccordionButton data-testid="toggle-block-button">
					{label}

					<AccordionIcon ml="auto" />
				</AccordionButton>

				<AccordionPanel mb={-2} motionProps={{ unmountOnExit: true }}>
					<SettingsBox blockId={blockId}>{children}</SettingsBox>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
});

export default memo(ToggleBlock);
