export const RegionsMap = {
	'West Europe': 'euw',
	'East US': 'use',
	'West US': 'usw',
	'West US 2': 'usw',
	'Japan East': 'jpe',
	'East Japan': 'jpe',
	'Australia East': 'aue',
	'East Australia': 'aue',
	'Southeast Asia': 'sg',
	eu1: 'euw',
	us1: 'use',
	us2: 'usw',
	jp1: 'jpe',
	au1: 'aue',
};
