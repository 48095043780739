import React, { useCallback } from 'react';

import { Box } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const CheckboxGroupComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

const CheckboxGroupSettings = (props) => {
	const onChange = useCallback(
		(data) => {
			let settings = {
				[data.type]: data.value,
			};

			if (props.address) {
				useContentStore.getState().saveElementSettings({
					address: props.address,
					settings,
				});
			}
		},
		[props.address],
	);

	return (
		<Box className="checkbox-settings">
			<CheckboxGroupComponents
				{...props}
				onChange={onChange}
				settings={{
					placeholder: {
						active: false,
					},
					options: {
						active: true,
					},
					optionsSpacing: {
						active: true,
					},
					optionsLimit: {
						active: true,
					},
					hiddenField: {
						active: false,
					},
					prefill: {
						active: false,
					},
					fieldGroup: {
						label: 'Checkbox styles',
						settings: {
							fieldBgColor: {
								label: 'Checkbox background color',
							},
							fieldFont: {
								active: false,
							},
							fieldFontSize: {
								active: true,
								label: 'Tick font size',
							},
							fieldFontColour: {
								label: 'Tick color',
							},
							fieldTextDecoration: {
								active: false,
							},
						},
					},
					labelGroup: {
						settings: {
							labelHide: {
								label: 'Hide main label',
							},
							labelPosition: {
								active: false,
							},
							labelWidth: {
								label: 'Label Height',
							},
						},
					},
					placeholderGroup: {
						active: false,
					},
				}}
			/>
		</Box>
	);
};

export default CheckboxGroupSettings;
