import { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import useUserStore from '@/stores/UserStore';

import { canAccessEntities } from '@/util/apiUtils/entities';
import { useRememberTenantAndOrg } from '@/util/auth/tenant-parameter';
import WebStorage from '@/util/webStorage';

import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';

export const AuthWrapper: React.FC = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const navigate = useNavigateWithParams();
	// const location = useLocation();

	const remember = useRememberTenantAndOrg();

	const checkAccess = async () => {
		await useUserStore.getState().getTenants();
		canAccessEntities()
			.then(() => {
				setIsAuthenticated(true);
			})
			.catch(() => {
				setIsAuthenticated(false);
				navigate('/no-access');
			});
	};

	const updateURLParams = () => {
		const tenantInfo: { tenantName: string; organization: string } = WebStorage.get('tenant_info');
		if (tenantInfo) remember(tenantInfo.organization, tenantInfo.tenantName);
	};

	useEffect(() => {
		checkAccess().then(() => updateURLParams());
	}, []);

	if (!isAuthenticated)
		return (
			<Box
				zIndex={9999}
				display="flex"
				alignItems="center"
				flexDirection="column"
				position="fixed"
				top={0}
				left={0}
				right={0}
				bottom={0}
				backgroundColor="white"
				justifyContent="center"
			>
				<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" />
			</Box>
		);

	return <Outlet />;
};
