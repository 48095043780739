import { Box, Tag } from '@chakra-ui/react';
import { Resizable } from 're-resizable';
import { AllComponentTypes, ComponentDefaults, getShadowValFromProps } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';

import { setAttributeInRef, simpleObjectEquals } from '@/util/helper';
import { ElementLimits } from '@/util/resources';

import Element from './Element.react';
import ElementComponent from './ElementComponent.react';

export class Spacer extends ElementComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.spacer] };

	constructor(props) {
		super(props);
		this.state = {
			height: this.props.height,
		};
	}

	componentDidUpdate(prevProps) {
		if (!simpleObjectEquals(prevProps, this.props)) {
			this.setState({ height: this.props.height });
		}
	}

	render() {
		let bgColor = this.props.background_color;

		const shadowSettings = getShadowValFromProps(this.props.box_shadow);

		//let backgroundImage = bgColor === 'transparent' ? 'repeating-linear-gradient(45deg, #efefef, #efefef 10px, #f9f9f9 10px, #f9f9f9 20px)' : 'none';

		//let backgroundImage = bgColor === 'transparent' ? 'repeating-linear-gradient(45deg, #efefef, #efefef 10px, rgba(249,249,249, 0.6) 10px, rgba(249,249,249, 0.6) 20px)' : 'none';

		let backgroundImage =
			bgColor === 'transparent'
				? 'repeating-linear-gradient(45deg, rgba(239, 239, 239, 0.40), rgba(239, 239, 239, 0.40) 10px, rgba(249,249,249, 0.25) 10px, rgba(249, 249, 249, 0.25) 20px)'
				: 'none';

		return (
			<table
				className="component spacer"
				ref={setAttributeInRef('align', 'center')}
				data-component-type="spacer"
				cellSpacing="0"
				cellPadding="0"
				width="100%"
				style={{
					backgroundColor: bgColor || 'transparent',
					clear: 'both',
					backgroundImage,
					borderWidth: this.props.borderWidth,
					borderColor: this.props.borderColor,
					borderRadius: this.props.borderRadius,
					borderStyle: this.props.borderWidth ? 'solid' : 'unset',
					boxShadow: shadowSettings,
				}}
				onWheel={this.onScroll}
			>
				<tbody>
					<tr>
						<td
							style={{
								position: 'relative',
							}}
							height={this.state.height}
						>
							{this.props.activeElement && (
								<Resizable
									size={{
										height: this.state.height,
										width: '100%',
									}}
									minHeight={ElementLimits[AllComponentTypes.spacer].height.min}
									maxHeight={ElementLimits[AllComponentTypes.spacer].height.max}
									onResizeStop={this.onResizeStop}
									onResize={(e, direction, ref) => {
										this.setState({ height: ref.clientHeight });
									}}
									enable={{
										bottom: this.props.activeElement,
									}}
									handleStyles={{
										bottom: {
											bottom: 7,
											height: 20,
										},
									}}
									handleComponent={{
										bottom: (
											<Box onMouseDown={(e) => e.preventDefault()} display="flex" alignItems="center" justifyContent="center" w="100%">
												<Tag variant="solid" colorScheme="purple">
													{this.state.height + 'px'}
												</Tag>
											</Box>
										),
									}}
								></Resizable>
							)}
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	onResizeStop = (e, direction, ref) => {
		useContentStore.getState().saveElementSettings({
			address: this.props.address,
			settings: {
				height: parseInt(ref.clientHeight),
			},
		});
	};

	onScroll = (event) => {
		let scroll = 0;

		if (this.props.activeElement) {
			event.preventDefault();
			event.stopPropagation();

			if (event.deltaY < 0 && this.state.height > ElementLimits[AllComponentTypes.spacer].height.min) {
				scroll = -1;
			} else if (event.deltaY > 0 && this.state.height < ElementLimits[AllComponentTypes.spacer].height.max) {
				scroll = 1;
			}

			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings: {
					height: parseInt(this.state.height + scroll),
				},
			});
		}
	};

	onResize = (event, { size }) => {
		this.setState({ height: size.height });
	};
}

export default Element(Spacer);
