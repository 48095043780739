import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Text, useToast } from '@chakra-ui/react';
import config from 'config/config';
import { omit } from 'lodash';
import Pusher from 'pusher-js';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useLocation, useParams } from 'react-router-dom';
import { generateGuid } from 'shared/src/utils/shared.js';
import { shallow } from 'zustand/shallow';

import useEntitiesStore, { initialState } from '@/stores/EntitiesStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';

import { getEntity as getEntityUtil } from '@/util/apiUtils/entities';
import { checkJsonValidity, updateTemplateJson } from '@/util/helper';
import { NotificationTypes } from '@/util/resources';

import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';
import { EmptyPage, MobileEmptyPage } from '@/components/Table/components/EmptyPage';
import { FormsTable } from '@/components/Table/components/Table';
import TableCopyModal, { CopyErrors } from '@/components/Table/components/TableCopyModal';
import { TableFilters } from '@/components/Table/components/TableFilters';
import { TableHeader } from '@/components/Table/components/TableHeader';
import { TablePagination } from '@/components/Table/components/TablePagination';
import { TableSettings } from '@/components/Table/components/TableSettings';
import { baseFlippedProps, generateDesignerUrl } from '@/components/Table/helpers';
import { EntityStatuses, EntityTypes, FilterCategories, getRoute, OrderByTypes } from '@/components/Table/strings';

export const useIsMount = () => {
	const isMountRef = useRef(false);
	useEffect(() => {
		isMountRef.current = true;
	}, []);
	return isMountRef.current;
};

export const FormsTableContainer = ({
	activeEntity,
	allTags,
	analytics,
	archiveEntity: archiveEntityProp,
	archiveManyEntities: archiveManyEntitiesProp,
	changeEntityData,
	copyToEnvs,
	createEntity,
	deleteEntity: deleteEntityProp,
	deleteManyEntities: deleteManyEntitiesProp,
	duplicateEntity: duplicateEntityProp,
	entities,
	entityForm,
	formFilters,
	getAllEntities,
	getEntity: getEntityProp,
	getTags,
	getWebhooks,
	hasSelectedEntities,
	isFiltered,
	loaders,
	navigate,
	params,
	publishEntity: publishEntityProp,
	publishManyEntities: publishManyEntitiesProp,
	reassignWebhook,
	refreshEntity: refreshEntityProp,
	selectedEntities,
	selectedEntitiesIds,
	setManyTags,
	setTags,
	totalFilteredEntities,
	totalFilteredPagination,
	unarchiveEntity: unarchiveEntityProp,
	unarchiveManyEntities: unarchiveManyEntitiesProp,
	updateEntity,
	webhooks,
	assignWebhook: assignWebhookProp,
}) => {
	const [copyEntityIds, setCopyEntityIds] = useState([]);
	const isMounted = useIsMount();

	const pusher = useMemo(
		() =>
			new Pusher(config.pusher_key, {
				cluster: 'eu',
			}),
		[],
	);
	const channel = useMemo(() => pusher.subscribe('copy-to-envs'), [pusher]);
	const toast = useToast();

	const entityType = EntityTypes.form;
	const { type, route } = entityType;

	const pagination = parseInt(params.page, 10) || undefined;
	const filters = {
		...formFilters,
		pagination,
		routeParam: route,
		status: formFilters.status,
		type,
	};

	const totalTablePages = totalFilteredPagination;
	const order = formFilters.orderBy;
	const allSelected = entities.length ? entities.length === selectedEntitiesIds.length : false;

	const onClose = () => {
		const input = {
			activeEntity: undefined,
			entityForm: initialState.entityForm,
		};

		if (changeEntityData) changeEntityData(input);
	};

	const onChangeForm = (data) => {
		const input = {
			entityForm: {
				...entityForm,
				[data.type]: data.value,
			},
		};

		if (changeEntityData) changeEntityData(input);
	};

	const onSubmit = () => {
		if (activeEntity) {
			if (entityForm.webhookId) assignWebhook(entityForm.webhookId);
			updateEntity({
				formData: entityForm,
				activeEntity: activeEntity,
				callback: () => getEntities({ ...filters }),
			});
		} else {
			createEntity({ formData: entityForm, callback: (id, entity) => redirectToDesigner(id, entity) });
		}
	};

	const initiateCreateNew = () => {
		onChangeForm({ type: 'show', value: true });
	};

	const changeOrder = (orderBy) => {
		onFilterChange({ dataLabel: 'orderBy', value: orderBy });
	};

	const onTagsSave = (tags, entityId) => {
		const foundPage = findEntityById(entityId || '');

		if (foundPage) {
			setTags({ entityId: entityId || '', tags, type });
		}
	};

	const onTagsRemove = (data) => {
		const foundPage = findEntityById(data.itemId || '');

		if (foundPage) {
			const tags =
				foundPage.Tags &&
				foundPage.Tags.map((Tag) => {
					return Tag.Tag;
				});

			tags.splice(data.index, 1);

			setTags({ entityId: data.itemId || '', tags, type });
		}
	};

	const onTagClick = (tag) => {
		const newTags = [...formFilters.tags];

		if (newTags.includes(tag)) {
			const index = newTags.findIndex((item) => {
				return item === tag;
			});

			newTags.splice(index, 1);
		} else {
			newTags.push(tag);
		}

		onFilterChange({ dataLabel: 'tags', value: newTags });
	};

	const onSetManyTags = (tag, remove) => {
		const payloadTags = selectedEntities.map((entity) => {
			let newTags = [];

			if (remove) {
				const removedTags = entity.Tags.filter((entityTag) => {
					return entityTag.Tag !== tag;
				});
				newTags = removedTags.map((entityTag) => {
					return entityTag.Tag;
				});
			} else {
				newTags = entity.Tags.map((entityTag) => {
					return entityTag.Tag;
				});
				newTags.push(tag);
			}

			return {
				entityId: entity.Entity.Id,
				type,
				tags: newTags,
			};
		});

		setManyTags({ newTags: payloadTags, type });
	};

	const onFilterChange = (data) => {
		const filterType = FilterCategories[route];

		const input = {
			[filterType]: {
				...formFilters,
				...{ [data.dataLabel]: data.value },
			},
		};

		if (changeEntityData) changeEntityData(input);
	};

	const selectEntity = (data) => {
		if (data.dataLabel) {
			let input = {};

			if (data.value) {
				input = {
					selectedEntitiesIds: selectedEntitiesIds.concat(data.dataLabel),
				};
			} else {
				const indexOfSelected = selectedEntitiesIds.indexOf(data.dataLabel);
				const newEntities = [...selectedEntitiesIds];
				newEntities.splice(indexOfSelected, 1);
				input = {
					selectedEntitiesIds: newEntities,
				};
			}

			if (changeEntityData) changeEntityData(input);
		}
	};

	const selectAllEntities = (selectAll) => {
		const input = {
			selectedEntitiesIds: selectAll ? entities.map((entity) => entity.Entity.Id) : [],
		};

		if (changeEntityData) changeEntityData(input);
	};

	const getEntity = (entityId) => {
		getEntityProp(entityId);
	};

	const duplicateEntity = (entityId) => {
		duplicateEntityProp({ entityId, type, callback: () => getEntities({ ...filters }) });
	};

	const publishEntity = async (entityId) => {
		const entityRsp = await getEntityUtil(entityId, false);
		const jsonData = entityRsp.data?.Blueprints.JsonContent;
		const jsonDataParsed = jsonData && JSON.parse(jsonData);

		// LEGACY
		const content = jsonDataParsed?.newsletter ? jsonDataParsed?.newsletter : jsonDataParsed?.content;

		if (content && content.rows.length) {
			const updatedJson = updateTemplateJson(content);
			const { validity } = checkJsonValidity(updatedJson.rows, updatedJson.lastPage);

			if (validity.hasErrors) {
				return useModalStore.getState().showGeneralModal({
					title: 'Unable to activate due to errors',
					message: 'Form can’t be activated because it contains errors. Edit the form to fix the errors.',
					okLabel: 'Edit form',
					onOk: () => takeEntityAction('design', entityId),
				});
			}

			const onOk = () => {
				publishEntityProp({ entityId, type, callback: () => getEntities(filters) });
			};

			useModalStore.getState().showGeneralModal({
				title: 'Activate form?',
				message: `Are you sure you want to activate this Form? This action is not reversible.`,
				okLabel: 'Activate',
				onOk,
			});
		} else {
			showNotification({
				type: NotificationTypes.ERROR,
				text: 'Form is missing a design.',
			});
		}
	};

	const refreshEntity = (entityId) => {
		refreshEntityProp(entityId);
	};

	const publishManyEntities = () => {
		const payloadIds = [];
		const inactiveIds = [];
		const draftIds = [];
		const errorIds = [];

		selectedEntities.forEach((item) => {
			if (item.Entity.Status === EntityStatuses.inactive) {
				inactiveIds.push(item.Entity.Id);
			} else if (item.Entity.Status === EntityStatuses.draft) {
				draftIds.push(item.Entity.Id);
			}
		});

		if (draftIds.length) {
			showNotification({
				type: NotificationTypes.INFO,
				text: `Cannot activate draft form: design or webhook settings missing`,
			});
		}

		if (inactiveIds.length) {
			Promise.all(inactiveIds.map((item) => getEntityUtil(item))).then((resp) => {
				resp.forEach((entityRsp) => {
					const jsonData = entityRsp.data?.Blueprints.JsonContent;
					const jsonDataParsed = jsonData && JSON.parse(jsonData);

					// LEGACY
					const content = jsonDataParsed?.newsletter ? jsonDataParsed?.newsletter : jsonDataParsed?.content;

					const updatedJson = updateTemplateJson(content);
					const { validity } = checkJsonValidity(updatedJson.rows, updatedJson.lastPage);

					if (validity.hasErrors) {
						errorIds.push(entityRsp.data?.Entity.Id);
					} else {
						payloadIds.push(entityRsp.data?.Entity.Id);
					}
				});

				if (errorIds.length) {
					showNotification({
						type: NotificationTypes.ERROR,
						text: `Forms with design errors could not be activated`,
					});
				}

				if (payloadIds.length) {
					publishManyEntitiesProp({
						entityIds: payloadIds,
						type: filters.type,
						callback: () => getEntities(filters),
					});
				}
			});
		}
	};

	const deleteEntity = (entityId) => {
		const entity = entities.find((item) => {
			return item.Entity.Id === entityId;
		});

		if (entity) {
			deleteEntityProp({
				callback: () => getEntities({ ...filters }),
				entityId,
				name: entity.Entity.Name,
				type: filters.type,
			});
		}
	};

	const deleteManyEntities = () => {
		deleteManyEntitiesProp({
			entityIds: selectedEntitiesIds,
			type: filters.type,
			callback: () => getEntities({ ...filters }),
		});
	};

	const archiveEntity = (entityId) => {
		const entity = entities.find((item) => item.Entity.Id === entityId);

		if (entity)
			archiveEntityProp({
				callback: () => {
					getEntities({ ...filters });
					removeSelectedEntitiesIds();
				},
				entityId,
				name: entity.Entity.Name,
				isActive: entity.Entity.Status === 1,
			});
	};

	const archiveManyEntities = () => {
		const unarchivedEntities = selectedEntities.filter((entity) => !entity.Entity.Archived).map((entity) => entity.Entity.Id);

		if (!unarchivedEntities.length) return;

		archiveManyEntitiesProp({
			entityIds: unarchivedEntities,
			type: filters.type,
			callback: () => {
				getEntities({ ...filters });
				removeSelectedEntitiesIds();
			},
		});
	};

	const unarchiveEntity = (entityId) => {
		const entity = entities.find((item) => item.Entity.Id === entityId);

		if (entity)
			unarchiveEntityProp({
				callback: () => {
					getEntities({ ...filters });
					removeSelectedEntitiesIds();
				},
				entityId,
				name: entity.Entity.Name,
			});
	};

	const unarchiveManyEntities = () => {
		const archivedEntities = selectedEntities.filter((entity) => entity.Entity.Archived).map((entity) => entity.Entity.Id);

		if (!archivedEntities.length) return;

		unarchiveManyEntitiesProp({
			entityIds: archivedEntities,
			type: filters.type,
			callback: () => {
				getEntities({ ...filters });
				removeSelectedEntitiesIds();
			},
		});
	};

	const takeEntityAction = (action, entityId) => {
		switch (action) {
			case 'design':
				redirectToDesigner(entityId);
				break;

			case 'duplicate':
				duplicateEntity(entityId);
				break;

			case 'settings':
				getEntity(entityId);
				break;

			case 'delete':
				deleteEntity(entityId);
				break;

			case 'archive':
				archiveEntity(entityId);
				break;

			case 'unarchive':
				unarchiveEntity(entityId);
				break;

			case 'activate':
				publishEntity(entityId);
				break;

			case 'refresh':
				refreshEntity(entityId);
				break;

			case 'copy':
				setCopyEntityIds([entityId]);
				break;

			default:
				break;
		}
	};

	const takeManyAction = (action) => {
		switch (action) {
			case 'delete':
				deleteManyEntities();
				break;

			case 'publish':
				publishManyEntities();
				break;

			case 'archive':
				archiveManyEntities();
				break;

			case 'unarchive':
				unarchiveManyEntities();
				break;

			case 'copy':
				setCopyEntityIds(selectedEntitiesIds);
				break;

			default:
				break;
		}
	};

	const assignWebhook = (webhookId) => {
		const entityId = activeEntity.Entity.Id;
		if (activeEntity) {
			if (activeEntity.WebhookSettings) {
				reassignWebhook(webhookId, entityId);
			} else {
				assignWebhookProp(webhookId, entityId);
			}
		}
	};

	const redirectToDesigner = (entityId, entityArg) => {
		const entity = entityArg ? entityArg : entities.find((item) => item.Entity.Id === entityId);
		if (entity) {
			changePath(generateDesignerUrl(entityId, entity.Entity.Status === EntityStatuses.active));
		}
	};

	const onChangePagination = (e, pagination) => {
		changePath(getRoute.root(route, 'list', pagination));
	};

	const changePath = (path) => {
		navigate(path + `${location.search}`);
	};

	const findEntityById = (id) => {
		return (
			entities &&
			entities.find((entity) => {
				return entity.Entity.Id === id;
			})
		);
	};

	const getEntities = (payload) => {
		getAllEntities(payload, location.search);
	};

	const removeSelectedEntitiesIds = () => {
		const input = {
			selectedEntitiesIds: [],
		};

		if (changeEntityData) changeEntityData(input);
	};

	const copyEntityToEnv = (entityIds, selectedTenants) =>
		copyToEnvs(entityIds, selectedTenants, (eventName) => {
			const id = generateGuid();

			showNotification({ id, type: NotificationTypes.LOADING, text: 'Copying forms has started' });
			channel.bind(eventName, (data) => {
				setTimeout(() => {
					if (toast.isActive(id)) {
						toast.close(id);
					}

					if (data.FailedForms.length) {
						showNotification({
							type: NotificationTypes.WARNING,
							text: 'Some forms failed to copy:',
							description: <CopyErrors errors={data.FailedForms} />,
						});
					} else {
						showNotification({ type: NotificationTypes.SUCCESS, text: 'Copying forms completed successfully' });
					}
				}, 500);
			});

			setCopyEntityIds([]);
		});

	const getInitialData = () => {
		getTags(type);
		getWebhooks();

		getEntities({
			...formFilters,
			type,
			pagination,
			routeParam: route,
			withLoader: true,
		});
	};

	const getUpdatedEntities = ({ pagination = params.page, withLoader = false, isUpdating = true } = {}) => {
		getEntities({
			...formFilters,
			type,
			pagination,
			withLoader,
			isUpdating,
			routeParam: route,
		});
	};

	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(
		() => () => {
			// remove selected table rows
			if (selectedEntitiesIds.length) removeSelectedEntitiesIds();
			changeEntityData({ pageFilters: initialState.pageFilters, formFilters: initialState.formFilters });
		},
		[],
	);

	// Pagination or type changed
	useEffect(() => {
		if (!isMounted) return;
		if (selectedEntitiesIds.length) removeSelectedEntitiesIds();
		getUpdatedEntities();
	}, [params.page, params.type, formFilters.orderBy]);

	const stringifiedFilters = JSON.stringify(omit(formFilters, 'orderBy'));

	// All filters except name or sort changed
	useEffect(() => {
		if (!isMounted) return;
		getTags(type);
		getUpdatedEntities({ pagination: 1, withLoader: !entities.length, isUpdating: !!entities.length });
	}, [stringifiedFilters]);

	return (
		<Flipper spring="noWobble" flipKey={entities.map((item) => item.Entity.Id).join('-')}>
			<div>
				<TableSettings
					onClose={onClose}
					onChange={onChangeForm}
					onSubmit={onSubmit}
					takeEntityAction={takeEntityAction}
					activeEntity={activeEntity}
					entityForm={entityForm}
					webhooks={webhooks}
				/>
				<Box>
					<TableHeader
						loader={loaders.table}
						isFiltered={isFiltered}
						totalFilteredEntities={totalFilteredEntities}
						initiateCreateNew={initiateCreateNew}
					/>
					<Box p={[2.5, 2.5, 8, 8]} maxW={2000} m="0 auto">
						<Flipped flipId="forms-table-filters" {...baseFlippedProps}>
							<div>
								<TableFilters
									selectedEntities={selectedEntities}
									selectedEntitiesIds={selectedEntitiesIds}
									loaders={loaders}
									filters={formFilters}
									allTags={allTags}
									hasSelectedEntities={hasSelectedEntities}
									onSetManyTags={onSetManyTags}
									onFilterChange={onFilterChange}
									takeManyAction={takeManyAction}
									removeSelectedEntitiesIds={removeSelectedEntitiesIds}
								/>
							</div>
						</Flipped>

						{isFiltered && !entities.length && !loaders.table && (
							<Flipped flipId="no-filter-match" {...baseFlippedProps}>
								<div>
									<Text
										display="flex"
										justifyContent="center"
										my={8}
										fontSize="xl"
									>{`No ${entityType.labels.fullPlural} match your criteria`}</Text>
								</div>
							</Flipped>
						)}
						{!isFiltered && !entities.length && !loaders.general && (
							<>
								<Box display={['none', 'none', 'block', 'block']}>
									<Flipped flipId="forms-table-empty" {...baseFlippedProps}>
										<div>
											<EmptyPage title={`No ${entityType.labels.fullPlural} yet`} />
										</div>
									</Flipped>
								</Box>
								<MobileEmptyPage
									title={`No ${entityType.labels.fullPlural.toLowerCase()}. Log in to your desktop to create your ${entityType.labels.full.toLowerCase()}.`}
									src={entityType.images.empty}
								/>
							</>
						)}

						{(entities.length > 0 || loaders.table) && (
							<FormsTable
								loaders={{ table: loaders.table, tableDataUpdate: loaders.tableDataUpdate }}
								allSelected={allSelected}
								entities={entities}
								analytics={analytics}
								order={order}
								activeTags={formFilters.tags}
								selectedEntitiesIds={selectedEntitiesIds}
								allTags={allTags}
								changeOrder={changeOrder}
								onToggleAll={selectAllEntities}
								selectEntity={selectEntity}
								takeEntityAction={takeEntityAction}
								onTagsSave={onTagsSave}
								onTagsRemove={onTagsRemove}
								onTagClick={onTagClick}
							/>
						)}

						{Boolean(totalTablePages) && totalTablePages > 1 && (
							<Flipped flipId="forms-table-pagination" {...baseFlippedProps}>
								<TablePagination
									currentPage={pagination}
									totalPages={totalTablePages}
									onClick={onChangePagination}
									totalItems={totalFilteredEntities}
									showPageLabel
								/>
							</Flipped>
						)}
					</Box>
				</Box>

				<TableCopyModal
					isOpen={Boolean(copyEntityIds.length)}
					onClose={() => setCopyEntityIds([])}
					entityIds={copyEntityIds}
					onCopy={copyEntityToEnv}
				/>
			</div>
		</Flipper>
	);
};

const TableContainerWrapper = (props) => {
	const storeData = useEntitiesStore((state) => {
		const { formFilters } = state;
		return {
			...state,
			isFiltered:
				// formFilters.name.length >= 3 || formFilters.orderBy !== OrderByTypes[3] || !!formFilters.status || !!formFilters.tags.length,
				!!formFilters.name.length ||
				formFilters.orderBy !== OrderByTypes[3] ||
				!!formFilters.status.length ||
				!!formFilters.tags.length ||
				formFilters.includeArchived,
			hasSelectedEntities: state.selectedEntitiesIds.length > 0 ? true : false,
			selectedEntities:
				state.entities.filter((item) => {
					return state.selectedEntitiesIds.includes(item.Entity.Id);
				}) || [],
		};
	}, shallow);
	const navigate = useNavigateWithParams();
	const location = useLocation();
	const params = useParams();
	return <FormsTableContainer {...storeData} params={params} {...props} navigate={navigate} location={location} />;
};

export default TableContainerWrapper;
