import config from 'config/config';

import { claimsKeys, getUser } from '@/util/auth/auth';

const PocketNav = () => {
	const user = getUser();
	const environment = config.authentication.pocketNavEnv;
	const tenantName = user[claimsKeys.TENANT_NAME];
	const organizationId = user[claimsKeys.ORG_ID];

	return <xmc-pocket-nav environment={environment} tenant={tenantName} organization={organizationId} />;
};

export default PocketNav;
