import { Badge, Checkbox, IconButton, Tooltip, useBreakpointValue } from '@chakra-ui/react';

import useUserStore from '@/stores/UserStore';

import { truncateString } from '@/util/helper';

import { Icon } from '@/components/gui/shared/Icon';
import MoreMenu from '@/components/gui/shared/MoreMenu';
import LinkWithParams from '@/components/gui/shared/navigation/LinkWithParams';
import { EntityStatuses, SmallButtons } from '@/components/Table/strings';

import { TableLabels } from './TableLabels';

const RESPONSIVE_TRUNCATION_MAP = {
	md: 30,
	lg: 50,
	xl: 74,
};

export const EntitiesTableItemDetails = (props) => {
	const breakpoint = useBreakpointValue(['xs', 'sm', 'md', 'lg', 'xl']);

	const isActive = props.entity.Entity.Status === EntityStatuses.active;

	const designerUrl = `/design${isActive ? '/preview' : ''}?entityId=${props.entity.Entity.Id}`;

	return (
		<LinkWithParams
			to={designerUrl}
			className="notranslate"
			whiteSpace="nowrap"
			overflow="hidden"
			textOverflow="ellipsis"
			display="inline-block"
			data-testid={`entity-${props.entity.Entity.Id}-details`}
		>
			{truncateString(props.entity.Entity.Name, RESPONSIVE_TRUNCATION_MAP[breakpoint] ?? 40)}
		</LinkWithParams>
	);
};

export const EntitiesTableItemSelect = (props) => {
	const selectEntity = (e, data) => {
		if (props.selectEntity) props.selectEntity({ dataLabel: props.entity.Entity.Id, value: data });
	};

	return (
		<Checkbox
			isChecked={props.checked || false}
			onChange={(e) => selectEntity(e, e.target.checked)}
			data-testid="table-item-select-checkbox"
		/>
	);
};

export const EntitiesTableItemActions = (props) => {
	const tenants = useUserStore((state) => state.tenants);

	const takeEntityAction = (action) => {
		const entityId = props.entity.Entity.Id;

		if (props.takeEntityAction) props.takeEntityAction(action, entityId);
	};

	const isActive = props.entity.Entity.Status === EntityStatuses.active;
	const isDraft = props.entity.Entity.Status === EntityStatuses.draft;
	const isArchived = props.entity.Entity.Archived;

	return (
		<>
			{SmallButtons.map((button, i) =>
				button.additional.length ? (
					<MoreMenu
						key={button.action}
						actions={button.additional.map((action) => ({
							label: action.label || action.action,
							action: () => takeEntityAction(action.action.toLowerCase()),
							noRender:
								(action.action === 'Activate' && (isActive || isDraft || isArchived)) ||
								((action.action === 'Deactivate' || action.action === 'Refresh') && (!isActive || isArchived)) ||
								(action.action === 'Delete' && isActive) ||
								(action.action === 'Archive' && isArchived) ||
								(action.action === 'Unarchive' && !isArchived) ||
								(action.action === 'Duplicate' && isArchived) ||
								(action.action === 'Settings' && isArchived) ||
								(action.action === 'Copy' && (isArchived || tenants.length <= 1)),
						}))}
						dataTestId="table-item-more-menu"
					/>
				) : (
					<Tooltip label={button.action}>
						<IconButton
							aria-label={button.action}
							key={i}
							position="relative"
							icon={<Icon path={button.icon} />}
							onClick={() => takeEntityAction(button.action.toLowerCase())}
						/>
					</Tooltip>
				),
			)}
		</>
	);
};

const StatusColors = ['neutral', 'success', 'warning', 'neutral'];
const StatusText = ['Draft', 'Active', 'Inactive', 'Archived'];

export const EntitiesTableItemStatus = (props) => {
	const status = props.entity.Entity.Status;

	return (
		<Badge colorScheme={StatusColors[status]} data-testid={`entity-${props.entity.Entity.Id}-status`}>
			{StatusText[status]}
		</Badge>
	);
};

export const EntitiesTableItemLabels = (props) => {
	const currentTags =
		props.entity.Tags &&
		props.entity.Tags.map((entityTag) => {
			return entityTag.Tag;
		});

	return (
		<TableLabels
			itemId={props.entity.Entity.Id}
			currentTags={currentTags}
			activeTags={props.activeTags}
			allTags={props.allTags}
			onTagClick={props.onTagClick}
			onTagsRemove={props.onTagsRemove}
			onTagsSave={props.onTagsSave}
		/>
	);
};
