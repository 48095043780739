import { memo } from 'react';

import { Card, IconButton, SimpleGrid, Tooltip } from '@chakra-ui/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { FieldComponentTypes } from 'shared/src/utils/shared.js';

import { ConditionsActionTypeLabels, ConditionsActionTypes, LogicErrors } from '@/util/resources';

import { LogicAction } from '@/components/Conditions/models/model';
import { Icon } from '@/components/gui/shared/Icon';
import { IMultiSelectWithTagsDefaultOption, MultiSelectWithTags } from '@/components/gui/shared/MultiSelectWithTags';

export interface IActionProps {
	action: LogicAction;
	fieldOptions: IMultiSelectWithTagsDefaultOption[];
	onChangeAction: (action: LogicAction, actionIdx: number, actionValue: keyof typeof ConditionsActionTypes) => void;
	onChangeField: (action: LogicAction, actionIdx: number, fieldId: keyof typeof FieldComponentTypes) => void;
	onDeleteAction: (action: LogicAction) => void;
	isLast: boolean;
	index: number;
	disabled?: boolean;
	errors?: string[];
}

const options = Object.entries(ConditionsActionTypeLabels).reduce(
	(prev, [value, title]) => [
		...prev,
		{
			title,
			value,
		},
	],
	[],
);

const SingleAction: React.FC<IActionProps> = ({
	action,
	fieldOptions,
	onChangeAction,
	onChangeField,
	onDeleteAction,
	isLast,
	index,
	disabled,
	errors = [],
}) => (
	<Card variant="flat" display="flex" direction="row" p={2.5} alignItems="center" mb={isLast ? 0 : 2}>
		<SimpleGrid columns={2} gap={2} width="100%">
			<div data-testid="action-value-select">
				<MultiSelectWithTags
					options={options}
					placeholder="Select action"
					value={action.action}
					onChange={(data) => onChangeAction(action, index, data as keyof typeof ConditionsActionTypes)}
					disabled={disabled}
					hasError={errors.includes(LogicErrors.missingAction)}
				/>
			</div>

			{Boolean(action.action) && (
				<div data-testid="action-field-select">
					<MultiSelectWithTags
						options={fieldOptions}
						placeholder="Select field…"
						value={action?.field?.id || ''}
						onChange={(data) => onChangeField(action, index, data as keyof typeof FieldComponentTypes)}
						disabled={disabled}
						hasError={errors.includes(LogicErrors.invalidField)}
					/>
				</div>
			)}
		</SimpleGrid>
		<Tooltip label="Remove">
			<IconButton
				variant="ghostColorOnHover"
				icon={<Icon path={mdiTrashCanOutline} />}
				aria-label="Remove"
				mx={1.5}
				colorScheme="danger"
				size="sm"
				style={{ userSelect: 'none' }}
				onClick={() => onDeleteAction(action)}
				data-testid="actions-remove-btn"
				isDisabled={disabled}
			/>
		</Tooltip>
	</Card>
);

export const Action = memo(SingleAction);
