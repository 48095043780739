import { memo, useMemo } from 'react';

import { Alert, AlertDescription, AlertIcon, Box, Button, CircularProgress, Tooltip } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import useContentStore from '@/stores/ContentStore';

import { pathPrefix } from '@/util/helper';
import { ConditionalLogicTypes, FieldsNotAllowedAsCondition } from '@/util/resources';

import { EmptyPage } from '@/components/Table/components/EmptyPage';

import Criteria from './components/Criteria';
import Logic from './components/Logic';
import Condition from './Condition';
import Group from './Group';
import { IConditionField } from './models/model';
import { createBaseLogic } from './utils';

const ComponentByType = {
	criteria: Criteria,
	group: Group,
	condition: Condition,
	logic: Logic,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MainComponent = memo((props: any) => {
	const children = useMemo(() => {
		if (!props.settings) return null;

		return props.settings.map((item, index) => {
			return (
				<MainComponent
					{...item}
					key={item.id}
					index={index}
					fieldType={props.field?.type || item.field?.type}
					isFirst={index === 0}
					isLast={index === props.settings.length - 1}
					parentAddress={props.address}
					isGrouped={props.type === ConditionalLogicTypes.group}
				/>
			);
		});
	}, [props.address, props.settings, props.field?.type, props.type]);

	const Component = ComponentByType[props.type];
	return <Component {...props}>{children}</Component>;
});

export const Conditions = () => {
	const { logics, allFields, checkingValidity, logicsErrors, canBeModified } = useContentStore(
		useShallow((state) => ({
			logics: state.content.logics || [],
			allFields: state.allFields.filter((item: IConditionField) => !FieldsNotAllowedAsCondition.includes(item.type)) as IConditionField[],
			checkingValidity: state.checkingValidity as boolean,
			logicsErrors: state.logicsErrors,
			canBeModified: state.canBeModified,
		})),
	);

	if (checkingValidity)
		return (
			<Box
				w="100%"
				h="calc(100vh - 3.5rem)"
				mx="auto"
				display="flex"
				alignItems="center"
				justifyContent="center"
				data-testid="conditions-loader"
			>
				<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" id="initial-loader" />
			</Box>
		);

	const hasLogics = Boolean(logics.length);
	const hasFields = Boolean(allFields?.length);

	return (
		<Box backgroundColor="chakra-subtle-bg" h="calc(100vh - 3.5rem)" overflowY="scroll" pb="20">
			<Box boxSizing="content-box" maxW="7xl" py={7} px={20} mx="auto">
				<LogicsHeader hasLogics={hasLogics} hasFields={hasFields} hasError={Boolean(logicsErrors.length)} canBeModified={canBeModified} />
				{hasFields &&
					hasLogics &&
					logics.map((logic, index) => (
						<MainComponent {...logic} key={`logic-${index}`} index={index} address={index} logicErrors={logicsErrors[index]?.errors} />
					))}
			</Box>
		</Box>
	);
};

const LogicsHeader: React.FC<{ hasLogics: boolean; hasFields: boolean; hasError: boolean; canBeModified: boolean }> = memo(
	({ hasLogics, hasFields, hasError, canBeModified }) => {
		const location = useLocation();

		const handleCreate = () => {
			const tempLogics = [
				...useContentStore.getState().content.logics.map((logic) => ({ ...logic, isOpen: false })),
				{
					isOpen: true,
					...createBaseLogic(),
				},
			];

			useContentStore.getState().setLogics(tempLogics);
		};

		return (
			<Box>
				<Box display="flex" flexWrap="wrap" alignItems="center" m="0 auto">
					<Tooltip label={!hasFields ? 'Add fields to proceed' : ''}>
						<Button
							size="sm"
							onClick={handleCreate}
							ml="auto"
							mb={6}
							display={!canBeModified ? 'none' : 'flex'}
							isDisabled={!hasFields}
							colorScheme={!hasFields ? 'neutral' : 'primary'}
						>
							Add logic
						</Button>
					</Tooltip>
				</Box>

				{hasError && (
					<Alert status="error">
						<AlertIcon />
						<AlertDescription>Fix invalid conditions before saving form</AlertDescription>
					</Alert>
				)}

				{(!hasFields || !hasLogics) && (
					<EmptyPage
						title={hasFields ? 'No logic yet' : 'This form requires fields before adding logic'}
						description={!hasFields && 'Add fields to proceed.'}
						route={!hasFields && `${pathPrefix()}${location.search}`}
						label={!hasFields && 'Design form'}
					/>
				)}
			</Box>
		);
	},
);
