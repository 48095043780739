import React from 'react';

import { Tab, TabList, Tabs } from '@chakra-ui/react';

import useTemplateStore from '@/stores/TemplateStore';

const Categories = (props) => {
	const { selectCategory } = useTemplateStore();

	const categories = props.templateCategories
		.sort((a, b) => {
			if (a === 'All categories') return 1;
			if (b === 'All categories') return 1;
			if (a < b) return -1;
			if (a > b) return 1;

			return 0;
		})
		.map((category, i) => {
			return (
				<Tab key={i} cursor="pointer" justifyContent={'flex-start'} onClick={() => selectCategory(category)}>
					{category}
				</Tab>
			);
		});

	return (
		<Tabs orientation="vertical">
			<TabList>{categories}</TabList>
		</Tabs>
	);
};

export default Categories;
