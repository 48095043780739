import React from 'react';

import { Badge, Box, Button, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, Tooltip } from '@chakra-ui/react';
import {
	mdiArrowDecision,
	mdiArrowDecisionOutline,
	mdiArrowLeft,
	mdiChevronDown,
	mdiCog,
	mdiCogOutline,
	mdiCommentText,
	mdiCommentTextOutline,
	mdiDotsHorizontal,
	mdiEye,
	mdiEyeOutline,
	mdiHistory,
	mdiPencilRuler,
	mdiPencilRulerOutline,
} from '@mdi/js';
import config, { importedConfig } from 'config/config';
import { ErrorMessages, ModalMessages, SuccessMessages, WarningMessages } from 'config/messages';
import { useLocation } from 'react-router-dom';
import { getEntityId } from 'shared/src/utils/shared.js';
import { shallow } from 'zustand/shallow';

import useCommentStore from '@/stores/CommentStore';
import useContentStore from '@/stores/ContentStore';
import useEntitiesStore from '@/stores/EntitiesStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';
import useTemplateStore from '@/stores/TemplateStore';
import useUndoStore from '@/stores/UndoStore';

import strings from '@/resources/strings/en-us';
import { transform } from '@/util/apiUtils/entities';
import { loadInitialMoosendData } from '@/util/apiUtils/initialData';
import { getDuplicateFieldNames, getEditorType, pathPrefix, updateTemplateJson } from '@/util/helper';
import { emptyDefaultJson, Modals, ModalTypes, NotificationTypes } from '@/util/resources';

import { Icon } from '@/components/gui/shared/Icon';
import LinkWithParams from '@/components/gui/shared/navigation/LinkWithParams';
import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';
import { EntitiesTableItemStatus } from '@/components/Table/components/TableItems';
import { EntityStatuses } from '@/components/Table/strings';

import HelpSection from './HelpSection.react';
import SimpleTopMenuItem from './SimpleTopMenuItem.react';
import TopMenuItem from './TopMenuItem.react';

const TopMenuItems = [
	{ id: 'edit', iconOff: mdiPencilRulerOutline, iconOn: mdiPencilRuler, label: 'Design', href: '', condition: true },
	{
		id: 'comments',
		iconOff: mdiCommentTextOutline,
		iconOn: mdiCommentText,
		label: 'Comments',
		href: '/comment-mode',
		condition: true,
	},
	{ id: 'preview', iconOff: mdiEyeOutline, iconOn: mdiEye, label: 'Preview', href: '/preview', condition: true },
	// { id: 'code', iconOff: mdiCodeTags, iconOn: mdiCodeTags, label: 'Code', href: '/code-options', condition: true },
	{ id: 'versions', iconOff: mdiHistory, iconOn: mdiHistory, label: 'Versions', href: '/drafts', condition: true },
	// { id: 'script', iconOff: mdiScriptOutline, iconOn: mdiScript, label: 'Script', href: '/script-menu', condition: true },
	{
		id: 'conditions',
		iconOff: mdiArrowDecisionOutline,
		iconOn: mdiArrowDecision,
		label: 'Logic',
		href: '/conditions',
		condition: true,
	},
	{ id: 'settings', iconOff: mdiCogOutline, iconOn: mdiCog, label: 'Settings', href: '/settings', condition: true },
];

class TopMenu extends React.PureComponent {
	importExportJsonListener;

	/* istanbul ignore next */
	constructor(props) {
		super(props);

		this.importExportJsonListener = (e) => {
			// < key import json
			if (e.keyCode === 188 && e.ctrlKey && e.shiftKey) {
				e.preventDefault();
				this.onClickImportJson(e);
			}

			// > key export json
			if (e.keyCode === 190 && e.ctrlKey && e.shiftKey) {
				e.preventDefault();
				this.onClickExportJson(e);
			}

			if (e.keyCode === 191 && e.ctrlKey && e.shiftKey) {
				e.preventDefault();
				this.toggleProdSave();
			}
		};

		//Temporary for testing
		this.state = {
			dropdown: false,
			importDiv: false,
			exportDiv: false,
			hovered: 0,
			json: 'none',
		};
	}

	componentDidMount() {
		document.addEventListener('keydown', this.importExportJsonListener);
		useUndoStore.getState().setInitialState();
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.importExportJsonListener);
	}

	isPublishedEntity() {
		return this.props.activeEntity?.Entity.Status === EntityStatuses.active;
	}

	render() {
		let filteredTopMenuItems = [...TopMenuItems];

		if (this.isPublishedEntity()) {
			const availableTabs = this.props.canBeModified
				? ['edit', 'preview', 'code', 'conditions', 'settings']
				: ['preview', 'code', 'conditions'];

			filteredTopMenuItems = TopMenuItems.map((item) => {
				return {
					...item,
					condition: availableTabs.includes(item.id),
				};
			});
		}

		if (this.props.location.pathname === pathPrefix() + '/template-library') {
			const children = (
				<>
					<LinkWithParams to={pathPrefix()} getAllParams>
						<Tooltip label="Back">
							<IconButton aria-label="Back" variant={'ghost'} icon={<Icon path={mdiArrowLeft} />} />
						</Tooltip>
					</LinkWithParams>
					<Heading size="md" alignSelf="center" ml={2}>
						Templates
					</Heading>
				</>
			);
			return this.renderEmptyTopbar(children);
		}

		return (
			<Box
				h={14}
				w="100%"
				backgroundColor="chakra-subtle-bg"
				display="flex"
				position="relative"
				justifyContent="space-between"
				zIndex={1200}
				boxShadow="base"
				data-testid="editor-top-menu"
				px={5}
				flexShrink={0}
			>
				<Box display="flex">
					<Box borderColor="chakra-border-color" onClick={this.onClickExit} display="flex" alignItems="center" pr={2}>
						<Tooltip label="Back">
							<IconButton aria-label="Back" variant={'ghost'} icon={<Icon path={mdiArrowLeft} />} />
						</Tooltip>
					</Box>
					{this.props.activeEntity && (
						<Box display="flex" alignItems="center" flexWrap="wrap" gap={3} mr={5}>
							<Heading as="h1" size="md" maxW="xs" isTruncated>
								{this.props.activeEntity.Entity.Name}
							</Heading>
							<EntitiesTableItemStatus entity={this.props.activeEntity} />

							{this.props.activeEntity.Entity.Archived && <Badge variant="outline">Archived</Badge>}
						</Box>
					)}
				</Box>
				<Box display="flex" alignItems="center">
					{filteredTopMenuItems.map((item) => {
						if (item.condition) {
							return (
								<TopMenuItem
									active={this.props.location.pathname === pathPrefix() + item.href}
									href={item.href}
									label={item.label}
									icons={{ iconOn: item.iconOn, iconOff: item.iconOff }}
									key={item.href}
									count={item.href === '/comment-mode' ? this.props.unreadComments : undefined}
								/>
							);
						}
						return null;
					})}
				</Box>
				<Box alignItems="center" display="flex">
					{this.topMenuBlueButton()}
					{(import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') && this.extraActionsMenuButton()}

					<Box display="flex" alignItems="center" ml={2} h="100%">
						<HelpSection />
					</Box>
				</Box>
				{this.state.importDiv && (
					<Box
						position="fixed"
						p={2.5}
						border="1px solid black"
						zIndex={9999999}
						w="2xl"
						backgroundColor="#fff"
						top="12"
						bottom="12"
						left="50%"
						ml={-75}
						data-testid="import-div"
					>
						<textarea ref="oldJson" style={{ width: '100%', height: '95%' }} />
						<button style={{ height: '5%', marginRight: 10 }} onClick={this.onClickCloseImportJson}>
							Close
						</button>
						<button style={{ height: '5%' }} onClick={this.importOldJSON}>
							Import
						</button>
					</Box>
				)}

				{this.state.exportDiv && (
					<Box
						position="fixed"
						p={2.5}
						border="1px solid black"
						zIndex={9999999}
						w="2xl"
						backgroundColor="#fff"
						top="12"
						bottom="12"
						left="50%"
						ml={-75}
						data-testid="export-div"
					>
						<textarea readOnly ref="exportJson" style={{ width: '100%', height: '95%' }} value={this.state.json} />
						<button style={{ height: '5%', marginRight: 10 }} onClick={this.onClickCloseExportJson}>
							Close
						</button>
					</Box>
				)}
			</Box>
		);
	}

	renderEmptyTopbar = (children) => {
		return (
			<Box
				h={14}
				px={5}
				flexShrink={0}
				w="100%"
				backgroundColor="chakra-body-bg"
				display="flex"
				alignItems="center"
				boxShadow="base"
				zIndex={1}
			>
				{children}
			</Box>
		);
	};

	topMenuBlueButton = () => {
		const proceedItem = (
			<Box data-testid="top-menu-button">
				<Box display="flex">
					{this.isPublishedEntity() ? (
						<SimpleTopMenuItem
							type="one"
							label={this.props.canBeModified ? strings.push_active_form_changes : strings.edit_active_form_desing}
							onClick={this.props.canBeModified ? this.onClickPushActiveFormChanges : this.onClickEditActiveForm}
							buttonProps={{
								borderRadius: '100px 0 0 100px',
								isDisabled: this.props.canBeModified && !this.props.formModified,
							}}
						></SimpleTopMenuItem>
					) : (
						<SimpleTopMenuItem
							type="one"
							label={strings.proceed_to_next_step}
							onClick={this.onClickProceedToSave}
							buttonProps={{ borderRadius: '100px 0 0 100px' }}
						></SimpleTopMenuItem>
					)}

					<Menu
						isOpen={this.state.dropdown}
						onClick={(e) => {
							e.stopPropagation();
							this.setState({ dropdown: false });
						}}
						onClose={() => this.setState({ dropdown: false })}
					>
						{({ isOpen }) => (
							<>
								<MenuButton
									isActive={isOpen}
									as={Button}
									borderRadius="0 100px 100px 0"
									ml={'1px'}
									px={2}
									onClick={() => this.setState({ dropdown: !this.state.dropdown })}
									aria-label="More save options"
								>
									<Icon size={'6'} path={mdiChevronDown} />
								</MenuButton>
								<MenuList>
									{!this.isPublishedEntity() && (
										<>
											<MenuItem color="inherit" onClick={(e) => this.onClickProceedToSave(e, this.activateForm)}>
												Save and activate
											</MenuItem>
											<MenuItem color="inherit" onClick={this.onClickSaveDraft}>
												Save a version
											</MenuItem>
										</>
									)}

									<MenuItem color="inherit" onClick={this.onSaveTemplate}>
										Save as template
									</MenuItem>

									{this.isPublishedEntity() && this.props.canBeModified && (
										<MenuItem color="inherit" onClick={this.onClickCancelEditActiveForm}>
											Cancel edit
										</MenuItem>
									)}
								</MenuList>
							</>
						)}
					</Menu>
				</Box>
			</Box>
		);

		// const saveDraft = <SimpleTopMenuItem type="one" label={'Save and continue'} onClick={this.onSaveDraftAndContinue} />;

		return proceedItem;
	};

	extraActionsMenuButton = () => (
		<Menu>
			<MenuButton ml={2} as={IconButton} variant="outline" icon={<Icon path={mdiDotsHorizontal} />} data-testid="top-menu-extra-actions" />
			<MenuList>
				<MenuItem onClick={this.onClickImportJson}>Import JSON</MenuItem>
				<MenuItem onClick={this.onClickExportJson}>Export JSON</MenuItem>
			</MenuList>
		</Menu>
	);

	onMouseEnter = () => {
		this.setState({ hovered: true });
	};

	onMouseLeave = () => {
		this.setState({ hovered: false });
	};

	onClickProceedToSave = async (e, callback) => {
		e.preventDefault();

		if (this.props.hasErrors && callback) {
			showNotification({ type: NotificationTypes.ERROR, text: 'Clear form errors before activating.' });
			return;
		}

		if (!this.checkIfLogicsValid()) {
			return;
		}

		const content = useContentStore.getState().content;
		const isEmptyDesign = !content.rows.length;

		const duplicateFieldNames = getDuplicateFieldNames(content);

		if (duplicateFieldNames.length) {
			showNotification({
				type: NotificationTypes.ERROR,
				text: 'The form cannot be saved. Check the field names for duplicates.',
			});
			return;
		}

		const proceedToSend = async () => {
			let mainMessage = SuccessMessages.PROCEED_TO_SEND_DESIGNER('form');

			//display overlay while loading
			const editorOverlay = document.querySelector('#initial-overlay');
			const messageDiv = editorOverlay && editorOverlay.querySelector('#initial-error');
			if (messageDiv) messageDiv.innerHTML = mainMessage;

			let modalMessage;
			let okLabel = 'Activate';
			let title = 'Confirm';

			if (this.isPublishedEntity()) {
				title = 'Push changes';
				modalMessage = ModalMessages.PUSH_ACTIVE_FORM;
				okLabel = 'Push changes';
			} else if (callback) modalMessage = ModalMessages.PROCEED_TO_ACTIVATE_FORM('form');
			else modalMessage = ModalMessages.PROCEED_TO_SEND_DESIGNER('form');

			let list = [];
			let json = content || emptyDefaultJson[getEditorType()];
			// let html = beautifyHtml(transform(json));

			let html = await transform(JSON.stringify({ content: json }), useContentStore.getState().logicsErrors);

			let onOk = async () => {
				if (editorOverlay) editorOverlay.style.display = 'flex';

				/* istanbul ignore next */
				let successCb = () => {
					if (callback) callback();
					window.onbeforeunload = null; //this is to disable the onbeforeunload event listener defined in module.js because it is overkill
					let redirectUrl = importedConfig.query.redirectUrl;
					redirectUrl = redirectUrl.replace('placeholder-temporary-id', getEntityId(config));
					if (importedConfig.query.redirectUrl) this.props.navigate(redirectUrl);
					if (editorOverlay) editorOverlay.style.display = 'none';
				};

				/* istanbul ignore next */
				let errorCb = () => {
					showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.DRAFT_NOT_SAVED });

					if (editorOverlay) editorOverlay.style.display = 'none';
				};

				/* istanbul ignore next */
				let warningCb = () => {
					// showNotification({type: NotificationTypes.SUCCESS, text: WarningMessages.DRAFT_NOT_SAVED});
					window.onbeforeunload = null; //this is to disable the onbeforeunload event listener defined in module.js because it is overkill
					if (importedConfig.query.redirectUrl) this.props.navigate(importedConfig.query.redirectUrl);
					if (editorOverlay) editorOverlay.style.display = 'none';
				};

				const res = await useLeftSidebarStore.getState().saveNewDraft(
					{
						json,
						generalSettings: useContentStore.getState().general_settings,
					},
					true,
					this.props.latestDraft,
					successCb,
					errorCb,
					warningCb,
					html,
				);

				console.log(res);
			};

			let onCancel = () => {
				if (editorOverlay) editorOverlay.style.display = 'none';
			};

			if (callback && import.meta.env.MODE !== 'test')
				useModalStore.getState().showGeneralModal({
					modal: Modals.CONFIRM,
					message: modalMessage,
					onOk,
					title,
					okLabel,
					onCancel,
					list,
				});
			else await onOk();
		};

		const checkPreviewAndProceed = async () => {
			if (
				!useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.checkPreview &&
				this.props.location.pathname !== pathPrefix() + '/preview'
			) {
				useModalStore.getState().showDontAskModal({
					modal: Modals.INFO,
					// image: responsiveGif,
					type: 'checkPreview',
					message: ModalMessages.CHECK_PREVIEW,
					// message2: ModalMessages.RESPONSIVE_WARNING2,
					// list: config.responsive_email_clients,
					// ignoreCancelBtn: true,
					onCancel: async (dont_ask_again) => {
						if (dont_ask_again) useModalStore.getState().closeDontAskModal({ checkPreview: dont_ask_again });
						await proceedToSend();
					},
					onOk: () => this.props.navigate(pathPrefix() + '/preview' + this.props.location.search),
					okLabel: 'Take me to preview',
					cancelLabel: 'Proceed',
				});
			} else {
				await proceedToSend();
			}
		};

		if (isEmptyDesign && callback) {
			useModalStore.getState().showGeneralModal({
				modal: Modals.INFO,
				type: 'checkDesign',
				message: ModalMessages.NO_DESIGN,
				onOk: () => useModalStore.getState().closeGeneralModal(),
				okLabel: 'OK',
				ignoreCancelBtn: true,
			});
		} else if (this.props.activeEntity && !this.props.activeEntity.WebhookSettings && callback) {
			useModalStore.getState().showGeneralModal({
				modal: Modals.INFO,
				type: 'checkPreview',
				message: ModalMessages.ASSIGN_WEBHOOK,
				onOk: () => this.props.navigate(pathPrefix() + '/settings' + this.props.location.search),
				okLabel: 'Set up a webhook',
				ignoreCancelBtn: true,
			});
		} else {
			await checkPreviewAndProceed();
		}
	};

	onClickEditActiveForm = (e) => {
		e.preventDefault();

		useModalStore.getState().showGeneralModal({
			modal: Modals.CONFIRM,
			title: 'Edit form',
			okLabel: 'Edit',
			message: ModalMessages.EDIT_ACTIVE_FORM,
			onOk: () => {
				this.props.setCanBeModified(true);
				this.props.setFormModified(false);
				this.redirect('');
			},
		});
	};

	onClickPushActiveFormChanges = (e) => {
		e.preventDefault();

		if (!this.props.formModified) return;

		this.onClickProceedToSave(e, () => {
			this.props.setCanBeModified(false);
			this.props.setFormModified(false);
		});
	};

	onClickCancelEditActiveForm = (e) => {
		e.preventDefault();

		const onOk = async () => {
			this.props.setCanBeModified(false);
			this.props.setFormModified(false);
			await this.props.revertActiveForm();
			useUndoStore.getState().setInitialState();
			this.redirect('/preview');
		};

		if (import.meta.env.MODE !== 'test') {
			useModalStore.getState().showGeneralModal({
				modal: Modals.CONFIRM,
				title: 'Cancel edit',
				message: ModalMessages.CANCEL_EDIT_ACTIVE_FORM,
				okLabel: 'Cancel edits',
				cancelLabel: 'Keep editing',
				onOk,
			});
		} else {
			onOk();
		}
	};

	onClickExit = (e) => {
		e.preventDefault();

		const content = useContentStore.getState().content;

		//sad to have to put a settimeout here because of ckeditor saving
		setTimeout(() => {
			const entityId = getEntityId(config);

			let mainMessage = SuccessMessages.PROCEED_TO_SEND_DESIGNER('form');

			const editorOverlay = document.querySelector('#initial-overlay');

			let modalMessage = ModalMessages.EXIT_DESIGNER_AUTOSAVE('form');

			if (this.isPublishedEntity()) {
				if (editorOverlay) editorOverlay.style.display = 'flex';
				if (importedConfig.query.secondaryRedirectUrl) this.props.navigate(importedConfig.query.secondaryRedirectUrl);
				// editorOverlay && (editorOverlay.style.display = 'none');
				return;
			}

			const messageDiv = editorOverlay && editorOverlay.querySelector('#initial-error');
			if (messageDiv) messageDiv.innerHTML = mainMessage;

			let onCancel = () => {
				if (editorOverlay) editorOverlay.style.display = 'none';
			};

			let onOk = () => {
				if (editorOverlay) editorOverlay.style.display = 'flex';

				let successCb = () => {
					// showNotification({type: NotificationTypes.SUCCESS, text: SuccessMessages.DRAFT_AUTOSAVED});
					if (importedConfig.query.secondaryRedirectUrl) this.props.navigate(importedConfig.query.secondaryRedirectUrl);
					if (editorOverlay) editorOverlay.style.display = 'none';
				};

				let errorCb = () => {
					showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.DRAFT_NOT_AUTOSAVED });
					if (editorOverlay) editorOverlay.style.display = 'none';
				};

				let warningCb = () => {
					// showNotification({type: NotificationTypes.SUCCESS, text: WarningMessages.DRAFT_NOT_AUTOSAVED});
					if (importedConfig.query.secondaryRedirectUrl) this.props.navigate(importedConfig.query.secondaryRedirectUrl);
					if (editorOverlay) editorOverlay.style.display = 'none';
				};

				const contentStore = useContentStore.getState();
				if (entityId) {
					useLeftSidebarStore.getState().saveNewDraft(
						{
							json: content || emptyDefaultJson[getEditorType()],
							generalSettings: contentStore.general_settings,
						},
						true,
						this.props.latestDraft,
						successCb,
						errorCb,
						warningCb,
						null,
					);
				} else {
					if (importedConfig.query.secondaryRedirectUrl) this.props.navigate(importedConfig.query.secondaryRedirectUrl);
				}
			};

			useModalStore.getState().showGeneralModal({ modal: Modals.WARNING, message: modalMessage, onOk, onCancel });
		}, 200);
	};

	checkIfLogicsValid = () => {
		if (!this.props.checkLogicsValidity().isValid) {
			if (this.props.location.pathname !== `${pathPrefix()}/conditions`)
				useModalStore.getState().showGeneralModal({
					modal: Modals.ERROR,
					message: ModalMessages.INVALID_CONDITIONS,
					title: 'Invalid form conditions',
					okLabel: 'View invalid conditions',
					onOk: () => this.props.navigate(pathPrefix() + '/conditions' + this.props.location.search),
					ignoreCancelBtn: true,
				});

			return false;
		}

		return true;
	};

	redirect = (path) => {
		return this.props.navigate(pathPrefix() + path + this.props.location.search);
	};

	onClickSaveDraft = (e) => {
		e.preventDefault();

		let successCb = () => {
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.DRAFT_SAVED });
		};

		const duplicateFieldNames = getDuplicateFieldNames(useContentStore.getState().content);

		if (duplicateFieldNames.length) {
			showNotification({
				type: NotificationTypes.ERROR,
				text: 'The form cannot be saved. Check the field names for duplicates.',
			});
			return;
		}

		this.saveDraftFunc(successCb);
	};

	onSaveTemplate = () => {
		const duplicateFieldNames = getDuplicateFieldNames(useContentStore.getState().content);

		if (duplicateFieldNames.length) {
			showNotification({
				type: NotificationTypes.ERROR,
				text: 'The form cannot be saved. Check the field names for duplicates.',
			});
			return;
		}

		useModalStore.getState().showTemplateModal();
	};

	onSaveDraftAndContinue = (e) => {
		e.preventDefault();
		let successCb = () => {
			if (importedConfig.query.secondaryRedirectUrl) this.props.navigate(importedConfig.query.secondaryRedirectUrl);
		};

		this.saveDraftFunc(successCb);
	};

	saveDraftFunc = (successCb) => {
		let errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.DRAFT_NOT_SAVED });
		};

		let warningCb = () => {
			showNotification({ type: NotificationTypes.INFO, text: WarningMessages.DRAFT_NOT_SAVED });
		};

		const contentStore = useContentStore.getState();

		const jsonDraft = contentStore.content || emptyDefaultJson[getEditorType()];

		let imageData;

		const saveNewDraft = (successCb, errorCb, warningCb) => {
			useLeftSidebarStore.getState().saveNewDraft(
				{
					json: jsonDraft,
					generalSettings: contentStore.general_settings,
				},
				false,
				this.props.latestDraft,
				successCb,
				errorCb,
				warningCb,
				null,
				imageData,
			);
		};

		saveNewDraft(successCb, errorCb, warningCb);
	};

	onClickExportJson = (e) => {
		e.preventDefault();
		const json = {
			json: useContentStore.getState().content,
		};
		this.setState({ exportDiv: true, json: JSON.stringify(json) });
	};

	onClickCloseExportJson = (e) => {
		e.preventDefault();
		this.setState({ exportDiv: false });
	};

	onClickImportJson = (e) => {
		e.preventDefault();
		this.setState({ importDiv: true });
	};

	onClickCloseImportJson = (e) => {
		e.preventDefault();
		this.setState({ importDiv: false });
	};

	importOldJSON = () => {
		let textarea = this.refs.oldJson;
		let json = {};

		try {
			json = JSON.parse(textarea.value);
		} catch (e) {
			console.error(e);
			showNotification({ type: 'ERROR', text: 'INVALID JSON' });
		}

		const contentStore = useContentStore.getState();

		contentStore.setContentJson(updateTemplateJson(json.json, json.mobile));

		this.setState({ importDiv: 'none' });
		textarea.value = '';
	};

	// toggleProdSave = () => {
	// 	const type = useLeftSidebarStore.getState().allowProdSave ? 'disabled' : 'enabled';

	// 	this.props.toggleProdSave();

	// 	showNotification({ type: NotificationTypes.SUCCESS, text: `Saving Templates and Drafts is ${type}!` });
	// };

	/* istanbul ignore next */
	toggleProdSave = () => {
		const type = useLeftSidebarStore.getState().allowProdSave ? 'disabled' : 'enabled';

		this.props.toggleProdSave();

		showNotification({
			type: NotificationTypes.SUCCESS,
			text: `Designer mode is ${type}`,
			// text: `Saving public Templates, Drafts and Blocks is ${type}!`
		});
	};

	activateForm = () => {
		const { activeEntity } = this.props;
		this.props.publishEntity({ entityId: activeEntity.Entity.Id, type: activeEntity.Entity.Type });
	};
}

const TopMenuWrapper = (props) => {
	const unreadComments = useCommentStore((state) => state.unreadComments);

	const entitiesData = useEntitiesStore((state) => {
		return {
			activeEntity: state.activeEntity,
			publishEntity: state.publishEntity,
			revertActiveForm: () => loadInitialMoosendData(),
		};
	}, shallow);

	const templatesData = useTemplateStore((state) => {
		return { templates: state.templatesMetadata };
	}, shallow);

	const leftSidebarData = useLeftSidebarStore((state) => {
		return {
			showHelpSection: state.showHelpSection,
			toggleProdSave: state.toggleProdSave,
			latestDraft: state.versions && state.versions.latestDraft,
		};
	}, shallow);

	const contentData = useContentStore((state) => {
		return {
			rowsLength: state.content.rows.length,
			templateId: state.content.templateId,
			hasErrors: state.validity.hasErrors,
			checkLogicsValidity: state.checkLogicsValidity,
			canBeModified: state.canBeModified,
			setCanBeModified: state.setCanBeModified,
			formModified: state.formModified,
			setFormModified: state.setFormModified,
		};
	}, shallow);

	const navigate = useNavigateWithParams();
	const location = useLocation();

	return (
		<TopMenu
			unreadComments={unreadComments}
			{...contentData}
			{...leftSidebarData}
			{...templatesData}
			{...entitiesData}
			{...props}
			navigate={navigate}
			location={location}
		/>
	);
};

export default TopMenuWrapper;
