import React, { useReducer, useRef } from 'react';

import {
	Box,
	Button,
	ButtonGroup,
	Tooltip as ChakraTooltip,
	FormControl,
	FormLabel,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Switch,
	Text,
	UnorderedList,
} from '@chakra-ui/react';
import { mdiAlertCircle, mdiArrowRight, mdiDatabaseOutline, mdiLinkVariant, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DropTarget } from 'react-dnd';

import itemTypes from '@/util/itemTypes';

import { Tooltip } from '@/components/gui/content/helper/Tooltip.react';
import { Icon } from '@/components/gui/shared/Icon';

import FormInputOption from './FormInputOption.react';
import { settingsLabelStyle } from './Settings.styles';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

class FormInputOptions extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isMapOptionsModalOpen: false,
		};
	}

	render() {
		return (
			<Box
				mb={5}
				className="text-input-setting-container"
				borderTopWidth={1}
				borderTopStyle="solid"
				borderTopColor="chakra-border-color"
				borderBottomWidth={1}
				borderBottomStyle="solid"
				borderBottomColor="chakra-border-color"
				display={'flex'}
				gap={2}
				py={2}
				flexDirection={'column'}
			>
				{this.props.label && (
					<FormLabel {...settingsLabelStyle}>
						<Box display="inline-flex" pos="relative">
							{this.props.label}
							<Tooltip>Enabling custom values lets you specify what to send to the webhook instead of the option text.</Tooltip>
						</Box>
					</FormLabel>
				)}
				<Box display="flex" justifyContent="space-between">
					<FormControl display="flex" alignItems="center">
						<FormLabel htmlFor="toggle-custom-values" mb="0">
							Custom values
						</FormLabel>
						<Switch id="toggle-custom-values" isChecked={this.props.hasCustomValues} onChange={this.toggleCustomValues} />
					</FormControl>

					<ChakraTooltip label="Map this field to a data source">
						<IconButton
							aria-label="Map field to data source"
							variant="ghost"
							colorScheme="neutral"
							size="sm"
							icon={<Icon path={mdiDatabaseOutline} />}
							onClick={() => {
								this.setState((state) => ({ ...state, isMapOptionsModalOpen: true }));
							}}
						/>
					</ChakraTooltip>

					<MapOptionsModal
						isOpen={this.state.isMapOptionsModalOpen}
						onClose={() => {
							this.setState((state) => ({ ...state, isMapOptionsModalOpen: false }));
						}}
						onSave={this.onMapOptions}
					/>
				</Box>
				<Box>
					{this.props.options && this.props.options.length ? (
						<DragDropContext onDragEnd={this.onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided) => (
									<Box {...provided.droppableProps} ref={provided.innerRef} display="flex" gap={2} flexDirection="column">
										{this.props.options.map((item, i) => {
											return (
												<Draggable key={`item-${i}`} draggableId={`item-${i}`} index={i}>
													{(provided) => (
														<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															<FormInputOption
																{...item}
																hasCustomValues={this.props.hasCustomValues}
																index={i}
																onRemoveOptions={this.onRemoveOptions}
																onChangeInput={this.onChangeInput}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</Box>
								)}
							</Droppable>
						</DragDropContext>
					) : (
						<Text p={2.5} display="flex" alignItems="center" justifyContent="center">
							No options available
						</Text>
					)}
				</Box>

				<Box display="flex" justifyContent={this.props.options && this.props.options.length ? 'space-evenly' : 'center'} mt={2}>
					<Button variant="ghost" colorScheme="primary" leftIcon={<Icon path={mdiPlus} />} onClick={this.onAddOption}>
						Add option
					</Button>
					{this.props.options && this.props.options.length ? (
						<Button variant="ghost" colorScheme="primary" leftIcon={<Icon path={mdiTrashCanOutline} />} onClick={this.onClearOptions}>
							Delete options
						</Button>
					) : null}
				</Box>
			</Box>
		);
	}

	toggleCustomValues = (e) => {
		if (this.props.onChange) this.props.onChange({ type: 'hasCustomValues', value: e.target.checked });
		if (!e.target.checked) {
			const { options } = this.props;
			const optionsArray = [...options].map((item) => ({ label: item.label, value: item.label }));
			if (this.props.onChange) this.props.onChange({ type: 'options', value: optionsArray });
		}
	};

	onDragEnd = (result) => {
		const { options } = this.props;
		let optionsArray = [...options];

		if (!result.destination) {
			return;
		}

		optionsArray = reorder(optionsArray, result.source.index, result.destination.index);
		if (this.props.onChange) this.props.onChange({ type: 'options', value: optionsArray });
	};

	onRemoveOptions = (index) => {
		const { options } = this.props;
		const optionsArray = [...options];
		optionsArray.splice(index, 1);
		if (this.props.onChange) this.props.onChange({ type: 'options', value: optionsArray });
	};

	onChangeInput = (value, type, index) => {
		const { options } = this.props;
		const optionsArray = [...options];
		const newValue = this.props.hasCustomValues ? { ...options[index], [type]: value } : { label: value, value };
		optionsArray.splice(index, 1, newValue);
		if (this.props.onChange) this.props.onChange({ type: 'options', value: optionsArray });
	};

	onAddOption = () => {
		const option = {
			value: `Option ${this.props.options.length + 1}`,
			label: `Option ${this.props.options.length + 1}`,
		};
		const newOptions = [...this.props.options, option];
		if (this.props.onChange) this.props.onChange({ type: 'options', value: newOptions });
	};

	onClearOptions = () => {
		if (this.props.onChange) this.props.onChange({ type: 'options', value: [] });
	};

	onMapOptions = (newOptions) => {
		if (this.props.onChange) this.props.onChange({ type: 'hasCustomValues', value: true });
		if (this.props.onChange) this.props.onChange({ type: 'options', value: newOptions });
	};
}

const mapOptionsReducer = (state, action) => {
	switch (action.type) {
		case 'INIT':
			return {
				isLoading: false,
				hasErrors: false,
				options: [],
			};
		case 'FETCH_STARTED':
			return {
				isLoading: true,
				hasErrors: false,
				options: [],
			};
		case 'FETCH_SUCCESS':
			return {
				isLoading: false,
				hasErrors: false,
				options: action.payload,
			};
		case 'FETCH_ERROR':
			return {
				isLoading: false,
				hasErrors: true,
				options: [],
			};
		default:
			return state;
	}
};

const MapOptionsModal = ({ isOpen, onClose, onSave }) => {
	const inputRef = useRef(null);
	const [state, dispatch] = useReducer(mapOptionsReducer, {
		isLoading: false,
		hasErrors: false,
		options: [],
	});

	const onCloseModal = () => {
		dispatch({ type: 'INIT' });

		onClose();
	};

	const onApiUrlCheck = (apiUrl) => {
		dispatch({ type: 'FETCH_STARTED' });

		fetch(apiUrl)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Response was not ok');
				} else {
					const contentType = response.headers.get('content-type');

					if (contentType && contentType.includes('application/json')) {
						return response.json();
					} else {
						throw new Error('Response is not JSON');
					}
				}
			})
			.then((json) => {
				const isValidJson =
					Array.isArray(json) &&
					json.length > 0 &&
					json.every(
						(item) =>
							item &&
							typeof item === 'object' &&
							((item.value && typeof item.value === 'string' && item.label && typeof item.label === 'string') ||
								(item.value && typeof item.value === 'string' && !Object.prototype.hasOwnProperty.call(item, 'label')) ||
								(item.label && typeof item.label === 'string' && !Object.prototype.hasOwnProperty.call(item, 'value'))),
					);

				if (isValidJson) {
					dispatch({
						type: 'FETCH_SUCCESS',
						payload: json.map((item) => {
							return {
								label: item.label || item.value,
								value: item.value || item.label,
							};
						}),
					});
				} else {
					throw new Error('Response is JSON but has invalid schema');
				}
			})
			.catch(() => {
				dispatch({ type: 'FETCH_ERROR' });
			});
	};

	return (
		<Modal id="map-options" isOpen={isOpen} onClose={onCloseModal} size="2xl">
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>Prefill fields values</ModalHeader>

				<ModalCloseButton />

				<ModalBody>
					<UnorderedList mb={4}>
						<ListItem>Prefill your fields’ labels and values using data stored as a JSON array on a publicly available URL</ListItem>
						<ListItem>
							The JSON format should be:
							<Text fontStyle="italic" color="chakra-subtle-text">
								{'[{ "value": "option1", "label": "Option 1" }, { "value": "option2", "label": "Option 2" }]'}
							</Text>
						</ListItem>
					</UnorderedList>

					<FormControl>
						<FormLabel>URL</FormLabel>

						<InputGroup>
							<InputLeftElement>
								<Icon path={mdiLinkVariant} color="gray" />
							</InputLeftElement>
							<Input
								ref={inputRef}
								title="API URL"
								{...(!state.isLoading &&
									state.hasErrors && {
										borderColor: 'danger',
										borderWidth: 2,
									})}
								data-testid="api-url-input"
							/>
							<InputRightElement>
								<IconButton
									aria-label="Fetch options from the api url"
									variant="ghost"
									colorScheme="neutral"
									size="sm"
									icon={<Icon path={mdiArrowRight} />}
									onClick={() => {
										onApiUrlCheck(inputRef.current.value);
									}}
								/>
							</InputRightElement>
						</InputGroup>
					</FormControl>

					{state.isLoading && (
						<Box mt={3} display="flex" gap={3} justifyContent="center" color="info" data-testid="api-url-check-msg">
							<Spinner /> Checking API
						</Box>
					)}

					{!state.isLoading && !state.hasErrors && state.options.length > 0 && (
						<Box mt={1} color="success" data-testid="api-url-check-msg">
							Success! The URL you entered is returning data
						</Box>
					)}

					{!state.isLoading && state.hasErrors && (
						<Box mt={1} display="flex" gap={1} alignItems="center" color="danger" data-testid="api-url-check-msg">
							<Icon path={mdiAlertCircle} boxSize={4} />
							The URL you provided did not return data in the expected format
						</Box>
					)}
				</ModalBody>

				<ModalFooter>
					<ButtonGroup>
						<Button variant="ghost" colorScheme="blackAlpha" onClick={onCloseModal}>
							Cancel
						</Button>

						<Button
							isDisabled={!state.options.length}
							onClick={() => {
								onSave(state.options);
								onCloseModal();
							}}
						>
							Save
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

const optionTarget = {
	drop(props, monitor) {
		const sourceObj = monitor.getItem();
		return {
			option: sourceObj,
		};
	},
};

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		canDrop: monitor.canDrop(),
		draggedField: monitor.getItem(),
	};
}

export default DropTarget(itemTypes.FORM_FIELD_OPTION, optionTarget, collect)(FormInputOptions);
