import config from './config';

export const ModalMessages = {
	RESTORE_DRAFT: 'Are you sure you want to restore this draft? Unsaved changes could be lost.',
	SEND_TEST: 'Are you sure you want to send a test email to these addresses?',
	EXIT_DESIGNER_AUTOSAVE: (type) => `Are you sure you want to exit the ${type}?`,
	PROCEED_TO_SEND_DESIGNER: (type) =>
		`The current design will be autosaved and your ${type} content will be updated. Do you wish to proceed?`,
	PROCEED_TO_ACTIVATE_FORM: () =>
		`As soon as you activate your Form you won’t be able to edit the design. In order to change the design you should go to Dashboard and duplicate the form.`,
	CLEAR_DESIGN: 'Are you sure you want to clear this design?',
	DELETE_TEMPLATE: 'Are you sure you want to delete this template?',
	SET_TEMPLATE_DESIGNER: () => `Using this template will erase all changes you made on your form until now.`,
	REMOVE_ROW: 'Are you sure you want to delete this layout?',
	REMOVE_PAGE: 'Are you sure you want to delete this page?',
	REMOVE_ELEMENT: 'Are you sure you want to delete this item?',
	BORDER_RADIUS_SETTING: 'The border-radius(rounded corners) CSS property is not supported by most Outlook email clients.',
	RESPONSIVE_WARNING1:
		'Enabling this option will display the slots of a row vertically for small width devices, in email clients which support the use of media queries.',
	RESPONSIVE_WARNING2: 'These email clients are:',
	RESPONSIVE_WARNING3:
		'Unchecking this option will display the slots of a row vertically for small width devices, in email clients which support the use of media queries.',
	ROW_BACKGROUND_IMAGE_OUTLOOK: 'Background images for rows are not supported on most Outlook email clients.',
	REMOVE_CUSTOM_ROW: 'Are you sure you want to delete your custom layout?',
	REMOVE_CUSTOM_ELEMENT: 'Are you sure you want to delete your custom item?',
	MISSING_UNSUBSCRIBE_LINK: 'Detected no Unsubscribe link. One will be added to your sent Campaign.',
	MISSING_WEBSITE: 'We couldn’t find a website selected for your dynamic block. This is critical and must be amended.',
	NO_WEBSITES: 'You should not use product blocks without website tracking.',
	NO_WEBSITES_GO_TO_AUTOMATIONS:
		'In order to use layout types that need to track the visitors’ behavior in your website you need to install Moosend’s website tracking script. No worries though! It’s really a piece of cake!',
	TEXT_LENGTH_SMALL_ROW: 'Lengthy wording in text items and buttons could be displayed wrapped in small devices.',
	WIDE_ROW_BACKGROUND: 'Full-width background will appear like normal width on native email app of iPad Pro',
	JPG_WARNING: `JPG is a compressed image format and may result in color loss. PNG format is recommended instead.`,
	NON_EMBED_VIDEO: `Non YouTube or Vimeo videos will be opened in another page.`,
	MOBILE_PADDING: (type) => `When using plenty of padding to your desktop ${type} we suggest using less padding to the mobile version.`,
	CHECK_PREVIEW: `Would you like to preview the page before proceeding to save the current design?`,
	ASSIGN_WEBHOOK: `Form is missing Submission Webhook, you can go to Settings to add it.`,
	REQUIRED_WEBHOOK: `A webhook is required to submit the form.`,
	CONFIRM_WEBHOOK: `Since your form is active, are you sure you want to change the webhook?`,
	OUTLOOK_BORDER: `Border is not supported and will not appear in Outlook clients`,
	STRUCTURE_WIDTH: `The selected width may not be supported by all email clients.`,
	CUSTOM_FONT_SUPPORT: (fonts) =>
		`You have used the following Google Web Font (${fonts}). Not all email clients will support custom fonts.`,
	CUSTOM_FONT_SUPPORT2: `The email clients that will support custom fonts are:`,
	RETINA_WARNING: `Using retina-quality images will result in more bandwidth usage for your recipients and on occasion longer loading times for your images.`,
	NO_DESIGN: 'Form is missing design, you can go to Edit tab to add it.',
	FIELD_HAS_CONDITION: 'Delete field',
	INVALID_CONDITIONS: 'Form can’t be saved. Fix invalid conditions before saving form.',
	DELETE_MODAL_WITH_LOGIC: (type) =>
		`Deleting this ${type} also deletes the associated logic. Are you sure you want to delete this ${type}?`,
	CLEAR_DESIGN_WITH_LOGIC: `Clearing this design also deletes the associated logic. Are you sure you want to clear this design?`,
	DELETE_PAGE_TITLE: 'Delete page',
	DELETE_LAYOUT_TITLE: 'Delete layout',
	EDIT_ACTIVE_FORM: `Are you sure you want to edit this active form? The changes you make will be visible to the users of the form if the form is live.`,
	CANCEL_EDIT_ACTIVE_FORM: `Are you sure you want to cancel the edits made on this active form?`,
	PUSH_ACTIVE_FORM:
		'Are you sure you want to push changes made on this active form? The changes will be visible by the users of the form if the form is live.',
};

export const ErrorMessages = {
	SVG_NOT_SUPPORTED: 'SVG images are not supported',
	ACTION_FAILED: 'Error: Action failed',
	DRAFT_NOT_SAVED: 'Error: Draft not saved',
	DRAFT_NOT_AUTOSAVED: 'Error: Draft not autosaved',
	DRAFT_NOT_EXIST: 'Error: Draft does not exist',
	DRAFT_NOT_LOADED: 'Error: Draft could not be loaded',
	TEMPLATE_NOT_SAVED: 'Error: Template not saved',
	TEMPLATE_NOT_LOADED: 'Error: Template not loaded',
	TEMPLATE_NOT_TRANSFERRED: 'Error: Template not transferred',
	TEMPLATE_NOT_REMOVED: 'Error: Template not removed',
	TEMPLATES_MORE_NOT_LOADED: 'Could not load more templates',
	TEMPLATE_NOT_SAVED_LIMIT_EXCEDED: 'Error: Cannot have more than {template_limit} custom templates',
	TEST_NOT_SENT: 'Error: Test not sent',
	SEND_TEST_LIMIT: 'Can not add more than {send_test_limit} emails', //values in parantheses are replaced with actual values
	SEND_TEST_INCLUDED: 'Error: Email already included',
	SEND_TEST_INVALID: 'Error: Email not valid or empty',
	URL_NOT_IMAGE: 'URL is invalid or is not an image URL',
	IMAGE_NOT_UPLOADED: 'Error: Image not uploaded',
	INITIAL_LOAD_SCREEN_GENERAL: 'Something went wrong.<br>If the problem persists, please contact our support.',
	MOOSEND_DATA_NOT_LOADED: 'Failed to load all data.',
	INITIAL_LOAD_SCREEN_BROWSER_NOT_SUPPORTED:
		'Unfortunately your browser is not supported. <br> Use Chrome, Firefox, Edge, Safari 10+ or Opera 30+ to access the Campaign Editor.',
	PREVIEW_ERROR: 'Error during Preview',
	CUSTOM_ROW_NOT_SAVED: 'Error: Layout not saved',
	CUSTOM_ELEMENT_NOT_SAVED: 'Lengthy wording in text items and buttons could be displayed wrapped in small devices.',
	CUSTOM_ROW_NOT_REMOVED: 'Error: Custom layout not removed',
	CUSTOM_ELEMENT_NOT_REMOVED: 'Error: Custom item not removed',
	INTERNET_CONNECTION_DOWN: 'Error: Lost internet connection',
	TIMER_NOT_LOADED: 'Error: Your generated timer could not be loaded',
	WRONG_SLOT: 'Error: Item does not fit in that slot type',
	INVALID_VIDEO_URL: 'Error: Not a valid YouTube or Vimeo URL',
	INVALID_URL: 'Error: Not a valid URL',
};

export const SuccessMessages = {
	DRAFT_SAVED: 'A new version was created',
	DRAFT_AUTOSAVED: 'Draft autosaved',
	TEMPLATE_SAVED: 'Template saved',
	TEMPLATE_LOADED: 'Template loaded',
	TEMPLATE_TRANSFERRED: 'Template transferred',
	TEMPLATE_REMOVED: 'Template removed',
	TEMPLATES_MORE_NOT_LOADED: 'There are no more templates',
	IMAGE_UPLOADED: 'Image uploaded successfully',
	TEST_SENT: 'Test sent',
	CUSTOM_ROW_SAVED: 'Layout saved successfully',
	CUSTOM_ELEMENT_SAVED: 'Item successfully saved',
	CUSTOM_ROW_REMOVED: 'Custom layout removed successfully',
	CUSTOM_ELEMENT_REMOVED: 'Custom item removed successfully',
	PROCEED_TO_SEND: 'Saving your content’s design progress. This may take a moment.',
	PROCEED_TO_SEND_DESIGNER: (type) => `Saving your ${type}’s design progress. This may take a moment.`,
};

export const WarningMessages = {
	DRAFT_NOT_SAVED: 'No need to save, content is the same as last save',
	DRAFT_NOT_AUTOSAVED: 'No need to save, content is the same as last save',
	TEMPLATES_MORE_NOT_LOADED: 'There are no more templates',
	DEFAULT_ACCOUNT_SAVE_ERROR: 'Warning: This function is disabled in this mode',
	TEMPLATE_NOT_FOUND: 'Template not found',
	OLD_JSON_CORRUPTED: 'Old content content is corrupted, but you can continue making a new one',
	CUSTOM_ROW_NO_TITLE: `Layout name can’t be empty`,
	CUSTOM_ELEMENT_NO_TITLE: `Item name can’t be empty`,
	PAST_DATE: 'Trying to select a past date',
	TEMPLATE_LOAD_ERROR: 'Error loading template',
	WRONG_IMAGE_FORMAT: `Only ${config.accepted_image_formats.join(', ')} type images are allowed`,
};
