import { useEffect } from 'react';

import { NavigateFunction } from 'react-router-dom';

import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';

export interface RouteWrapperProps extends React.PropsWithChildren {
	onEnter?: (navigate: NavigateFunction) => void;
	onLeave?: (navigate: NavigateFunction) => void;
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({ onEnter, onLeave, children }) => {
	const navigate = useNavigateWithParams();

	useEffect(() => {
		if (typeof onEnter === 'function') onEnter(navigate as NavigateFunction);

		return () => {
			if (typeof onLeave === 'function') onLeave(navigate as NavigateFunction);
		};
	}, []);

	return children;
};
