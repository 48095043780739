import React from 'react';

import { Box, Button, ModalBody, ModalFooter, Text } from '@chakra-ui/react';

import useModalStore from '@/stores/ModalStore';

import { Modals, ModalTypes } from '@/util/resources';

import Modal from '@/components/gui/shared/modals/Modal.react';

const GeneralModal = ({ show, data, title = '' }) => {
	const close = () => {
		if (data.onCancel) data.onCancel();
		useModalStore.getState().closeGeneralModal();
	};

	const ok = () => {
		if (data.onOk) data.onOk();
		useModalStore.getState().closeGeneralModal();
	};

	const list = data.list?.map((item, i) => (
		<Text mb={1.5} as="li" key={i}>
			- {item}
		</Text>
	));

	return (
		<Modal display={show} title={title ?? data.title} modal={data.modal || Modals.CONFIRM} modalType={ModalTypes.GENERAL_MODAL}>
			<ModalBody>
				{data.message && <Text>{data.message}</Text>}
				{list && list.length ? <Box>{list}</Box> : null}
			</ModalBody>
			<ModalFooter gap="2">
				{!data.ignoreCancelBtn && (
					<Button variant="ghost" onClick={close}>
						{data.cancelLabel || 'Cancel'}
					</Button>
				)}
				<Button colorScheme={data.modal === Modals.ERROR ? 'danger' : 'primary'} onClick={ok}>
					{data.okLabel || 'Yes'}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default GeneralModal;
