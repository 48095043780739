import React from 'react';

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import useCommentStore from '@/stores/CommentStore';

import { fireEvent, formatTestId, pathPrefix } from '@/util/helper';

import { Icon } from '@/components/gui/shared/Icon';
import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';

const customStyle = {
	'& > span': {
		flex: 'unset',
	},
};

export const TopMenuItem = React.memo((props) => {
	const [menu, setMenu] = React.useState(false);
	const location = useLocation();
	const navigate = useNavigateWithParams();

	const onClick = (e) => {
		e.preventDefault();

		if (props.onClick) {
			props.onClick(e);
		}

		const proceed = () => {
			const path = pathPrefix() + props.href || pathPrefix();

			if (path) navigate(path + `${location.search}`);

			let closeDiv = document.getElementById('close-stuff');

			if (closeDiv) fireEvent(closeDiv, 'mousedown');

			e.target.blur();
		};

		proceed();
	};

	return (
		<Box
			position="relative"
			onMouseEnter={() => props.count && setMenu(true)}
			onMouseLeave={() => setMenu(false)}
			data-testid="top-menu-item"
		>
			<Tooltip label={props.label} hideFrom="2xl">
				<Button
					to={{ pathname: pathPrefix() + props.href || pathPrefix(), search: location.search }}
					onClick={(e) => onClick(e)}
					data-testid={`top-menu-${formatTestId(props.label)}`}
					isActive={!!props.active}
					variant="navigation"
					mx="1"
				>
					<Icon path={props.active ? props.icons.iconOn : props.icons.iconOff} size="6" />
					<Text hideBelow="2xl" ml={2}>
						{/* check the breakpoint again after title is added */}
						{props.label}
					</Text>
				</Button>
			</Tooltip>
			{props.count ? (
				<Menu zIndex={9} isOpen={menu}>
					{({ isOpen }) => (
						<>
							<MenuButton
								sx={customStyle}
								isActive={isOpen}
								as={Box}
								borderRadius="full"
								fontSize="xs"
								fontWeight="medium"
								w={5}
								h={5}
								top={-1}
								right={-1}
								position="absolute"
								color="chakra-inverse-text"
								backgroundColor="red"
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								{props.count}
							</MenuButton>
							<MenuList>
								<MenuItem
									onClick={() => {
										// setMenu(false);
										useCommentStore.getState().markAllCommentsAsSeen();
									}}
								>
									Mark all as read
								</MenuItem>
							</MenuList>
						</>
					)}
				</Menu>
			) : null}
		</Box>
	);
});

export default TopMenuItem;
