import React from 'react';

import { Box } from '@chakra-ui/react';
import { mdiArrowCollapse, mdiArrowExpand, mdiCrop } from '@mdi/js';
import { ErrorMessages, ModalMessages, SuccessMessages } from 'config/messages';

import useContentStore from '@/stores/ContentStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';

import { stringifyAddress } from '@/util/helper';
import { Modals, ModalTypes, NotificationTypes } from '@/util/resources';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import AlignmentSetting from '@/components/gui/shared/settings/AlignmentSetting.react';
import ButtonSetting from '@/components/gui/shared/settings/ButtonSetting.react';
import DropDownSetting from '@/components/gui/shared/settings/DropDownSetting.react';
import ImagePickerButtons from '@/components/gui/shared/settings/ImagePickerButtons.react';
import MultiSetting from '@/components/gui/shared/settings/MultiSetting.react';
import NumberSetting from '@/components/gui/shared/settings/NumberSetting.react';
import PaddingSetting from '@/components/gui/shared/settings/PaddingSetting.react';
import TextInputSetting from '@/components/gui/shared/settings/TextInputSetting.react';

const SharedImageSettingsFields = {
	link_url: 'link_url',
	src: 'src',
	alignment: 'alignment',
	resizeHeight: 'resizeHeight',
	resizeWidth: 'resizeWidth',
	spacing: 'spacing',
	spacing_title: 'spacing_title',
	imageBorderRadius: 'imageBorderRadius',
	spacing_description: 'spacing_description',
	background_repeat: 'background_repeat',
	background_size: 'background_size',
};

const AlignOptions = [
	{
		value: 'left',
		label: 'Left',
	},
	{
		value: 'center',
		label: 'Center',
	},
	{
		value: 'right',
		label: 'Right',
	},
];

export const BackgroundRepeatOptions = [
	{
		value: 'no-repeat',
		label: 'no-repeat',
	},
	{
		value: 'repeat-y',
		label: 'repeat-y',
	},
	{
		value: 'repeat-x',
		label: 'repeat-x',
	},
	{
		value: 'repeat',
		label: 'repeat',
	},
];

export const BackgroundRepeatOptionsLP = [
	{
		value: 'no-repeat',
		label: 'no-repeat',
	},
	{
		value: 'repeat-y',
		label: 'repeat-y',
	},
	{
		value: 'repeat-x',
		label: 'repeat-x',
	},
	{
		value: 'repeat',
		label: 'repeat',
	},
	{
		value: 'full',
		label: 'Full-width',
	},
];

const BackgroundSizeOptions = [
	{ value: 'auto', label: 'Auto' },
	{ value: 'cover', label: 'Cover' },
	{ value: 'contain', label: 'Contain' },
	{ value: '100% 100%', label: '100% 100%' },
];

export const SharedImageSettingComponents = React.memo((props) => {
	const imageType = props.imageType ? props.imageType : props.originalSrc ? 'image/' + props.originalSrc.split('.').pop() : '';

	let imageUploaded = !!(props.src || props.background_image);

	let spacingSetting =
		(props.spacingSetting && (
			<NumberSetting
				type={SharedImageSettingsFields.spacing}
				inputType="number"
				onChange={props.onChange}
				text={props.spacing}
				label="Spacing"
				ariaLabel="Set spacing"
			/>
		)) ||
		null;

	let altSetting =
		(props.altSetting && (
			<TextInputSetting type={'alt'} onChange={props.onChange} text={props.alt} label="Alt text" ariaLabel="Set alt text" />
		)) ||
		null;

	let fileUploadSetting = props.fileUploadSetting && (
		<ImagePickerButtons
			removeLabel={props.removeLabel}
			label={props.label}
			filterId="add change remove image"
			image={props.src || props.background_image}
			type={props.settingType}
			details={props.address}
			progressFunc={props.progressFunc}
		/>
	);
	let progressBar =
		(props.fileUploadSetting && (
			<Box w={`${props.progress}%`} display={props.progress === 0 ? 'none' : 'block'} h="4" transition="width .5s" bgColor="cyan" />
		)) ||
		null;

	let linkSetting =
		(props.linkSetting && (
			<TextInputSetting
				type={SharedImageSettingsFields.link_url}
				onChange={props.onChange}
				text={props.link_url || ''}
				label="URL link"
				ariaLabel="Set a URL, including http: or https:"
				placeholder="https://…"
				validateURL={false} // false here because url might not be just http or https, could be mailto or whatever
			/>
		)) ||
		null;

	let alignSetting = props.alignSetting && (
		<AlignmentSetting
			type={SharedImageSettingsFields.alignment}
			onChange={props.onChange}
			selected={props.alignment}
			options={AlignOptions}
			label={'Alignment'}
		/>
	);

	let paddingSetting = props.paddingSetting ? (
		<PaddingSetting
			multiPadding={props.multiPadding}
			padding={props.padding}
			type="multiPadding"
			secondaryType={'padding'}
			onChange={props.onChange}
			label="Padding"
		/>
	) : null;

	let backgroundRepeatSetting =
		(props.backgroundRepeatSetting && imageUploaded && (
			<DropDownSetting
				type={SharedImageSettingsFields.background_repeat}
				onChange={props.onChange} //changed this from onResize
				selected={props.background_repeat}
				label="Background repeat"
				options={BackgroundRepeatOptions}
			/>
		)) ||
		null;

	let backgroundSizeSetting = props.backgroundSizeSetting && imageUploaded && (
		<DropDownSetting
			type={SharedImageSettingsFields.background_size}
			onChange={props.onChange}
			selected={props.background_size}
			label="Background size"
			options={BackgroundSizeOptions}
		/>
	);

	let originalSizeButtons =
		(props.originalSizeSetting && (
			<ResetImageSizeSettings
				imageType={imageType}
				imageUploaded={imageUploaded}
				onClickResetWidth={props.onClickResetWidth}
				originalSrc={props.originalCustomLogo || props.originalSrc || ''}
				recropBtn={props.recropBtn}
				onClickCrop={props.onClickCrop}
				isMobileView={props.isMobileView}
				label="Reset image resize"
				noResizeButtons={props.noResizeButtons}
			/>
		)) ||
		null;

	let dimensionSettings = props.dimensionSettings && !props.generalSettings && (
		<MultiSetting label="Image width & image height">
			<NumberSetting
				type={SharedImageSettingsFields.resizeWidth}
				onChange={props.onDimensionsChange}
				text={props.resizeWidth}
				max={useContentStore.getState().general_settings.general_styles.structureWidth}
				min={30}
				label="Image width"
				ariaLabel="Image width in pixels"
			/>
			<NumberSetting
				type={SharedImageSettingsFields.resizeHeight}
				onChange={props.onDimensionsChange}
				text={typeof props.resizeHeight !== 'undefined' ? props.resizeHeight : 'Responsive'}
				min={30}
				label="Image height"
				ariaLabel="Image height in pixels"
			/>
		</MultiSetting>
	);

	let borderRadiusSetting = props.borderRadiusSetting && (
		<NumberSetting
			type={SharedImageSettingsFields.imageBorderRadius}
			onChange={props.onChange}
			text={props.imageBorderRadius}
			label="Image border radius"
			ariaLabel="Image border radius in pixels"
		/>
	);

	return (
		<SettingsBox blockId={props.blockId}>
			{alignSetting}
			{paddingSetting}
			{dimensionSettings}
			{borderRadiusSetting}
			{linkSetting}
			{altSetting}
			{spacingSetting}
			{backgroundRepeatSetting}
			{backgroundSizeSetting}
			{originalSizeButtons}
			{fileUploadSetting}
			{progressBar}
		</SettingsBox>
	);
});

class SharedImageSettings extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			progress: 0,
		};
	}

	componentDidMount() {
		if (this.props.resized && this.props.resizeWidth > useContentStore.getState().general_settings.general_styles.structureWidth) {
			this.onDimensionsChange({
				type: SharedImageSettingsFields.resizeWidth,
				value: useContentStore.getState().general_settings.general_styles.structureWidth,
			});
		}
	}

	render() {
		return (
			<div className="image-settings">
				<SharedImageSettingComponents
					{...this.props}
					onChange={this.onChange}
					onDimensionsChange={this.onDimensionsChange}
					onResize={this.onResize}
					onUpload={this.onUpload}
					onUploadByUrl={this.onUploadByUrl}
					onRemoveImage={this.onRemoveImage}
					onClickResetWidth={this.onClickResetWidth}
					progress={this.state.progress}
					onClickCrop={this.onClickCrop}
					progressFunc={this.progressFunc}
					label={this.props.label}
					removeLabel={this.props.removeLabel}
				/>
			</div>
		);
	}

	onClickResetWidth = (type) => {
		return () => {
			let settings = {};

			//get dimensions directly from the DOM
			let image = document.querySelector(`img#image-${stringifyAddress(this.props.address)}`);

			if (type === 'full') {
				settings = {
					resized: false,
					resizeWidth: null,
					resizeHeight: null,
				};
			} else {
				settings = {
					resized: true,
					resizeWidth: (image && image.naturalWidth) || null,
					resizeHeight: image ? image.naturalHeight : null,
				};
			}

			if (this.props.address) {
				useContentStore.getState().saveElementSettings({
					address: this.props.address,
					settings,
				});
			}
		};
	};

	onResize = (data) => {
		let settings = {
			[data.type]: data.value,
		};
		if (this.props.address) {
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
		}
	};

	onChange = (data) => {
		if (this.props.onChange) {
			this.props.onChange(data);
			return;
		}

		let settings = {};

		if (Array.isArray(data)) {
			data.map((setting) => {
				settings = {
					...settings,
					[setting.type]: setting.value,
				};
			});
		} else {
			settings = {
				[data.type]: data.value,
			};
		}

		if (this.props.address) {
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
		} else {
			useContentStore.getState().saveContentGeneralSettings({ settings });
		}
	};

	onDimensionsChange = (data) => {
		let dimensions = {
			resizeWidth: this.props.resizeWidth,
			resizeHeight: this.props.resizeHeight,
		};

		if (!this.props.resized) {
			let image = document.querySelector(`img#image-${stringifyAddress(this.props.address)}`);

			dimensions = {
				resizeWidth: (image && image.naturalWidth) || null,
				resizeHeight: image ? image.naturalHeight : null,
			};
		}

		if (data.type === SharedImageSettingsFields.resizeHeight) {
			dimensions = {
				...dimensions,
				[data.type]: data.value,
				resizeWidth: (data.value * dimensions.resizeWidth) / dimensions.resizeHeight,
			};
		} else {
			dimensions = {
				...dimensions,
				[data.type]: data.value,
				resizeHeight: (data.value * dimensions.resizeHeight) / dimensions.resizeWidth,
			};
		}

		const settings = {
			resized: true,
			...dimensions,
		};

		if (this.props.onDimensionsChange) {
			return this.props.onDimensionsChange(settings);
		}

		if (this.props.address) {
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
		}
	};

	onClickCrop = () => {
		useModalStore.getState().showCropModal(this.props);
	};

	onUpload = (files) => {
		if (files && files.length > 0) {
			let errorCb = () => {
				showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.IMAGE_NOT_UPLOADED });
			};

			let successCb = () => {
				const imageType = files[0].type;

				const url = URL.createObjectURL(files[0]);
				const img = new Image();

				img.onload = () => {
					const settings = {
						resized: true,
						resizeWidth: (img && img.naturalWidth) || null,
						resizeHeight: (img && img.naturalHeight) || null,
					};

					if (this.props.address) {
						useContentStore.getState().saveElementSettings({
							address: this.props.address,
							settings,
						});
					}
				};

				img.src = url;

				if (imageType === 'image/jpg' || imageType === 'image/jpeg') {
					if (!useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.jpgWarning) {
						useModalStore.getState().showDontAskModal({
							modal: Modals.INFO,
							// image: responsiveGif,
							type: 'jpgWarning',
							message: ModalMessages.JPG_WARNING,
							// message2: ModalMessages.RESPONSIVE_WARNING2,
							// list: config.responsive_email_clients,
							ignoreCancelBtn: true,
							okLabel: 'Got it',
						});
					} else {
						showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.IMAGE_UPLOADED });
					}
				} else {
					showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.IMAGE_UPLOADED });
				}
			};

			let progress = (e) => {
				let percentCompleted = (e.loaded / e.total) * 100;
				this.setState({ progress: percentCompleted === 100 ? 0 : percentCompleted });
			};

			if (this.props.address) {
				useContentStore.getState().uploadImage(
					{
						address: this.props.address,
						files,
						progress,
					},
					successCb,
					errorCb,
				);
			}
		}
	};

	onUploadByUrl = (url) => {
		if (url && url.length > 0) {
			let errorCb = () => {
				showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.URL_NOT_IMAGE });
			};

			let successCb = () => {
				const img = new Image();

				img.onload = () => {
					const settings = {
						resized: true,
						resizeWidth: (img && img.naturalWidth) || null,
						resizeHeight: (img && img.naturalHeight) || null,
					};

					if (this.props.address) {
						useContentStore.getState().saveElementSettings({
							address: this.props.address,
							settings,
						});
					}
				};

				img.src = url;

				const imageType = url ? 'image/' + url.split('.').pop() : '';
				if (imageType === 'image/jpg' || imageType === 'image/jpeg') {
					if (!useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.jpgWarning) {
						useModalStore.getState().showDontAskModal({
							modal: Modals.INFO,
							// image: responsiveGif,
							type: 'jpgWarning',
							message: ModalMessages.JPG_WARNING,
							// message2: ModalMessages.RESPONSIVE_WARNING2,
							// list: config.responsive_email_clients,
							ignoreCancelBtn: true,
							okLabel: 'Got it',
						});
					} else {
						showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.IMAGE_UPLOADED });
					}
				} else {
					showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.IMAGE_UPLOADED });
				}
			};

			let progress = (e) => {
				let percentCompleted = (e.loaded / e.total) * 100;
				this.setState({ progress: percentCompleted === 100 ? 0 : percentCompleted });
			};

			if (this.props.address)
				useContentStore.getState().uploadImageByUrl(
					{
						address: this.props.address,
						url,
						progress,
					},
					successCb,
					errorCb,
				);
			if (!this.props.address) {
				useContentStore.getState().uploadBackgroundImageByUrl(
					{
						url,
						progress,
					},
					successCb,
					errorCb,
				); //no address means whole content bckg image
			}
		}
	};

	progressFunc = (e) => {
		let percentCompleted = (e.loaded / e.total) * 100;
		this.setState({ progress: percentCompleted === 100 ? 0 : percentCompleted });
	};

	onRemoveImage = () => {
		let elementSettings = {
			src: '',
			alt: '',
			originalSrc: '',
			resizeWidth: null,
			resizeHeight: null,
			resized: false,
		};

		let contentSettings = {
			background_image: '',
		};

		if (this.props.address)
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings: elementSettings,
			});
		if (!this.props.address) useContentStore.getState().saveContentSettings({ settings: contentSettings });
	};
}

const ResetImageSizeSettings = React.memo((props) => {
	const isGif = props.imageType === 'image/gif';

	let customBtnStyle = !isGif
		? {
				w: '32%',
				d: 'inline-block',
				mb: 2.5,
			}
		: {
				w: '49%',
				d: 'inline-block',
				mb: 2.5,
			};

	let recropBtn =
		(!props.isMobileView && props.recropBtn && !isGif && props.originalSrc && props.originalSrc !== '' && (
			<ButtonSetting
				onClick={props.onClickCrop}
				tooltip="Crop image"
				icon={mdiCrop}
				testId="crop"
				customStyle={!props.noResizeButtons ? customBtnStyle : { w: '100%' }}
			/>
		)) ||
		null;

	let resizeButtons = !props.noResizeButtons
		? [
				<ButtonSetting
					key="0"
					onClick={props.onClickResetWidth('original')}
					tooltip="Natural size"
					icon={mdiArrowCollapse}
					testId="close_fullscreen"
					customStyle={customBtnStyle}
				/>,
				<Box key="1" display="inline-block" w="2%" />,
				<ButtonSetting
					key="2"
					onClick={props.onClickResetWidth('full')}
					tooltip="Full-width"
					testId="open_in_full"
					icon={mdiArrowExpand}
					customStyle={customBtnStyle}
				/>,
			]
		: null;

	return (
		<Box mt={2.5} mb={2.5} display={props.imageUploaded ? 'block' : 'none'}>
			{recropBtn}
			{!isGif && !props.noResizeButtons && <Box display="inline-block" w="2%" />}
			{resizeButtons}
		</Box>
	);
});

export default SharedImageSettings;
