import React from 'react';

import useContentStore from '@/stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

const TextInputSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<div className="text-input-settings">
			<SharedInputSettings
				{...props}
				onChange={onChange}
				settings={{
					pattern: { active: true },
					maxLength: { active: true },
					autocomplete: { active: true },
				}}
			/>
		</div>
	);
};

export default TextInputSettings;
