import React from 'react';

import { Box } from '@chakra-ui/react';
import { AllComponentTypes, ComponentDefaults, getPadding, getShadowValFromProps } from 'shared/src/utils/shared.js';

import Element from './Element.react';
import ElementComponent from './ElementComponent.react';

export class Button extends ElementComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.submit_button] };

	render() {
		const { alignment, background_color, back_button } = this.props;

		const backButton = this.props.pageIndex > 0 && <button style={getButtonStyles(back_button)}>{back_button.text}</button>;
		const submitButton = <button style={getButtonStyles(this.props)}>{this.props.text}</button>;

		if (this.props.pageIndex === this.props.lastPage) {
			return (
				<Box
					display="flex"
					flexWrap="wrap"
					bgColor={background_color}
					alignItems="center"
					justifyContent={
						this.props.pageIndex > 0
							? 'space-between'
							: alignment === 'left'
								? 'flex-start'
								: alignment === 'right'
									? 'flex-end'
									: alignment
					}
				>
					{backButton}
					{submitButton}
				</Box>
			);
		}

		return (
			<Box display="flex" flexWrap="wrap" bgColor={background_color} justifyContent={this.props.pageIndex !== 0 ? 'space-between' : 'end'}>
				{backButton}
				{submitButton}
			</Box>
		);
	}
}

const getButtonStyles = (props) => {
	const {
		customSize,
		borderColor,
		borderRadius,
		borderWidth,
		color,
		fontFamily,
		fontSize,
		fontStyle,
		fontWeight,
		margin,
		textDecoration,
		text_color,
		width,
	} = props;

	const dimensions =
		width === 'custom'
			? {
					padding: 0,
					width: customSize.width,
					height: customSize.height,
				}
			: { width, ...getPadding(props, true) };

	const buttonStyles = {
		boxShadow: getShadowValFromProps(props.box_shadow),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: text_color,
		backgroundColor: color,
		borderStyle: 'solid',
		borderColor,
		borderRadius,
		borderWidth,
		fontFamily,
		fontSize,
		fontStyle,
		fontWeight,
		margin: width !== '100%' ? margin : `0`,
		textDecoration,
		...dimensions,
	};

	return buttonStyles;
};

export default Element(Button);
