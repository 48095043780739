import configDev from './config.development';
import configPreprod from './config.preprod';
import configProd from './config.production';

let config = configDev;

if (process.env.NODE_ENV === 'production') {
	config = configProd;
} else if (process.env.NODE_ENV === 'preproduction') {
	config = configPreprod;
}

export default config;
