import { useEffect } from 'react';

import { useToast } from '@chakra-ui/react';

import useNotificationStore from '@/stores/NotificationStore';

const AutosaveNotificationPopup = () => {
	const autosave = useNotificationStore((state) => state.autosave);
	const toast = useToast();

	useEffect(() => {
		if (autosave)
			toast({
				description: 'Autosaved',
				status: 'success',
			});
	}, [autosave]);

	return null;
};

export default AutosaveNotificationPopup;
