import cloneDeep from 'lodash/cloneDeep';
import { generateGuid } from 'shared/src/utils/shared.js';

import {
	BaseConditionGroupProps,
	BaseConditionProps,
	BaseCriteriaProps,
	BaseLogicActionProps,
	BaseLogicProps,
	ComponentTypeToComparer,
} from '@/util/resources';

export const swapArrayElements = (array, from, to) => {
	const temp = array[from];

	array[from] = array[to];
	array[to] = temp;

	return array;
};
export const swapConditionalItems = (array, from, to) => {
	const fromItem = cloneDeep({ ...array[from], operator: array[to].operator });
	const toItem = cloneDeep({ ...array[to], operator: array[from].operator });

	array[from] = toItem;
	array[to] = fromItem;

	return array;
};
export const createBaseConditionGroup = (settings) => {
	return { ...BaseConditionGroupProps, id: generateGuid(), settings: settings ?? [] };
};
export const createBaseCondition = () => {
	return { ...BaseConditionProps, id: generateGuid() };
};
export const duplicateCondition = (condition) => {
	return { ...condition, id: generateGuid() };
};
export const createBaseCriteria = (fieldType) => {
	const comparer = ComponentTypeToComparer?.[fieldType]?.[0] || BaseCriteriaProps.comparer;
	return { ...BaseCriteriaProps, id: generateGuid(), comparer };
};
export const createBaseLogic = () => {
	return {
		...BaseLogicProps,
		id: generateGuid(),
		name: 'Logic',
		settings: [
			{
				...BaseConditionProps,
				id: generateGuid(),
			},
		],
	};
};
export const createBaseLogicAction = () => {
	return { ...BaseLogicActionProps, id: generateGuid() };
};
