import { produce } from 'immer';
import { create } from 'zustand';

import { getTenants } from '@/util/apiUtils/users';
import { claimsKeys, getUser } from '@/util/auth/auth';

const useUserStore = create((set) => ({
	currentTenant: {},
	tenants: [],
	getTenants: async () => {
		try {
			const user = getUser();
			const tenants = await getTenants();

			if (tenants.data.data) {
				set(
					produce((state) => {
						state.currentTenant = tenants.data.data.find((tenant) => tenant.id === user[claimsKeys.TENANT_ID]);
						state.tenants = tenants.data.data;
					}),
				);
			}
		} catch (e) {
			console.error(e, true);
		}
	},
}));

export default useUserStore;
