export const searchForComponentsInRows = (components, logics) => {
	const existingAddress = [[]];

	logics.forEach((logic, logicIndex) => {
		logic.settings.forEach((item, itemIndex) => {
			if (item.type === 'group') {
				return item.settings.forEach((condition, conditionIndex) => {
					components.forEach((compId, rowCompIndex) => {
						if (condition.field && condition.field.id === compId) {
							existingAddress[rowCompIndex] = [logicIndex, itemIndex, conditionIndex];
						}
					});
				});
			} else {
				components.forEach((compId, rowCompIndex) => {
					if (item.field && item.field.id === compId) {
						existingAddress[rowCompIndex] = [logicIndex, itemIndex];
					}
				});
			}
		});
	});

	return existingAddress.filter((address) => address.length > 0);
};

export const sortComponentIndexes = (address1, address2) => {
	if (address1.length !== address2.length) {
		return address2.length - address1.length;
	}

	for (let i = 0; i < address1.length; i++) {
		if (address1[i] !== address2[i]) {
			return address2[i] - address1[i];
		}
	}

	return 0;
};

export const deleteComponentsFromRows = (existingAddress, logics) => {
	// Remove the components from their respective Logic
	existingAddress
		.sort(sortComponentIndexes)
		.forEach((componentAddress) =>
			componentAddress.length === 3
				? logics[componentAddress[0]].settings[componentAddress[1]].settings.splice(componentAddress[2], 1)
				: logics[componentAddress[0]].settings.splice(componentAddress[1], 1),
		);

	return logics;
};
