import * as React from 'react';

import { Box, Heading, IconButton, Text } from '@chakra-ui/react';
import { mdiClose } from '@mdi/js';
import { isEqual } from 'lodash';
import { AllComponentTypes, SpecialComponentTypes } from 'shared/src/utils/shared.js';
import { shallow } from 'zustand/shallow';

import useContentStore from '@/stores/ContentStore';
import useContentViewStore from '@/stores/ContentViewStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import strings from '@/resources/strings/en-us';
import { getRowPageData } from '@/util/helper';
import { ElementLabels, contentViewMenuItemConstants as ViewTypes } from '@/util/resources';

import ButtonSettings from '@/components/gui/content/settings/Button-settings.react';
import CheckboxSettings from '@/components/gui/content/settings/Checkbox-settings.react';
import CheckboxGroupSettings from '@/components/gui/content/settings/CheckboxGroup-settings.react';
import DateInputSettings from '@/components/gui/content/settings/DateInput-settings.react';
import EmailInputSettings from '@/components/gui/content/settings/EmailInput-settings.react';
import FieldPlaceholderSettings from '@/components/gui/content/settings/FieldPlaceholder-settings.react';
import FileUploadSettings from '@/components/gui/content/settings/FileUpload-settings';
import GDPRboxSettings from '@/components/gui/content/settings/GDPRbox-settings.react';
import ImageSettings from '@/components/gui/content/settings/Image-settings.react';
import LongTextInputSettings from '@/components/gui/content/settings/LongTextInput-settings.react';
import MultiSelectSettings from '@/components/gui/content/settings/MultiSelect-settings.react';
import NumberInputSettings from '@/components/gui/content/settings/NumberInput-settings.react';
import PhoneInputSettings from '@/components/gui/content/settings/PhoneInput-settings.react';
import RadioSettings from '@/components/gui/content/settings/Radio-settings.react';
import RecaptchaSettings from '@/components/gui/content/settings/Recaptcha-settings.react';
import RowSettings from '@/components/gui/content/settings/Row-settings.react';
import SharedSettings from '@/components/gui/content/settings/SharedSettings.react';
import SingleSelectSettings from '@/components/gui/content/settings/SingleSelect-settings.react';
import SocialFollowSettings from '@/components/gui/content/settings/SocialFollow-settings.react';
import SpacerSettings from '@/components/gui/content/settings/Spacer-settings.react';
import SummaryPageSettings from '@/components/gui/content/settings/SummaryPage-settings';
import TextSettings from '@/components/gui/content/settings/Text-settings.react';
import TextInputSettings from '@/components/gui/content/settings/TextInput-settings.react';
import { FilterableSettings } from '@/components/gui/settingsContext/SettingsContext';
import { Icon } from '@/components/gui/shared/Icon';

const SettingTypes = {
	[AllComponentTypes.spacer]: SpacerSettings,
	[AllComponentTypes.text]: TextSettings,
	[AllComponentTypes.submit_button]: ButtonSettings,
	[AllComponentTypes.social_follow]: SocialFollowSettings,
	[AllComponentTypes.image]: ImageSettings,
	[AllComponentTypes.email_input]: EmailInputSettings,
	[AllComponentTypes.recaptcha]: RecaptchaSettings,
	[AllComponentTypes.phone_input]: PhoneInputSettings,
	[AllComponentTypes.text_input]: TextInputSettings,
	[AllComponentTypes.longtext_input]: LongTextInputSettings,
	[AllComponentTypes.date_input]: DateInputSettings,
	[AllComponentTypes.number_input]: NumberInputSettings,
	[AllComponentTypes.radio]: RadioSettings,
	[AllComponentTypes.checkbox]: CheckboxSettings,
	[AllComponentTypes.checkbox_group]: CheckboxGroupSettings,
	[AllComponentTypes.singleselect_input]: SingleSelectSettings,
	[AllComponentTypes.multiselect_input]: MultiSelectSettings,
	[AllComponentTypes.gdpr]: GDPRboxSettings,
	[AllComponentTypes.file_upload]: FileUploadSettings,
	[SpecialComponentTypes.fieldPlaceholder]: FieldPlaceholderSettings,
	row: RowSettings,
};

export const DynamicSettings = React.memo((props) => {
	const isMobileView = useContentViewStore((state) => state.currentView === ViewTypes.MOBILE_VIEW);

	const { address, settings } = useLeftSidebarStore((state) => {
		return {
			settings: state.settings.activeElement,
			address: state.settings.toggledElementSettingsAddress,
			component: state,
		};
	}, shallow);

	const general_styles = useContentStore((state) => {
		const settings = isMobileView ? { ...state.content, ...state.content.mobileProps } : state.content;

		return {
			text_line_height: settings.text_line_height || '1',
			font_size: settings.font_size,
			font_family: settings.font_family,
			color: settings.color === 'transparent' ? '#000000' : settings.color,
			background_image: settings.background_image,
			background_repeat: settings.background_repeat,
			bg_color: settings.bg_color,
			title: settings.title,
			borderWidth: settings.borderWidth,
			borderColor: settings.borderColor,
			structureWidth: settings.structureWidth,
		};
	}, isEqual);

	const summaryPageActive = useContentStore((state) => state.content.summaryPage.active);

	const mainBgColor = general_styles.bg_color;

	const { general_settings, lastPage } = useContentStore((state) => {
		return {
			general_settings: state.general_settings,
			lastPage: state.content.lastPage,
		};
	}, shallow);

	const [pageData, setPageData] = React.useState({
		lastPage,
		pageIndex: address ? getRowPageData(useContentStore.getState().content.rows, address.rowId) : 0,
	});

	React.useEffect(() => {
		if (address) {
			setPageData({
				lastPage,
				pageIndex: getRowPageData(useContentStore.getState().content.rows, address.rowId),
			});
		}
	}, [JSON.stringify(address), lastPage]);

	const onClose = () => {
		const contentStore = useContentStore.getState();
		contentStore.unsetClickedElement();
		contentStore.unsetClickedRow();
		contentStore.unsetClickedSummaryPage();
		contentStore.deactivateAddStructureBoxHelper();
		contentStore.deactivateAddElementBoxHelper();
	};

	const renderSettingsTitle = (title) => (
		<Box className="settings-title" mt={-5} px={5} py={4} mx={-5} textAlign="left" pos="relative">
			<Heading size="sm">{title}</Heading>

			<IconButton
				aria-label="Close"
				variant="ghost"
				size="sm"
				pos="absolute"
				top="50%"
				right={3}
				transform="translateY(-50%)"
				onClick={onClose}
				icon={<Icon path={mdiClose} />}
				data-testid="close-settings-btn"
			/>
		</Box>
	);

	const finalSettings = isMobileView ? { ...settings, ...settings.mobileProps } : settings;
	if (address !== null && typeof address === 'object') {
		const SettingsComponent = SettingTypes[settings.type];

		const settingsComp = (
			<SettingsComponent
				{...finalSettings}
				{...pageData}
				address={address}
				isMobileView={isMobileView}
				leftSidebarOptions={props.options}
				general_styles={general_styles}
				mainBgColor={mainBgColor}
				summaryPageActive={summaryPageActive}
			/>
		);

		if (SettingsComponent) {
			return (
				<div>
					{renderSettingsTitle(ElementLabels[settings.type])}

					{AllComponentTypes[settings.type] ? (
						<FilterableSettings>
							<SharedSettings address={address} background_color={finalSettings.background_color} box_shadow={finalSettings.box_shadow} />
							{settingsComp}
						</FilterableSettings>
					) : (
						settingsComp
					)}
				</div>
			);
		} else {
			return <Text variant="strong">{isMobileView ? strings.no_settings_for_mobile : strings.no_settings_for_text}</Text>;
		}
	} else if (address && address === 'summary_page') {
		return (
			<div>
				{renderSettingsTitle('Summary page')}

				<FilterableSettings>
					<SummaryPageSettings leftSidebarOptions={props.options} />
				</FilterableSettings>
			</div>
		);
	} else if (typeof address === 'string' || typeof address === 'number') {
		return (
			<div>
				{renderSettingsTitle('Layout settings')}

				<FilterableSettings>
					<RowSettings
						{...finalSettings}
						isMobileView={isMobileView}
						structureWidth={general_settings.general_styles.structureWidth}
						leftSidebarOptions={props.options}
					/>
				</FilterableSettings>
			</div>
		);
	} else {
		return null;
	}
});

DynamicSettings.displayName = 'DynamicSettings';
