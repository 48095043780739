import { createRoot } from 'react-dom/client';

import { setupRegionBasedConfig } from 'config/config';

import Router from './routes';
import { initApm } from './util/apm';
import { checkAuthentication, createClient, handleRedirectCb, resolveAndSetRegionCode, setUserData } from './util/auth/auth';

const runMain = async () => {
	if (import.meta.env.MODE === 'test') return;

	const apm = initApm();

	// Next lines deal with authentication, always needs to be the first thing that runs on the app
	const auth = await createClient();

	await handleRedirectCb(auth); // has side effects

	const isAuthenticated = await checkAuthentication(auth); // has side effects

	if (!isAuthenticated) return;

	const user = await setUserData(auth); // has side effects
	const regionCode = await resolveAndSetRegionCode(auth); // has side effects

	setupRegionBasedConfig({ regionCode }); // has side effects, interpolates {regionCode} in all string properties in the config object with the right region code

	apm.setUserContext({
		id: user.sub,
		email: user.email,
	});
	const root = createRoot(document.getElementById('react-content'));
	root.render(<Router />);
};

runMain();
