import React from 'react';

import { Checkbox } from '@chakra-ui/react';

import { Tooltip } from '@/components/gui/content/helper/Tooltip.react';

const CheckBoxSetting = ({ checked, disabled, onClick, type, label, tooltip, styleProps }) => {
	const handleClick = (event) => {
		if (onClick) onClick({ event, type, value: !checked });
	};

	return (
		<Checkbox
			isChecked={checked}
			isDisabled={disabled}
			onChange={handleClick}
			size="md"
			mb={5}
			data-testid={`${type}-checkbox`}
			{...styleProps}
		>
			{label}
			{tooltip && <Tooltip>{tooltip}</Tooltip>}
		</Checkbox>
	);
};

export default CheckBoxSetting;
