import React from 'react';

import { AllComponentTypes, ComponentDefaults, generateGuid, getShadowValFromProps } from 'shared/src/utils/shared.js';

import urlConfig from '../../../config/config';

const config = {
	possible_social_follow_icons: [
		'facebook',
		'twitter',
		'instagram',
		'pinterest',
		'youtube',
		'linkedin',
		'tumblr',
		'soundcloud',
		'tiktok',
		'snapchat',
		'custom',
	],
	social_follow_style_folder_names: [
		'default',
		'blue',
		'grey-circle',
		'grey-round-thin',
		'mint-round-thin',
		'peach-circle',
		'white-circle',
		'white-half-circle',
		'white-half-roundsquare',
		'white-half-solid',
		'white-roundsquare',
		'white-solid',
		'white-square',
		'anthracite-square',
		'black-half-round',
		'black-half-roundsquare',
		'black-half-solid',
		'black-round',
		'black-round',
		'black-roundsquare',
		'black-solid',
		'black-square',
	],
};

class SocialFollow extends React.PureComponent {
	static defaultProps = {
		background_color: ComponentDefaults[AllComponentTypes.social_follow].background_color,
		type: ComponentDefaults[AllComponentTypes.social_follow].type,
		style: ComponentDefaults[AllComponentTypes.social_follow].style,
		spacing: ComponentDefaults[AllComponentTypes.social_follow].spacing,
		alignment: ComponentDefaults[AllComponentTypes.social_follow].alignment,
		facebook: null,
		twitter: null,
		instagram: null,
		googleplus: null,
		tumblr: null,
		pinterest: null,
		linkedin: null,
		youtube: null,
		box_shadow: ComponentDefaults[AllComponentTypes.social_follow].box_shadow,
	};

	render() {
		let passableIcons = config.possible_social_follow_icons;
		let folderNames = config.social_follow_style_folder_names;

		let style = folderNames.includes(this.props.style) ? this.props.style : 'default';

		let iconList = passableIcons.filter((icon) => {
			//can not check for truthy of falsy here because empty string is considered falsy
			return icon in this.props && this.props[icon] !== null && this.props[icon] !== undefined;
		});

		let tempKey = 1;

		let socialIcons = iconList.map((icon) => {
			const assetUrl = new URL(`/images/social_icons/social_follow/${style}/${icon}.png`, urlConfig.base_url).href;
			const assetUrlDouble = new URL(`/images/social_icons/social_follow/${style}/${icon}@2x.png`, urlConfig.base_url).href;
			const assetUrlTriple = new URL(`/images/social_icons/social_follow/${style}/${icon}@3x.png`, urlConfig.base_url).href;

			// const imageSet = `image-set(url(${assetUrl}) 1x, url(${assetUrlDouble}) 2x, url(${assetUrlTriple}) 3x)`;
			// const imageSetWebkit = `-webkit-image-set(url(${assetUrl}) 1x, url(${assetUrlDouble}) 2x, url(${assetUrlTriple}) 3x)`;

			const backgroundClassName = 'icon' + generateGuid().replace(/-/g, '');

			const elementStyle = (
				<style>
					{`
                        .${backgroundClassName} {
                            background-image: url(${assetUrl});
                        }
                        
                        @media only screen and (-webkit-min-device-pixel-ratio: 2),
                        only screen and (   min--moz-device-pixel-ratio: 2),
                        only screen and (     -o-min-device-pixel-ratio: 2/1),
                        only screen and (        min-device-pixel-ratio: 2),
                        only screen and (                min-resolution: 192dpi),
                        only screen and (                min-resolution: 2dppx) { 
                            .${backgroundClassName} {
                                background-image: url(${assetUrlDouble});
                            }
                        }

                        @media only screen and (-webkit-min-device-pixel-ratio: 3),
                        only screen and (   min--moz-device-pixel-ratio: 3),
                        only screen and (     -o-min-device-pixel-ratio: 3/1),
                        only screen and (        min-device-pixel-ratio: 3),
                        only screen and (                min-resolution: 288dpi),
                        only screen and (                min-resolution: 3dppx) { 
                            .${backgroundClassName} {
                                background-image: url(${assetUrlTriple});
                            }
                        }
                    `}
				</style>
			);

			return [
				<div key={tempKey - 1} className="social-icon-spacing" />,
				<div key={tempKey} className="social-icon-div">
					{elementStyle}
					<a
						data-icon-type={icon}
						href={this.props[icon]}
						className={`social_icon ${icon} ${backgroundClassName}`}
						target="_blank"
						title={icon}
					>
						<img src={assetUrl} srcSet={`${assetUrlTriple} 3x, ${assetUrlDouble} 2x, ${assetUrl} 1x`} />
					</a>
				</div>,
				<div key={tempKey + 1} className="social-icon-spacing" />,
			];
		});

		const id = this.props.id ? this.props.id.toString().replace(/-/g, '') : '';

		return (
			<div className={`component global-social social-follow-component socialfollow${id} `} data-component-type="social_follow" id={id}>
				{socialIcons}
			</div>
		);
	}
}

export function generateFollowClass(component) {
	const realSpacing = isNaN(component.spacing) ? 0 : component.spacing / 2;

	const passableIcons = config.possible_social_follow_icons;
	const folderNames = config.social_follow_style_folder_names;

	const iconStyle = folderNames.includes(component.style) ? component.style : 'default';

	const iconList = passableIcons.filter((icon) => {
		return icon && icon in component;
	});

	const socialIcons = iconList.map((icon) => {
		return {
			url: `${urlConfig.base_url}/images/social_icons/social_follow/${iconStyle}/${icon}.png`,
			double: `${urlConfig.base_url}/images/social_icons/social_follow/${iconStyle}/${icon}@2x.png`,
			triple: `${urlConfig.base_url}/images/social_icons/social_follow/${iconStyle}/${icon}@3x.png`,
			icon,
		};
	});

	const renderButtonIcons = () => {
		const icons = [];
		socialIcons.forEach((icon) => {
			icons.push(
				`
                
                .socialfollow${component.id.replace(/-/g, '')} .${icon.icon} {
                    background-image: url("${icon.url}");
                }

                @media
                only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (   min--moz-device-pixel-ratio: 2),
                only screen and (     -o-min-device-pixel-ratio: 2/1),
                only screen and (        min-device-pixel-ratio: 2),
                only screen and (                min-resolution: 192dpi),
                only screen and (                min-resolution: 2dppx) { 
                    .socialfollow${component.id.replace(/-/g, '')} .${icon.icon} {
                        background-image: url("${icon.double}");
                    }
                }

                @media
                only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (   min--moz-device-pixel-ratio: 3),
                only screen and (     -o-min-device-pixel-ratio: 3/1),
                only screen and (        min-device-pixel-ratio: 3),
                only screen and (                min-resolution: 288dpi),
                only screen and (                min-resolution: 3dppx) { 
                    .socialfollow${component.id.replace(/-/g, '')} .${icon.icon} {
                        background-image: url("${icon.triple}");
                    }
                }
                `,
			);
		});

		return icons.join('');
	};

	return `

    .socialfollow${component.id.replace(/-/g, '')} {
        background-color: ${component.background_color || 'transparent'};
        text-align: ${component.alignment};
        box-shadow: ${getShadowValFromProps(component.box_shadow)};
    }

    .socialfollow${component.id.replace(/-/g, '')} .social-icon-spacing {
        width: ${realSpacing}px;
    }

		.socialfollow${component.id.replace(/-/g, '')} .social_icon:focus {
			outline: 2px solid #007fff;
		}

    ${renderButtonIcons()}
    `;
}

export default SocialFollow;
