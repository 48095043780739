import { useMemo, useState } from 'react';

import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	ButtonGroup,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import useContentStore from '@/stores/ContentStore';
import useModalStore from '@/stores/ModalStore';
import useStylingStore from '@/stores/StylingStore';

import { Modals } from '@/util/resources';

export const GlobalStyleSetting = () => {
	const [isOpen, setIsOpen] = useState(false);
	const list = useStylingStore((state) => state.list);
	const customCss = useContentStore((state) => state.content.customCss);
	const removeGlobalStyles = useContentStore((state) => state.removeGlobalStyles);

	const onReset = () => {
		useModalStore.getState().showGeneralModal({
			modal: Modals.INFO,
			hideIcon: true,
			title: 'Clear style',
			message: `Are you sure you want to clear style?`,
			onOk: removeGlobalStyles,
			okLabel: 'Clear',
			cancelLabel: 'Cancel',
		});
	};

	return (
		<Box>
			<StyleSelect list={list} isOpen={isOpen} onClose={() => setIsOpen(false)} />
			<Box>
				<Text mb={3} fontWeight="medium" color="neutral-fg">
					Style
				</Text>
				{customCss ? (
					<>
						<ButtonGroup spacing={3}>
							<Button size="sm" variant="outline" colorScheme="primary" isDisabled={!list.length} onClick={() => setIsOpen(true)}>
								Apply style to form
							</Button>
							<Button size="sm" variant="ghost" mb="4" onClick={onReset}>
								Clear style
							</Button>
						</ButtonGroup>
						<Alert data-testid="custom-style-alert" w="fit-content">
							<AlertIcon />
							<AlertDescription>
								Style in-use is saved as CSS code, and the changes will only be visible when the form is seen in Preview.
							</AlertDescription>
						</Alert>
					</>
				) : (
					<Button variant="outline" colorScheme="primary" size="sm" isDisabled={!list.length} onClick={() => setIsOpen(true)}>
						Apply style to form
					</Button>
				)}
			</Box>
		</Box>
	);
};

const StyleSelect = ({ onClose, list, isOpen }) => {
	const applyGlobalStyles = useContentStore((state) => state.applyGlobalStyles);

	const [selectedStyle, setSelectedStyle] = useState('');

	const onCloseModal = () => {
		onClose();
		setSelectedStyle('');
	};

	const onSave = () => {
		applyGlobalStyles(selectedStyle);
		onCloseModal();
	};

	const isCss = useMemo(() => {
		const style = list.find((item) => item.id === selectedStyle);
		if (style && style.isCss) {
			return true;
		}

		return false;
	}, [selectedStyle]);

	return (
		<Modal onClose={onCloseModal} isOpen={isOpen} size="xl">
			<ModalOverlay data-testid="new-style-modal-overlay" />
			<ModalContent h="fit-content">
				<ModalCloseButton />
				<ModalHeader>Apply style to all items</ModalHeader>
				<ModalBody data-testid="apply-style-wrapper">
					<Select
						onChange={(selectedOption) => setSelectedStyle(selectedOption.value)}
						selectedValue={selectedStyle}
						data-testid="styling-select"
						placeholder="Select a style"
						useBasicStyles
						selectedOptionStyle="check"
						options={list.map((item) => {
							return {
								value: item.id,
								label: item.name + (item.isCss ? ' (Code)' : ''),
							};
						})}
					/>
					{isCss && (
						<Alert mt={6} data-testid="css-style-alert">
							<AlertIcon />
							<AlertDescription>
								This style is saved as CSS code, and the changes will only be visible when the form is seen in Preview.
							</AlertDescription>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<Button variant="ghost" onClick={onCloseModal}>
							Cancel
						</Button>
						<Button data-testid="new-style-button" onClick={onSave} isDisabled={!selectedStyle}>
							Apply
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
