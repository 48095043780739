import { useCallback } from 'react';

import { Box, Button, Heading } from '@chakra-ui/react';

import useModalStore from '@/stores/ModalStore';
import useStylingStore from '@/stores/StylingStore';

import { Modals } from '@/util/resources';

import StylingCode from './StylingCode';
import StylingSettings from './StylingSettings';

export const StylingLeftSidebar = () => {
	const { isCss, resetStyle, switchToCode } = useStylingStore((state) => {
		return {
			changeStyle: state.changeStyle,
			styles: state.styles.settings,
			isCss: state.styles.isCss,
			resetStyle: state.resetStyle,
			switchToCode: state.switchToCode,
		};
	});

	const onReset = useCallback(() => {
		useModalStore.getState().showGeneralModal({
			modal: Modals.INFO,
			hideIcon: true,
			title: 'Styles builder',
			message: `Going back to the styles builder will erase the CSS code entered in the editor.`,
			onOk: resetStyle,
			okLabel: 'Return',
		});
	}, []);

	const onSwitchToCode = useCallback(() => {
		useModalStore.getState().showGeneralModal({
			modal: Modals.INFO,
			hideIcon: true,
			title: 'CSS editor',
			message: `You are now switching to CSS edit mode. The changes you've done using the styles builder will remain.`,
			onOk: switchToCode,
			okLabel: 'Edit as CSS',
		});
	}, []);

	return (
		<Box flex={2} p={5} h="100%" overflowY="auto" overflowX="hidden" position="relative">
			<Box display="flex" justifyContent="space-between" pb={3.5}>
				<Heading size="md">{!isCss ? 'Styles builder' : 'CSS editor'}</Heading>
				<Box display="flex">
					<Button
						data-testid="style-toggle-button"
						variant="outline"
						size="sm"
						colorScheme="primary"
						onClick={isCss ? onReset : onSwitchToCode}
					>
						{isCss ? 'Styles builder' : 'CSS editor'}
					</Button>
				</Box>
			</Box>
			{isCss ? <StylingCode /> : <StylingSettings />}
		</Box>
	);
};

export default StylingLeftSidebar;
