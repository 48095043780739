import { produce } from 'immer';
import { create } from 'zustand';

import { addFontToStylesheets, sortFonts } from '@/util/helper';
import { CkeditorFontNamesLP, CkeditorLineHeights, GoogleFontOptions } from '@/util/resources';

import useContentStore from './ContentStore';

const useTextEditorStore = create((set, get) => ({
	font_names: sortFonts(CkeditorFontNamesLP),
	line_height: CkeditorLineHeights,
	custom_fonts: GoogleFontOptions,

	setFontNames: (fontNames) => {
		set(() => ({ font_names: sortFonts(fontNames) }));
	},
	addCustomFont: (font) => {
		addFontToStylesheets([...get().custom_fonts, font]);

		const isAddedFont = get().custom_fonts.find((item) => {
			return item.label === font.label;
		});

		if (isAddedFont) {
			const customFonts = [...get().custom_fonts];
			const index = customFonts.findIndex((item) => {
				return item.label === font.label;
			});

			if (index !== -1) {
				customFonts.splice(index, 1, font);

				set(() => ({ custom_fonts: customFonts }));
			}
		} else {
			set(
				produce((state) => {
					state.custom_fonts.push(font);
					state.font_names = sortFonts(state.font_names + `;${font.label}=${font.value}`);
				}),
			);
		}

		useContentStore.getState().addCustomFont(font);
	},
}));

export default useTextEditorStore;
