import * as React from 'react';

import { Anchor, Box, Button, Input, InputContainer, InputIcon, Text } from '@moosend/mooskin';

import useContentStore from '@/stores/ContentStore';

export const ContentHubIntegration = (props) => {
	const [errorMsg, setErrorMsg] = React.useState('');

	const authenticate = (data, callback) => {
		// this.props.authenticate({ callback, data });
		// fire authenticate action
		const newCallback = (data) => {
			callback();
			props.onDataChange(data);
		};
		useContentStore.getState().authenticateContentHub({ data, successCb: newCallback, errorCb: onError });
	};

	const onError = (text) => {
		setErrorMsg(text || 'Failed to authenticate');
		setTimeout(() => {
			setErrorMsg('');
		}, 5000);
	};

	return <ContentHubForm authenticate={authenticate} savedDomain={props.domain} savedUsername={props.username} errorMsg={errorMsg} />;
};

const ContentHubForm = (props) => {
	const [credentials, setCredentials] = React.useState({
		username: props.savedUsername ? props.savedUsername : '',
		password: '',
		domain: props.savedDomain ? props.savedDomain : '',
	});

	React.useEffect(() => {
		setCredentials({ ...credentials, username: props.savedUsername });
	}, [props.savedUsername]);

	React.useEffect(() => {
		setCredentials({ ...credentials, domain: props.savedDomain });
	}, [props.savedDomain]);

	const authenticate = () => {
		props.authenticate(
			{
				username: credentials.username,
				password: credentials.password,
				domain: credentials.domain,
			},
			() => {
				setCredentials({ ...credentials, password: '' });
			},
		);
	};

	const onChangeCredentials = (data) => {
		setCredentials({ ...credentials, [data.dataLabel]: data.value });
	};

	return (
		<Box d="flex" justify="center">
			<Authentication
				username={credentials.username}
				password={credentials.password}
				domain={credentials.domain}
				savedUsername={props.savedUsername}
				savedDomain={props.savedDomain}
				onChangeInput={onChangeCredentials}
				authenticate={authenticate}
				disconnect={props.disconnect}
				errorMsg={props.errorMsg}
			/>
		</Box>
	);
};

const Authentication = (props) => {
	const onChangeInput = (e, data) => {
		props.onChangeInput(data);
	};
	const createCHDAMAccountLink = 'https://www.sitecore.com/products/content-hub';
	return (
		<Box d="flex" direction="column" align="center">
			<Text mt={30} mb={40}>
				Log in to Sitecore Content Hub DAM
			</Text>
			<InputContainer
				disabled={props.savedDomain ? true : false}
				value={props.domain}
				dataLabel="domain"
				onChangeInput={onChangeInput}
				maxW={250}
				mb={15}
			>
				<InputIcon mr={10} ml={-5}>
					domain
				</InputIcon>
				<Input autoFocus placeholder="Login domain" />
			</InputContainer>
			<InputContainer
				disabled={props.savedUsername ? true : false}
				value={props.username}
				dataLabel="username"
				onChangeInput={onChangeInput}
				maxW={250}
				mb={15}
			>
				<InputIcon mr={10} ml={-5}>
					person
				</InputIcon>
				<Input type="email" placeholder="Username" />
			</InputContainer>
			<InputContainer
				disabled={props.savedUsername ? true : false}
				value={props.password}
				dataLabel="password"
				onChangeInput={onChangeInput}
				maxW={250}
				mb={25}
			>
				<InputIcon mr={10} ml={-5}>
					lock
				</InputIcon>
				<Input type="password" placeholder="Password" />
			</InputContainer>
			{props.errorMsg && (
				<Text fontColor="red" mb={25}>
					{props.errorMsg}
				</Text>
			)}
			<Button onClick={props.savedUsername ? props.disconnect : props.authenticate}>{props.savedUsername ? 'Disconnect' : 'Login'}</Button>
			<Text mt={30} mb={40} maxW="400px" d="unset">
				If you don't have a Sitecore Content Hub DAM account, click
				<Anchor
					mx={5}
					textDecoration="none"
					fontColor="fontColors.teal500"
					fontSize={16}
					target="_blank"
					d="unset"
					href={createCHDAMAccountLink}
				>
					here
				</Anchor>
				to learn more.
			</Text>
		</Box>
	);
};
