import React from 'react';

import { getPadding, getShadowValFromProps } from 'shared/src/utils/shared.js';
import styled from 'styled-components';

import { getFormFieldFontStyles } from '@/util/helper';

import Element from './Element.react';

export const basicLabelStyles = {
	userSelect: 'none',
	whiteSpace: 'pre-wrap',
	wordBreak: 'break-word',
};

export const inputGroupStyles = {
	padding: 0,
	display: 'flex',
	flexDirection: 'column',
};

export const MultiSelect = (props) => {
	const {
		labelTextDecoration,
		labelFontStyle,
		labelFontWeight,
		labelHide,
		labelFont,
		labelFontSize,
		labelFontColour,
		labelPosition,
		labelWidth,
		fieldFontWeight,
		fieldFontStyle,
		fieldTextDecoration,
		fieldFont,
		fieldFontSize,
		fieldFontColour,
		fieldBorderWidth,
		fieldBorderColour,
		fieldBorderRadius,
		fieldPadding,
		fieldBgColor,
		// fieldWidth,
		asteriskColor,
		formId,
		placeholderFont,
		placeholderFontColour,
		placeholderFontWeight,
		placeholderTextDecoration,
		placeholderFontStyle,
		descriptionFontColour,
		descriptionFontSize,
		descriptionSpacing,
		descriptionFontFamily,
		descriptionTextDecoration,
		descriptionFontStyle,
		descriptionFontWeight,
		box_shadow,
		background_color,
		options,
		placeholder,
	} = props;

	const fieldStyle = {
		background: fieldBgColor,
		padding: fieldPadding,
		paddingRight: fieldPadding + 30,
		color: fieldFontColour,
		fontSize: fieldFontSize,
		borderWidth: fieldBorderWidth,
		borderColor: fieldBorderColour,
		borderStyle: 'solid',
		borderRadius: fieldBorderRadius,
		fontWeight: fieldFontWeight,
		fontStyle: fieldFontStyle,
		textDecoration: fieldTextDecoration,
		...getFormFieldFontStyles(fieldFont),
	};

	const labelDimension =
		labelPosition === 'row'
			? {
					width: labelWidth,
				}
			: {
					minHeight: labelWidth,
				};

	const labelStyle = {
		color: labelFontColour,
		fontSize: labelFontSize,
		fontWeight: labelFontWeight,
		fontStyle: labelFontStyle,
		textDecoration: labelTextDecoration,
		display: labelHide ? 'none' : '',
		...labelDimension,
		...getFormFieldFontStyles(labelFont),
	};

	const containerStyle = {
		flexDirection: labelPosition,
		// width: `${fieldWidth}%`,
		width: '100%',
	};

	const asteriskStyles = {
		color: asteriskColor,
		textDecoration: 'none',
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontFamily: 'inherit',
	};

	const { flexDirection } = containerStyle;
	const dynamicContainerStyles =
		flexDirection === 'row'
			? {
					alignItems: 'center',
					WebkitBoxAlign: 'center',
					MsFlexAlign: 'center',
				}
			: {};

	const directionClass =
		flexDirection === 'row' ? 'lp-flex-direction-horizontal lp-flex-align-center' : 'lp-flex-direction-vertical element-vertical-ie';

	const hiddenStyles = props.hidden
		? {
				display: 'none',
			}
		: {};

	// to add placeholder styles
	const Input = styled.input`
		::placeholder {
			color: ${placeholderFontColour};
			font-size: ${fieldFontSize}px;
			font-weight: ${placeholderFontStyle};
			font-style: ${placeholderFontWeight};
			text-decoration: ${placeholderTextDecoration};
			font-family: ${placeholderFont};
		}
	`;

	const descriptionStyles = {
		color: descriptionFontColour,
		fontSize: descriptionFontSize,
		fontFamily: descriptionFontFamily,
		marginTop: descriptionSpacing,
		fontStyle: descriptionFontStyle,
		fontWeight: descriptionFontWeight,
		textDecoration: descriptionTextDecoration,
		display: props.descriptionHide ? 'none' : 'block',
		paddingLeft: labelPosition === 'row' ? labelWidth : undefined,
	};

	return (
		<div
			id={`${props.name.replace(/ /g, '')}-${formId}`}
			style={{
				width: '100%',
				pointerEvents: 'none',
				position: 'relative',
				backgroundColor: background_color,
				...getPadding(props, true),
				...hiddenStyles,
			}}
			className="form-input-wrapper lp-flex-1"
		>
			<label
				className={`form-input-wrapper-element form-input-select-element form-input-multiselect-element lp-flex-container form-input-${formId} ${directionClass}`}
				style={{ position: 'relative', ...inputGroupStyles, ...dynamicContainerStyles, ...containerStyle }}
			>
				<span style={{ ...basicLabelStyles, ...labelStyle }} className="form-field-label">
					{props.label}
					{props.required && (
						<span className="form-field-required" style={asteriskStyles}>
							{' '}
							*
						</span>
					)}
				</span>
				<div style={{ display: 'block', flex: '1 1 auto' }} className="lp-flex-1">
					<Input
						className="lp-flex-1 form-input-element"
						placeholder={placeholder}
						value={props.value}
						readOnly
						data-label={props.label}
						data-type={props.type}
						data-required={props.required}
						// data-validate={props.validate || false}
						disabled={options.length <= 0}
						style={{
							lineHeight: 'initial',
							caretColor: 'transparent',
							cursor: 'pointer',
							width: '100%',
							minWidth: 0,
							outline: 'none',
							flex: 1,
							boxShadow: getShadowValFromProps(box_shadow),
							display: 'block',
							...fieldStyle,
						}}
						name={props.name}
						type={props.hidden ? 'hidden' : 'text'}
						data-readOnly={props.readOnly}
					/>
				</div>
				{/* {options.length > 0 && (
					<div
						style={{
							...fieldStyle,
							display: 'none',
							padding: 0,
							border: '1px solid #007fff',
							borderRadius: 0,
							fontSize: fieldStyle.fontSize - 2 < 14 ? 14 : fieldStyle.fontSize - 2,
							position: 'absolute',
							left: labelStyle.display === 'none' ? 0 : flexDirection === 'row' ? labelStyle.width : 0,
							zIndex: 1,
							right: 0,
							top:
								(fieldStyle.fontSize || 16) +
								(fieldStyle.padding * 2 || 14) +
								(props.borderWidth * 2 || 4) +
								(flexDirection !== 'row' && labelStyle.display !== 'none' ? labelStyle.minHeight : 0) +
								2,
						}}
						className="form-select-option-container"
					>
						{options.map((option, i) => {
							return (
								<div key={i} data-value={option.value} className="form-select-option">
									{option.label}
								</div>
							);
						})}
					</div>
				)} */}
				<i
					style={{
						position: 'absolute',
						bottom: fieldStyle.padding - 4,
						right: fieldStyle.padding + 3,
						color: fieldStyle.color,
						fontSize: fieldStyle.fontSize + 10,
						cursor: 'pointer',
						lineHeight: 1,
					}}
					className="mdi mdi-menu-down"
				></i>
			</label>
			{props.description && <div style={descriptionStyles}>{props.description}</div>}
		</div>
	);
};

export default Element(MultiSelect);
