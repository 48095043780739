import React from 'react';

import { AllComponentTypes, calculateImageWidthInSlot, ComponentDefaults, getShadowValFromProps } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';

import domHelper from '@/util/domHelper';
import { setAttributeInRef } from '@/util/helper';

import Element from './Element.react';
import ElementComponent from './ElementComponent.react';
import SharedImage from './SharedImage.react';

export class Image extends ElementComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.image] };

	// shouldComponentUpdate(nextProps, nextState) {
	// 	return nextProps.forceUpdate || super.shouldComponentUpdate(nextProps, nextState);
	// }

	render() {
		let bgColor = this.props.background_color;

		const shadowSettings = getShadowValFromProps(this.props.box_shadow);

		let imageWidth = calculateImageWidthInSlot(this.props.resizeWidth, this.props.slotWidth);

		const finalWidth = imageWidth;

		return (
			<table
				className="component"
				data-component-type="image"
				cellSpacing="0"
				cellPadding="0"
				style={{
					width: '100%',
					clear: 'both',
					verticalAlign: 'bottom',
				}}
				width={this.props.slotWidth}
				ref={(component) => {
					setAttributeInRef('align', 'center')(component);
					domHelper.setAttributeList(component, this.props.rootTableAttributes || []);
					domHelper.applyStyles(component, { backgroundColor: bgColor });
				}}
			>
				<tbody>
					<tr>
						<td className="image image-container" ref={setAttributeInRef('align', this.props.alignment)}>
							<div className="content-main-content">
								<SharedImage
									address={this.props.address}
									src={this.props.src}
									alt={this.props.alt}
									width={finalWidth}
									padding={this.props.padding}
									multiPadding={this.props.multiPadding}
									height={this.props.resizeHeight}
									imgAttributes={this.props.imgAttributes}
									slotType={this.props.slot_type}
									isMobileView={this.props.isMobileView}
									onResizeStop={this.onResizeStop}
									resized={this.props.resized}
									resizeWidth={this.props.resizeWidth}
									resizeHeight={this.props.resizeHeight}
									type={this.props.type}
									placeholderText={true}
									imageType={this.props.imageType}
									originalSrc={this.props.originalSrc}
									activeElement={this.props.activeElement}
									slotWidth={this.props.slotWidth}
									forceUpdate={this.props.forceUpdate}
									extraStyles={{
										borderRadius: this.props.imageBorderRadius,
										borderWidth: this.props.imageBorderWidth,
										borderColor: this.props.imageBorderColor,
										borderStyle: this.props.imageBorderWidth ? 'solid' : 'unset',
										boxShadow: shadowSettings,
									}}
								/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	onResizeStop = (event, { size }) => {
		useContentStore.getState().saveElementSettings({
			address: this.props.address,
			settings: {
				resizeWidth: parseInt(size.width),
				resizeHeight: parseInt(size.height),
				resized: true,
			},
		});
	};
}

export default Element(Image);
