import { ModalMessages } from 'config/messages';
import { create } from 'zustand';

import useContentStore from '@/stores/ContentStore.js';

import { Modals, ModalTypes, contentViewMenuItemConstants as ViewTypes } from '@/util/resources';

import useModalStore from './ModalStore';

const useContentViewStore = create((set, get) => ({
	currentView: ViewTypes.DESIGN_VIEW,
	changeView: (view) => {
		if (view in ViewTypes) {
			const type = 'form';
			if (
				view === ViewTypes.MOBILE_VIEW &&
				get().currentView !== ViewTypes.MOBILE_VIEW &&
				!useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.mobilePadding
			) {
				useModalStore.getState().showDontAskModal({
					modal: Modals.INFO,
					// image: responsiveGif,
					type: 'mobilePadding',
					message: ModalMessages.MOBILE_PADDING(type),
					// message2: ModalMessages.RESPONSIVE_WARNING2,
					// list: config.responsive_email_clients,
					ignoreCancelBtn: true,
					okLabel: 'Got it',
				});
			}

			set(() => ({ currentView: view }));

			return useContentStore.getState().checkJsonValidity(view === ViewTypes.MOBILE_VIEW);
		} else {
			throw new Error(`No view type ${view} exists`);
		}
	},
}));

export const changeView = useContentViewStore.getState().changeView;

export default useContentViewStore;
