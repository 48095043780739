import WebStorage from '@/util/webStorage';

export const getAuth0ParamsFromUrl = (search) => {
	const searchParams = new URLSearchParams(search);

	const organization = searchParams.get('organization') || WebStorage.get('tenant_info')?.organization || '';
	const tenantName = searchParams.get('tenantName') || WebStorage.get('tenant_info')?.tenantName || '';

	return new URLSearchParams({ organization, tenantName });
};
